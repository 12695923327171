import React, { useEffect, useState } from "react";
import { InputCheckBox, InputText } from "commons/input-controls";
import s from "./topic-menu.module.scss"
import { ReactComponent as DownRightArrowIcon } from '../../assets/icons/down-right-arrow.svg';
import { ReactComponent as CopyIcon} from "assets/icons/copy.svg";
const TopicMenu = (props) => {

    const getSelectedAll = (topicsCount, selectedCount) => {
        return selectedCount === topicsCount ? true : false
    }

    const getSelectedAllSub = (topics, items, subCount) => {
        if(topics.length === 1){
            let topicIndex = items.findIndex(k => k.uuid === topics[0]);
            if(topicIndex !== -1){
                let topic = items[topicIndex];
                if(topic.subtopics && topic.subtopics.length){
                    return topic.subtopics.length === subCount.length ? topic.uuid : null
                }
                else return null
            }
            else return null
        }
        else return null
    }

    const [items, setItems] = useState(props.items)
    const [selected, setSelected] = useState(props.selected);
    const [selectedSubtopic, setSelectedSubtopic] = useState(props.selectedSubtopics);
    const [showSubtopics, setShowSubtopics] = useState(props.showSubtopics ? props.showSubtopics : false);
    const [selectedAll, setSelectedAll] = useState(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0))
    const [selectectedAllSub, setSelectedAllSub] = useState(getSelectedAllSub(props.selected, props.items, props.selectedSubtopics));
    const [type, setType] = useState(props.type && props.type === 'tree' ? 'tree' : 'checkboxes');
    const [copied, setCopied] = useState(null);

    useEffect( () => {
        setItems(props.items)
        setSelected(props.selected);
        setSelectedSubtopic(props.selectedSubtopics);
        setShowSubtopics(props.showSubtopics ? props.showSubtopics : false);
        setSelectedAll(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0));
        setType(props.type && props.type === 'tree' ? 'tree' : 'checkboxes');
        setSelectedAllSub(getSelectedAllSub(props.selected, props.items, props.selectedSubtopics));
    }, [props])
    const addSearchText = () => {

    }

    const closeMenu = () => {
        props.setRemoveLeftMenuComponent();
        props.setShowLeftMenu(false);
    }

    const checkLimitTitle = (title, limit = null) => {
        if(limit && title && title.length > limit){
            let t = title.slice(0, limit);
            let str = t.split(" ");
            str.pop();
            
            return str.join(" ")+"...";
        }
        else
            return title
    }

    const selectAll = (e) => {
        let isSelected = !selectedAll
        isSelected ? props.setSelectedTopicsAll(items.map(k => {return {name: k.name, id: k.uuid}})) : props.setSelectedTopicsAll([])
    }

    const selectAllSub = (topic, e) => {
        if(e){
            props.setSelectedSubtopicsAll([{name: topic.name, id: topic.uuid}], topic.subtopics.map(k => {return {name: k.name, id: k.uuid}}))
            setSelectedAllSub(topic.uuid)
        }
        else{
            props.setSelectedTopicsAll([])
            setSelectedAllSub(null)
        }
    }

    const selectChecked = (uuid, isSelected, selected) => {
        props.setSelectedTopics({name: selected, id: uuid})
    }

    const selectCheckedSubtopics = (uuid, selected, selectedTopic, isSelected, sele) => {
        props.setSelectedSubtopics({name: selectedTopic, id: selected}, {name: sele, id: uuid})
    }

    const selectItem = (name, uuid) => {
        props.setSelectedTopicsAll([{name: name, id: uuid}]);
        if(props.deviceType === 'mobile' || props.deviceType ==='tablet'){
            closeMenu();
        }
        
    }

    const selectSubtopicItem = (topic, subtopic, e) => {
        props.setSelectedSubtopics({name: topic.name, id: topic.uuid}, {name: subtopic.name, id: subtopic.uuid})
    }

    const selectSubtopicsItem = (topic, subtopic, e) => {
        props.setSelectedSubtopicsAll([{name: topic.name, id: topic.uuid}], [{name: subtopic.name, id: subtopic.uuid}])
    }

    const copyName = (name) => {
        
        navigator.clipboard.writeText(name);
        setCopied(name);
        setTimeout(()=>{
            setCopied(null)
        }, 1000);
    }

    const checkboxItems = items.map( (k, i) => 
        <div className={s.CheckItem} key={k.uuid}>
            <div className={s.CheckItemName}>
                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selected.indexOf(k.uuid) !== -1} name={k.name} callback={selectChecked.bind(this, k.uuid)}/>
                <span>{checkLimitTitle(k.name, 60)}</span>
            </div>
            {showSubtopics && k.subtopics && k.subtopics.length > 2 ?
                    <div className={s.ItemHeader+' '+s.subHeader}>
                        <div className={s.CheckItem}>
                            <div className={s.CheckAllSubItem}>
                                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectectedAllSub && selectectedAllSub === k.uuid} callback={selectAllSub.bind(this, k)} name={'name'+k.uuid} title={props.getValue('CHOOSE_ALL_SUBTOPICS')} />
                                <span>{props.getValue('CHOOSE_ALL_SUBTOPICS')}</span>
                            </div>

                        </div>
                    </div> : null}
            {showSubtopics && k.subtopics /*&& props.selectedTopics.indexOf(k.uuid) !== -1*/ ? k.subtopics.map((subtopic, j) => 
                <div key={subtopic.uuid} className={s.CheckItemName+' '+s.SubtopicItemName}>
                    
                    <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedSubtopic.indexOf(subtopic.uuid) !== -1} name={subtopic.name+"sub"} callback={selectCheckedSubtopics.bind(this, subtopic.uuid, k.uuid, k.name)}/>
                    <span>{checkLimitTitle(subtopic.name, 60)}</span>
                </div>) : null}
        </div>
    )

    const buttonItems = items.map((k, i) => {
            return <div className={s.topicItem} key={k.uuid}>
                        <button disabled={props.isFetchingAnalytic} onDoubleClick={copyName.bind(this, k.name)} onClick={selectItem.bind(this, k.name, k.uuid)} className={props.selected.indexOf(k.uuid) !== -1 ? s.topic+' '+s.selected : s.topic+''}>{checkLimitTitle(k.name, 60)}{ copied && copied === k.name ? <CopyIcon /> : null}</button>
                        {showSubtopics && k.subtopics && props.selected.indexOf(k.uuid) !== -1 ? k.subtopics.map((subtopic, j) => <div key={subtopic.uuid} className={s.subtopicItem}><DownRightArrowIcon /><button disabled={props.isFetchingAnalytic} onDoubleClick={copyName.bind(this, subtopic.name)} onClick={selectSubtopicsItem.bind(this, k, subtopic)} className={selectedSubtopic.indexOf(subtopic.uuid) !== -1 ? s.subtopic+" "+s.selectedSubtopic : s.subtopic}>{checkLimitTitle(subtopic.name, 60)}{ copied && copied === subtopic.name ? <CopyIcon /> : null}</button></div>) : null}
                    </div>
        }
    )

    return (
        <div className={s.TopicMenu}>
            {type === 'checkboxes' 
                ?   <div className={s.Checkboxes}>
                        <div className={s.ItemHeader}>
                            <div className={s.CheckItem}>
                                <div className={s.CheckAllItem}>
                                    <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedAll} callback={selectAll} name={'name+121'} title={props.getValue('CHOOSE_ALL')} />
                                    <span>{props.getValue('CHOOSE_ALL')}</span>
                                </div>

                            </div>
                        </div>
                        <div className={s.MenuBody}>
                            { checkboxItems }
                        </div>
                    </div>



                :   <div className={s.Tree}>
                        { buttonItems }
                    </div>
            }
        </div>
    )
}

export default TopicMenu;