import React, {useState, useEffect} from "react";
import s from './notifications-statuses.module.scss';
import { StandartButton } from "../../commons/form-controls"
import PaginatorContainer from "items/paginator/paginator-container";
import { ReactComponent as RetryIcon } from '../../assets/icons/retry.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as RestartIcon } from '../../assets/icons/restart.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as EmptyDocumentIcon } from '../../assets/icons/empty-document.svg';

import { ReactComponent as FavouriteAuthorIcon } from '../../assets/icons/favourite_authors.svg';
import { ReactComponent as FavouriteIcon } from '../../assets/icons/favorite.svg';
import { ReactComponent as RecylceBinIcon } from '../../assets/icons/recycle-bin.svg';
import { ReactComponent as BlackListIcon } from '../../assets/icons/black-list.svg';
import { ReactComponent as Sample } from '../../assets/icons/sample.svg';
import { getFormatDate } from "service/format-date";
import Spinner from "items/preload/spinner";
import SwitchAdminModeContainer from "pages/settings-page/menu/switch-admin-mode/switch-admin-mode-container";

const NotificationsStatuses = (props) => {
    const [disableRetryButton, setDisableRetryButton] = useState(null);
    const [reportSended, setReportSended] = useState(props.body);
    const [reportReloaded, setReportReloaded] = useState(null);
    const [notifications, setNotifications] = useState(props.notificationsStatuses);
    const [page, setPage] = useState(props.currentPage);
    const [showedCount, setShowedCount] = useState(props.showedItemsCount)
    const [rerender, setRerender] = useState(false);
    const [users, setUsers] = useState(props.users ? props.users : []);

    useEffect(()=>{
        setNotifications(props.notificationsStatuses);
        setPage(props.currentPage);
        setShowedCount(props.showedItemsCount)
        setRerender(!rerender);
        setUsers(props.users ? props.users : []);
    }, [props.notificationsStatuses, props.currentPage, props.showedItemsCount, props.users])

    const getUserName = (userId) => {
        let user =  users ? users.filter(k => k.id === userId) : null
        return user[0] && user[0].login ? user[0].login : null
    }

    const getFormatedDate = (date) => {
        return getFormatDate(date, 'DD-MM-YYYY HH:mm')
    }

    const getFormatName = (format) => {
        let formatIndex = props.reportFormatTypes.findIndex(k => k.short_name === format)
        if(formatIndex !== -1)
            return props.getValue(props.reportFormatTypes[formatIndex].title)
        else format
    }

    const getReportTemplateNameById = (templateId) => {
        switch(templateId){
            case 0:
                return props.getValue('STANDART_REPORT');
            default:
                return templateId
        }
    }

    const getTextByStatus = (status, emails) => {
        switch(status){
            case 'PENDING':
            case 'IN_PROGRESS':
                return props.getValue('REQUEST_IS_PROCESSING_PLEASE_WAIT');
            case 'FAILED':
                return props.getValue('ERROR_RE_REQUEST_REQUIRED');
            case 'COMPLETE':
                return emails.length ? props.getValue('READY_SENT_TO_THE_EMAIL')+' '+emails : props.getValue('READY');            
        }
    }

    const retry = (id) => {
        props.selectedRole === 'ROLE_ADMIN'
            ? props.retryAdminReportOperation(id)
            : props.retryReportOperation(id)
    }

    const download = (url) => {
        props.downloadReport(url)
    }

    const deleteReport = (id) => {
        let p = page

        if(notifications.length <= 1 && page > 0){
            p -= 1
            props.setPage(p)
        }
        props.selectedRole === 'ROLE_ADMIN'
            ? props.deleteAdminReportOperation(id, p, showedCount)
            : props.deleteReportOperation(id, p, showedCount)
    }

    const reloadItem = (id) => {
        setDisableRetryButton(id)
        props.getNotificationsStatus(id);
        setReportSended(id);
        setTimeout(()=>{setDisableRetryButton(null), setReportSended(null);},1500)
    }

    if(reportSended){
        setTimeout(()=>{setReportSended(null)},4000)
    }

    const statusIcon = (feedType) => {
        switch(feedType){
            case 'favourite_manual':
                return <FavouriteIcon />
            case 'deleted':
                return <RecylceBinIcon />
            case 'favourite_auto':
                return <FavouriteAuthorIcon />
            case 'blacklisted':
                return <BlackListIcon />
            default:
                return <Sample />
        }
    }

    const stats = notifications && notifications.map((n, i) => {
        return  <div key={n.id} className={s.NotificationsStatusesItem}>
                    <div className={(reportSended && reportSended === n.id) && s.pulse+" "+s.style}>
                    </div>
                        <div className={s.notificationsRowBetween}>
                            <div className={s.notificationsRow}>
                                <div className={s.status+' '+n.status}>{statusIcon(n.reportFeedType)}</div>
                                <span className={s.NotificationsStatusesTitle}>{n.topicName}</span>
                                {n.digestSettingsId && <ClockIcon /> }
                            </div>
                            <div className={s.buttonsBlock}>
                                {n.status === 'FAILED' && <StandartButton disabled={n.retryReport} setClass={'background'} icon={RetryIcon} callback={retry.bind(this, n.id)}/>}
                                {n.status === 'COMPLETE' ? n.downloadUrl ? <StandartButton disabled={n.retryReport} setClass={'background'} icon={DownloadIcon} callback={download.bind(this, n.downloadUrl)} name={props.getValue('DOWNLOAD_FINISHED_REPORT')}/> : <StandartButton disabled={n.retryReport} setClass={'background'} icon={EmptyDocumentIcon} name={props.getValue('EMPTY_DOCUMENT')}/> : null}
                                {(n.status !== 'COMPLETE' && n.status !== 'FAILED') && <StandartButton disabled={disableRetryButton && disableRetryButton === n.id} setClass={'background'} icon={RestartIcon}callback={reloadItem.bind(this, n.id)}/>}
                                <StandartButton disabled={n.retryReport} setClass={'background'} icon={CloseIcon} callback={deleteReport.bind(this, n.id)} name={props.getValue('DELETE')} />
                            </div>
                        </div>
                        { props.selectedRole === 'ROLE_ADMIN'
                            ? <div className={s.notificationsRow+' '+s.period}>
                             <span>{props.getValue('ACCOUNT')+':'}</span>
                                <span>{getUserName(n.userId)}</span>
                            </div>
                            : <div></div>
                        }
                        {n.dateStart && <div className={s.notificationsRow+' '+s.period}>
                            <span>{props.getValue('PERIOD_2')+':'}</span>
                            <span>{getFormatedDate(n.dateStart)} - {getFormatedDate(n.dateEnd)}</span>
                        </div>}
                        <div className={s.notificationsRow+' '+s.period}>
                            <span>{props.getValue('DATA')+': '}</span>
                            <span>{getFormatedDate(n.createdAt)}</span>
                        </div>
                        <div className={s.notificationsRow+' '+s.period}>
                            <span>{props.getValue('TYPE')+': '}</span>
                            <span>{n.reportTemplateId || !isNaN(n.reportTemplateId) ? getReportTemplateNameById(n.reportTemplateId) : getFormatName(n.type)}</span>
                        </div>
                        <div className={s.notificationsRow+' '+s.period}>
                            <span>{props.getValue('STATUS')+': '}</span>
                            <span>{getTextByStatus(n.status, n.emails)}</span>
                        </div>
                    

                </div>
    });

    const paginator = (type, e) => {
        if(type === 'changePage'){
            //setPage(e.selected);
            props.setPage(e.selected ? e.selected : e-1);
        }
        else if(type === 'changeLimit'){
            setShowedCount(e);
            props.setShowedCount(e);
        }
    }

    return (
        <React.Fragment>
            {props.role === 'ROLE_ADMIN' || props.role === 'ROLE_SUPER_ADMIN' ? <SwitchAdminModeContainer pageType={'Notifications'}/> : null}
            { !props.notificationsIsFetching ? <PaginatorContainer callback={paginator} page={page} showedCount={showedCount} count={props.notificationsCount}/> : null}
            <div className={s.NotificationsStatuses}>
                {props.notificationsIsFetching ? <Spinner/> : stats}
            </div>
        </React.Fragment>
    )
}

export default NotificationsStatuses