import ModalReportController from "./modal-report-controller";
import { getValue } from "../../store/languages";
import { connect } from "react-redux";
import { getStartDate, getEndDate} from "../../selectors/calendar-selector";
import { sendReport, sendMultipleReport, sendSubtopicReport, sendTemplateReport, setReportSended } from "../../store/report"
import { getTopicDropdownFromState, getSubtopicsFromState} from "../../selectors/theme-selector"
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";

let mapStateToProps = (state) => {
    return {
        /*selectedTopic: state.Tree.selectedItem,
        selectedSubItem: state.Tree.selectedSubItem,
        selectedId: state.Tree.selectedId,*/
        selectedTopics: getSelectedTopicsUUID(state),
        selectedSubtopics: getSelectedSubTopicsUUID(state),
        selectedTopicsItems: state.TopicMenu.selectedTopics,
        selectedSubtopicsItems: state.TopicMenu.selectedSubtopics,
        /*selectedUUId: state.Tree.selectedUUId,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,*/
        selectedPostItems: state.storage.selectedPostItems,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        isChanged: state.Filters.isChanged,
        selectedItems: state.Filters.selectedMyFilter,
        selectedItemItems: state.Filters.selectedItemItems,
        email: state.Account.email,
        reportFormatTypes: state.report.reportFormatTypes,
        reportTypes: state.report.reportTypes,
        reportSended: state.report.reportSended,
        topics: getTopicDropdownFromState(state),
        subtopics: getSubtopicsFromState(state),
        //selectedTopics: state.Filters.selectedTopics,
        pageName: state.Filters.pageName,
        searchList: state.SearchText.searchList,
    }
}

const ModalReportContainer = (ModalReportController);

export default connect(mapStateToProps, {sendReport, sendMultipleReport, getValue, sendSubtopicReport, sendTemplateReport, setReportSended})(ModalReportContainer)