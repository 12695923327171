import React from "react";
import AdminAccounts from "./admin-accounts";
import CreateCompanyAccount from "./create-account/create-account";
import EditAccountContainer from "./edit-account/edit-account-container";
import ControlButtons from "items/control-buttons/control-buttons";
import AdminAddItem from "pages/admin/admin-add-item/admin-add-item";
import { getFormatDate } from "service/format-date";
class AdminAccountsController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            companyId: this.props.adminCompanyId ? this.props.adminCompanyId : this.props.companyId,
            selectedAccountId: null,
            companies: this.props.companies,//this.props.getSuperadminCompanies("ALL"),
            selected: null,
            companyCounts: 100
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    getCompanyName = (companyId, type) => {
        let companyIndex = this.props.companies ? this.props.companies.findIndex(k => k.id === companyId) : -1
        return companyIndex !== -1 ? this.props.companies[companyIndex][type] : null
    }

    getAccountById = (accountId) => {
        return this.props.accounts && this.props.accounts.accounts ? this.props.accounts.accounts.find(k => k.id === accountId) : null
    }

    CreateCompanyAccount = (companyId, accountLogin, accountFio, accountEmail, accountPhone, accountPassword, accountLocale, isDemo, role, topics) => {
        let authorities = role === "ROLE_USER" && Object.values(this.props.selectedAuthorities).length ? Object.values(this.props.selectedAuthorities): []
        
        if(companyId != null && this.props.isSuperadmin) {
            this.props.createCompanyAccount(companyId, accountLogin, accountFio, accountEmail, accountPhone, accountPassword, accountLocale, role, authorities, this.props.isSuperadmin, 0, this.props.showedItemsCount, topics)
        } 
        else {
              this.props.createAccount(accountLogin, accountFio, accountEmail, accountPhone, accountPassword, accountLocale, role, authorities, this.props.isSuperadmin, 0, this.props.showedItemsCount, topics)
            
        }
        this.closeRightMenu()
        
    }

    EditCompanyAccount = (companyId, accountId, fio, email, phone, locale, isDemo, role, is) => {
        let authorities = (role === "ROLE_USER" && Object.values(this.props.selectedAuthorities).length) ? Object.values(this.props.selectedAuthorities): []
            if(this.props.isAccountsPage){
                this.props.editAccount(accountId, fio, email, phone, locale, isDemo, role, authorities, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount)
            }
            else{
                this.props.editCompanyAccount(companyId, accountId, fio, email, phone, locale, isDemo, role, authorities, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount);
            }
        this.closeRightMenu();
    }

    attachAccountToCompany = (companyId, accountId) => {
        this.props.attachAccountToCompany(companyId, accountId, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount);
        this.closeRightMenu()
    }

    detachAccountFromCompany = (accountId, companyId) => {
        this.props.detachAccountFromCompany(accountId, companyId, this.props.isAccountsPage, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount);
        this.closeRightMenu()
    }

    addAccountToTopic = (item) => {
        let account = this.getAccountById(this.state.selectedAccountId);
        if(account && account.role && account.role === 'ROLE_MARKER'){
            this.props.makeTopicVisibleToMarker(this.state.selectedAccountId, item, this.props.companyId, true)
        }
        else{
            this.props.attachTopicToAccount(this.props.companyId, this.state.selectedAccountId, item, this.props.isSuperadmin, true);
        }
        
        this.closeRightMenu()
    }

    removeAccountFromTopic = (item) => {
        let account = this.getAccountById(this.props.accountId);
        if(account && account.role && account.role === 'ROLE_MARKER')
            this.props.detachMarkerTopic(item, this.props.topicId)
        else
            this.props.detachTopicFromAccount(this.props.companyId, item, this.props.topicId, this.props.isSuperadmin, true)
    }

    closeMenu = () => {
        this.props.setRemoveRightMenuComponent()
    }

    showRightMenu = (show, title, index) => {
        this.closeRightMenu()
        let account = this.props.accounts.accounts ? this.props.accounts.accounts[index] : []
        if(show){
            this.props.setShowRightMenu(true)
            switch(show) {
                case 'create':
                    this.props.setAccountId(null)
                    this.props.setRightMenuComponent(
                        <CreateCompanyAccount
                            account={null}
                            companyId={(this.props.isAccountsPage && !this.props.adminCompanyId) ? null : (this.props.companyId ? this.props.companyId : this.props.adminCompanyId)}
                            callback={this.CreateCompanyAccount} 
                            getValue={this.props.getValue}
                            accountLocales={this.props.accountLocales}
                            roles={this.props.roles}
                            selectedAuthorities={this.props.selectedAuthorities}
                            companyTopics={this.props.companyTopics.topics}
                            topics={this.props.topics}
                        />, 
                        'account', 
                        null, 
                        'CREATE_USER', 
                        true,
                        null,
                        this.closeMenu
                    );
                    break;
                case 'edit':
                    let companyName = account && account.companyId ? this.getCompanyName(account.companyId, 'name') : null
                    //this.props.getAccountTopics(account.id)
                    this.props.setRightMenuComponent(
                        <EditAccountContainer
                            Account={account}
                            companyId={this.props.isSuperadmin && this.props.adminCompanyId ? this.props.adminCompanyId : this.props.companyId} 
                            callback={this.EditCompanyAccount}
                            getValue={this.props.getValue}
                            accountLocales={this.props.accountLocales}
                            roles={this.props.roles}
                            selectedAuthorities={this.props.selectedAuthorities}
                            companies={this.props.companies}
                            activeCompanies={this.props.activeCompanies}
                            companyName={companyName}
                            accountTopics={this.props.accountTopics}
                            isEdit={true}
                        />, 
                        'account', 
                        null, 
                        'UPDATE_ACCOUNT', 

                        true,
                        null,
                        this.closeMenu
                    );
                    break;
                case 'show':
                    this.props.setRightMenuComponent(
                        <EditAccountContainer
                            Account={account}
                            companyId={this.props.companyId} 
                            callback={this.EditCompanyAccount}
                            accountLocales={this.props.accountLocales}
                            getValue={this.props.getValue}
                            isEdit={false}
                        />, 
                        'account', 
                        null, 
                        this.props.getValue('SHOW_ACCOUNT'), 
                        true
                    );
                    break;
                case 'addItem':
                        let items = this.props.isAccountsPage 
                            ? this.props.companies
                            : this.props.companyTopics.topics
                        let title = this.props.isAccountsPage
                            ? 'ATTACH_ACCOUNT_TO_COMPANY'
                            : 'ATTACH_ACCOUNT_TO_TOPIC'
                            
                    this.props.setRightMenuComponent(<AdminAddItem items={items} getValue={this.props.getValue} callback={this.props.isAccountsPage ? this.attachAccountToCompany.bind(this, account.id) : this.addAccountToTopic}/>, 'addItem', null, title, true, null, this.closeMenu) 
                    break;
                case 'remove':
                    let companyNameT = this.getCompanyName(account.companyId, 'name')
                    this.props.setRightMenuComponent(<AdminAddItem items={[]} getValue={this.props.getValue} callback={this.detachAccountFromCompany} detach={companyNameT} companyId={account.companyId} accountId={account.id}/>, 'addItem', null, 'DETACH_ACCOUNT_FROM_COMPANY', true, null, this.closeMenu) 
                    break;
                }
        }
    }

    componentDidMount(){
        if(this.props.isSuperadmin){
            this.props.getSuperadminCompanies('ALL', 0, 9999, 'SUPERADMIN_ACCOUNTS'); //get all companies for search company name
            this.props.getAllTopics(0, 9999, 'ACTIVE');
        }
        if(this.state.companyId && !this.props.isAccountsPage)
            this.props.getCompanyAccounts(this.state.companyId, this.props.isSuperadmin);
        this.props.getAvailableRoles(this.props.isSuperadmin);
        //this.props.getAccountTopics()
    }

    deleteAccount = (accountId, companyId, isSuperadmin) => {
        this.props.deleteAccount(companyId, accountId, isSuperadmin, this.props.isAccountsPage ,this.props.currentPage, this.props.showedItemsCount)
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;
        let selectedTopic = null
        /*

        this.props.pauseTopic(key);
                break;
            case 'PAUSED':
                this.props.startTopic(key);
        */
        switch(type){
            case 'show':
                this.showRightMenu('show', key, index)
                break;
            case 'create':
                this.showRightMenu('create', key, index)
                break;
            case 'edit':
                this.showRightMenu('edit', key, index)
                break;
            case 'delete':
                this.deleteAccount(key, this.props.companyId, this.props.isSuperadmin)
                break;
            case 'remove':
                this.props.isAccountsPage
                                        ? this.showRightMenu('remove', key, index) //this.props.detachAccountFromCompany(key, this.props.accounts.accounts[index].companyId)
                                        : this.removeAccountFromTopic(key);
                break;
            case 'copy':
                break;
            case 'ACTIVE':
                this.props.blockAccount(this.props.companyId, key, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount);
                break;
            case 'PAUSED':
                this.props.activateAccount(this.props.companyId, key, this.props.isSuperadmin, this.props.currentPage, this.props.showedItemsCount);
                break;
            case 'add':
                this.setState({
                    selectedAccountId: key
                })
                this.showRightMenu('addItem', key, index)    
                break;
        }
    }

    getControlButtonItems = (id, status, items, index, companyId, disabled) => {
        let c = [];
        c = [...items];
        if(this.props.isAccountsPage){
            let addRemove = c.indexOf('add');
            c[addRemove] = companyId ?  'remove' : 'add'
            
        }

        c.unshift(status)
        return <ControlButtons keyId={id} disabled={disabled} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    formatDate = (date) =>{
        return getFormatDate(date, "DD.MM.YY HH:mm");
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            if(typeof(k[k.length-1]) !== "object"){
                /*let id = k[0];
                let companyId = k[2]
                
                k[0] = i+1;
                k[2] = this.props.isSuperadmin ? this.getCompanyName(k[2], 'name') : k[2]
                
                
                k[5] = this.props.getValue(k[5])
                k[6] = this.props.getValue(k[6])

                
                
                k.push(this.getControlButtonItems(id, status, ctrl, i, companyId, disabled))*/
                let status = k.status === 'ACTIVE' ? 'ACTIVE' : 'PAUSED';
                let disabled = []
                if(k.status !== 'ACTIVE') disabled = ['add']
                else disabled = []
                let companyName = this.props.isSuperadmin ? this.getCompanyName(k.companyId, 'name') : k.companyId
                let ctrl = !this.props.isTopicAccounts ? this.props.accountsTableControlButtons : this.props.accountsTopicTablerControlButton;
                let d = [i+1, k.login, k.fio, k.email, this.props.getValue(k.role), this.props.getValue(k.status), this.formatDate(k.createdAt), this.getControlButtonItems(k.id, status, ctrl, i, k.companyId, disabled)]
                if(this.props.isAccountsPage){
                    d.splice(2, 0, companyName, );
                }
                return d;
            }
            //return k
        })
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    componentDidUpdate(){
        if((this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount) && !this.props.companyId){
            this.closeRightMenu()
            this.setState({
                currentPage: this.props.currentPage,
                showedItemsCount: this.props.showedItemsCount,
                selected: null
            })
        }
        /*if(this.props.companies && this.props.companies.length >= this.state.companyCounts){
            let companyCounts = this.state.companyCounts + 50;
            this.setState({
                companyCounts: companyCounts
            })
            this.props.getSuperadminCompanies('ALL', 0, companyCounts, 'SUPERADMIN_ACCOUNTS');
        }*/
    }


    setSelected = (selected) => {
        this.setState({
            selected: selected
        });
    }

    getAccounts = () => {
        if(this.props.isTopicAccounts){
            if(this.props.topicAccounts.accounts && this.props.topicAccounts.accounts.length){
                return this.setTableValues(this.props.topicAccounts.accounts)
            }
        }
        else{
            if(this.props.accounts.data && this.props.accounts.data.length){
                return this.setTableValues(this.props.accounts.accounts)
            }  
        }

    }

    render(){
        const data = this.getAccounts();//(this.props.accounts.data && this.props.accounts.data.length) && this.setTableValues(this.props.accounts.accounts)
                                              
        return (
                <AdminAccounts 
                    data={data} 
                    {...this.props} 
                    tableHeader={this.getTableHeader(this.props.accountTableHeader)}
                    showRightMenu={this.showRightMenu}
                    setSelected={this.setSelected}
                    selected={this.state.selected}
                />
        )
    }

    componentWillUnmount(){
        this.closeRightMenu();
        this.props.setAccountId(null)
    }
}

export default AdminAccountsController;