const Titles = {
    "_COMMENT_0_":"авторизация",
    "LOGIN" : "ЛОГИН",
    "PASSWORD" : "ПАРОЛЬ",
    "WELLCOME_TO_THE" : "Добро пожаловать",
    "FCBK_ECHO": "FCBK Echo",
    "MEDIA_MONITORING" : "Media Monitoring",
    "DON'T_REMEMBER_PASSWORD" : "НЕ ПОМНЮ ПАРОЛЬ",
    "PREVIOUS_VERISON" : "Предыдущая версия системы",
    "SIGN_IN" : "Войти в систему",
    "DON'T_HAVE_ACCOUNT" : "Нет аккаунта?",
    "REGISTRATION" : "Регистрация",
    "WRONG_EMAIL" : "НЕВЕРНЫЙ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ",
    "EMAIL_AND_PASSWORD_COMBINATION_WASN'T_FOUND" : "КОМБИНАЦИЯ ЛОГИНА И ПАРОЛЯ НЕ НАЙДЕНА",
    "_COMMENT_1":"модальное окно восстановления пароля",
    "PASSWORD_RESTORE" : "Восстановление пароля",
    "CONTINUE" : "Продолжить",
    "SORRY_SUCH_MAIL_OR_LOGIN_IS_NOT_REGISTERED" : "К СОЖАЛЕНИЮ, ТАКАЯ ПОЧТА ИЛИ ЛОГИН НЕ ЗАРЕГИСТРИРОВАНЫ",
    "TRY_AGAIN" : "Попробовать еще раз",
    "CANCEL" : "Отмена",
    "THE_LETTER_WITH_INSTRUCTIONS_WAS_SENT_TO_YOUR_EMAIL_ADDRESS" : "НА ВАШ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ БЫЛО ОТПРАВЛЕНО ПИСЬМО С ИНСТРУКЦИЕЙ",
    "CLOSE" : "Закрыть",
    "_COMMENT_2":"окно регистрации пользователя",
    "USER_REGISTRATION" : "Регистрация пользователя",
    "UNIQUE_LOGIN" : "УНИКАЛЬНЫЙ ЛОГИН",
    "SORRY_SUCH_LOGIN_IS_BUSY" : "ИЗВИНИТЕ, ЛОГИН УЖЕ ИСПОЛЬЗУЕТСЯ",
    "8-12_CHARACTER_LATIN_CAPITAL_LETTERS_NUMBERS" : "8-12 СИМВОЛОВ, ЛАТИНИЦА, ЗАГЛАВНЫЕ БУВКЫ, ЦИФРЫ",
    "PASSWORD_REPEAT" : "ПОВТОР ПАРОЛЯ",
    "SORRY_WRONG_PASSWORD_REPEAT" : "К СОЖАЛЕНИЮ, ВЫ НЕВЕРНО ПОВТОРИЛИ ПАРОЛЬ",
    "OPTIONAL_FIELDS" : "НЕОБЯЗАТЕЛЬНЫЕ ПОЛЯ",
    "FULL_NAME" : "ФИО",
    "YOUR_FULL_NAME" : "ВАШИ ФАМИЛИЯ, ИМЯ И ОТЧЕСТВО",
    "EMAIL_EXAMPLE" : "EXAMPLE@EMAIL.COM",
    "PHONE" : "ТЕЛЕФОН",
    "YOUR_PHONE_NUMBER" : "ВАШ НОМЕР ТЕЛЕФОНА",
    "NOT_VALID_EMAIL" : "НЕ ВАЛИДНЫЙ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ",
    "NOT_VALID_PHONE" : "НЕ ДОПУСТИМЫЙ НОМЕР ТЕЛЕФОНА",
    "SEND" : "Отправить",
    "ALLREADY_HAVE_ACCOUNT" : "Уже есть аккаунт?",
    "_COMMENT_3":"форма обратной связи",
    "FEEDBACK_FORM" : "Форма обратной связи",
    "CONTACT_DETAILS" : "КОНТАКТНЫЕ ДАННЫЕ",
    "PHONE_NUMBER_OR_E-MAIL_ADDRESS_SEPARATED_WITH_A_COMMA" : "НОМЕР ТЕЛЕФОНА ИЛИ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ, МОЖНО ЧЕРЕЗ ЗАПЯТУЮ",
    "EMAIL_OR_LOGIN" : "АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ ИЛИ ЛОГИН",
    "EMAIL" : "АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ",
    "MESSAGE_TEXT" : "ТЕКСТ СООБЩЕНИЯ",
    "WRITE_HERE_WHAT_YOU_ARE_INTERESTED_IN" : "НАПИШИТЕ СЮДА, ЧТО ВАМ ИНТЕРЕСНО",
    "WE_ARE_ALWAYS_AWAILABLE_AT" : "Мы всегда доступны по адресу:",
    "OUR_EMAIL" : "echo@1cb.kz",
    "OR_BY_PHONE" : "или по номеру:",
    "OUR_PHONE" : "+7 (727) 325 35 00",
    "_COMMENT_4":"основное меню",
    "FAVORITE_POSTS" : "Избранные публикации", 
    "FAVORITE_AUTHORS_AND_SOURCES" : "Избранные авторы и источники",
    "BLACK_LIST" : "Черный список",
    "TRASHBIN" : "Корзина",
    "LIGHT" : "светлая",
    "MIX" : "микс",
    "DARK" : "темная",
    "RUSSIAN" : "Русский",
    "KAZAKH" : "Қазақ",
    "ENGLISH" : "English",
    "BELORUSSIAN": "Белорусский",
    "UKRAINIAN": "Украинский",
    "GERMAN": "Немецкий",
    "DUTCH": "Нидерландский",
    "FRENCH": "Французский",
    "ITALIAN": "Итальянский",
    "SPANISH": "Испанский",
    "DANISH": "Датский",
    "CATALAN": "Каталонский",
    "SLOVENIAN": "Словенский",
    "PORTUGUESE": "Португальский",
    "ROMANIAN": "Румынский",
    "SLOVAK": "Словацкий",
    "NORWEGIAN": "Норвежский",
    "FINNISH": "Финский",
    "POLISH": "Польский",
    "GREEK": "Греческий",
    "ESTONIAN": "Эстонский",
    "SWEDISH": "Шведский",
    "HUNGURIAN": "Венгерский",
    "LITHUANIAN": "Литовский",
    "THAI": "Тайский",
    "SHORT_ANALYTICS" : "Краткая аналитика",
    "SELECTION" : "Выборка",
    "EXTENDED_ANALYTICS" : "Развернутая аналитика",
    "TOPICS_COMPARISON" : "Сравнение тем",
    "REPORTS_BUILDER" : "Конструктор отчетов",
    "AUTO_RULES" : "Авто правила",
    "STATISTIC_DESIGN" : "Дизайн статистики",
    "NOTIFICATIONS" : "Уведомления",
    "FAQ" : "FAQ",
    "CONTACT_US" : "Связаться с нами",
    "_COMMENT_5":"верхнее меню краткой аналитики",
    "SETTINGS": "Настройки",
    "ACCOUNTS_CABINET": "Кабинет аккаунта",
    "TOPICS" : "Темы",
    "MASS_MEDIA" : "Масс-медиа",
    "SOC_MEDIA" : "Соц. медиа",
    "TOTAL_1" : "Суммарно",
    "DIAGRAMS" : "Диаграммы",
    "COMPARISON" : "Сравнение",
    "_COMMENT_5_1":"фильтры страницы краткой аналитики по темам",
    "ALPHABETICALLY_A-Z_↓" : "ПО АЛФАВИТУ А-Я ↓",
    "ALPHABETICALLY_Z-A_↑" : "ПО АЛФАВИТУ А-Я ↑",
    "LAST_VIEWED_DATE_↓" : "ПО ДАТЕ ПОСЛЕДНЕГО ПРОСМОТРА ↓",
    "LAST_VIEWED_DATE_↑" : "ПО ДАТЕ ПОСЛЕДНЕГО ПРОСМОТРА ↑",
    "MONITORING_START_DATE_↓" : "ПО ДАТЕ НАЧАЛА МОНИТОРИНГА ↓",
    "MONITORING_START_DATE_↑" : "ПО ДАТЕ НАЧАЛА МОНИТОРИНГА ↑",
    "BY_LAST_PUBLICATION_DATE_↓" : "ПО ДАТЕ ПОСЛЕДНЕЙ ПУБЛИКАЦИИ ↓",
    "BY_LAST_PUBLICATION_DATE_↑" : "ПО ДАТЕ ПОСЛЕДНЕЙ ПУБЛИКАЦИИ ↑",
    "NUMBER_OF_NEW_POSTS_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ↓",
    "NUMBER_OF_NEW_POSTS_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ↑",
    "NUMBER_OF_POSTS_FOUND_↓" : "ПО КОЛИЧЕСТВУ НАЙДЕННЫХ ПУБЛИКАЦИЙ ↓",
    "NUMBER_OF_POSTS_FOUND_↑" : "ПО КОЛИЧЕСТВУ НАЙДЕННЫХ ПУБЛИКАЦИЙ ↑",
    "_COMMENT_5_2":"фильтры страницы краткой аналитики по масс-медиа, по социальным медиа, по суммарному значению",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_DAY_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ЗА ТЕКУЩИЕ СУТКИ ↓",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_DAY_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ЗА ТЕКУЩИЕ СУТКИ ↑",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_WEEK_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ С НАЧАЛА НЕДЕЛИ ↓",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_WEEK_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ С НАЧАЛА НЕДЕЛИ ↑",
    "NUMBER_OF_POSTS_FOR_THE_ENTIRE_MONITORING_PERIOD_↓" : "ПО КОЛИЧЕСТВУ ПУБЛИКАЦИЙ ЗА ВЕСТЬ ПЕРИОД МОНИТОРИНГА ↓",
    "NUMBER_OF_POSTS_FOR_THE_ENTIRE_MONITORING_PERIOD_↑" : "ПО КОЛИЧЕСТВУ ПУБЛИКАЦИЙ ЗА ВЕСТЬ ПЕРИОД МОНИТОРИНГА ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_POSITIVE_TONE_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ПОЛОЖИТЕЛЬНОЙ ТОНАЛЬНОСТИ ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_POSITIVE_TONE_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ ПОЛОЖИТЕЛЬНОЙ ТОНАЛЬНОСТИ ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEUTRAL_TONE_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ НЕЙТРАЛЬНОЙ ТОНАЛЬНОСТИ ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEUTRAL_TONE_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ НЕЙТРАЛЬНОЙ ТОНАЛЬНОСТИ ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEGATIVE_TONE_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ НЕГАТИВНОЙ ТОНАЛЬНОСТИ ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEGATIVE_TONE_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ НЕГАТИВНОЙ ТОНАЛЬНОСТИ ↑",
    "NUMBER_OF_NEW_POSTS_WITH_AGGRESIVE_CONTENT_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ АГРЕССИВНОГО СОДЕРЖАНИЯ ↓",
    "NUMBER_OF_NEW_POSTS_WITH_AGGRESIVE_CONTENT_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ АГРЕССИВНОГО СОДЕРЖАНИЯ ↑",
    "NUMBER_OF_NEW_POSTS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↓" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ В ИСТОЧНИКАХ С БОЛЬШОЙ АУДИТОРИЕЙ ↓",
    "NUMBER_OF_NEW_POSTS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↑" : "ПО КОЛИЧЕСТВУ НОВЫХ ПУБЛИКАЦИЙ В ИСТОЧНИКАХ С БОЛЬШОЙ АУДИТОРИЕЙ ↑",
    "_COMMENT_6":"Прочие элементы страницы краткой аналитики",
    "NEW_MESSAGES" : "НОВЫХ СООБЩЕНИЙ",
    "WEEK" : "НЕДЕЛЯ",
    "TOTAL_2" : "Всего",
    "TODAY" : "СЕГОДНЯ",
    "TODAY_STATISTIC" : "СТАТИСТИКА ЗА СЕГОДНЯ (С 00:00)",
    "_COMMENT_7":"страница выборки",
    "TOPIC_NOT_SELECTED" : "Тема не выбрана",
    "ALL_ON_PAGE" : "Все на странице",
    "ALL_LIST" : "Весь список",
    "SHOW" : "ПОКАЗАТЬ",
    "ADD_POST" : "Добавить публикацию",
    "DOWNLOAD" : "Выгрузить",
    "SELECTED" : "ВЫБРАНО",
    "COMMON_TAGS" : "ОБЩИЕ ТЕГИ",
    "SHARE" : "Поделиться",
    "TO_FAVORITE" : "В избранное",
    "TO_BLACK_LIST" : "В черный список",
    "FROM_BLACK_LIST" : "Из черного списка",
    "TO_TRASHBIN" : "В корзину",
    "FROM_TRASHBIN" : "Из корзины",
    "ANSWER" : "Ответить",
    "ADD_TAG" : "Добавить тег",
    "ARTICLE" : "СТАТЬЯ",
    "TRANSCRIBATION" : "ТРАНСКРИБАЦИЯ",
    "POST_3" : "ПОСТ",
    "REPOST" : "РЕПОСТ",
    "EXTENDED_REPOST" : "ДОПОЛНЕННЫЙ РЕПОСТ",
    "COMMENT" : "КОММЕНТАРИЙ",
    "review" : "Отзывы",
    "REVIEW" : "Отзывы",
    "REVIEW_2": "Отзыв",
    "TODAY_2" : "Сегодня",
    "WEEK_2" : "Неделя",
    "MONTH" : "Месяц",
    "YEAR": "Год",
    "TEXT_FILTER" : "ПОИСК ПО ТЕКСТУ",
    "TEXT_FILTER_INSTRUCTION": "ИНСТРУКЦИЯ ПОИСКА ПО ТЕКСТУ",
    "_COMMENT_8":"панель фильтрации",
    "SELECTION_DATES_FILTER" : "Выбрать период",
    "BEGIN" : "Начало",
    "END" : "Окончание",
    "MON" : "ПН",
    "TUE" : "ВТ",
    "WED" : "СР",
    "THU" : "ЧТ",
    "FRI" : "ПТ",
    "SAT" : "СБ",
    "SUN" : "ВС",
    "JAN" : "Январь",
    "FEB" : "Февраль",
    "MAR" : "Март",
    "APR" : "Апрель",
    "MAY" : "Май",
    "JUN" : "Июнь",
    "JUL" : "Июль",
    "AUG" : "Август",
    "SEP" : "Сентябрь",
    "OKT" : "Октябрь",
    "NOV" : "Ноябрь",
    "DEC" : "Декабрь",
    "SOURCE_TYPE" : "Тип источника",
    "SOCIAL_NETWORKS" : "Социальные сети",
    "SOCIAL" : "Социальные",
    "FACEBOOK" : "Facebook",
    "INSTAGRAM" : "Instagram",
    "VKONTAKTE" : "Vkontakte",
    "YOUTUBE" : "YouTube",
    "TWITTER" : "Twitter",
    "TELEGRAM" : "Telegram",
    "ODNOKLASSNIKI" : "Odnoklassniki",
    "RUTUBE" : "RuTube",
    "YANDEXZEN" : "ЯндексДзен",
    "TIKTOK" : "TikTok",
    "MOIMIR" : "Мой Мир",
    "RADIO" : "Радио (транскрибации)",
    "TELEVISION" : "Телевидение (транскрибации)",
    "TV" : "Телевидение (транскрибации)",
    "PRINT_MASS_MEDIA_PAPER" : "Печатные СМИ (бумажная версия)",
    "PRINT" : "Печатные СМИ (бумажная версия)",
    "PRINT_MASS_MEDIA_WEB" : "Печатные СМИ (интернет-версия)",
    "INTERNET_MASS_MEDIA" : "Интернет-СМИ",
    "INTERNET" : "Интернет",
    "NEWS_AGENCIES" : "Информационные агентства",
    "NEWS_AGENCY" : "Информационные агентства",
    "POST_TYPE" : "Тип публикации",
    "ARTICLE_2" : "Статья",
    "TRANSCRIBATION_2" : "Транскрибация",
    "POST_2" : "Пост",
    "REPOST_2" : "Репост",
    "ADDED_REPOST_2" : "Дополненный репост",
    "COMMENT_2" : "Комментарий",
    "SOURCES" : "Источники",
    "TAGS" : "Теги",
    "KEY_WORDS" : "Ключевые слова",
    "TONE_AGGRESSION_ADVERTISEMENT" : "Тональность, агрессия, реклама",
    "NEUTRAL" : "Нейтральные",
    "POSITIVE" : "Позитивные",
    "NEGATIVE" : "Негативные",
    "UNDEFINED": "Неопределенные",
    "WITH_OBSCENE_VOCABULARY" : "С нецензурной лексикой",
    "WITH_AGGRESSION" : "С агрессией",
    "WITH_ADVERTISEMENT" : "Рекламный контент",
    "WITH_CHECKED_TONE" : "С проверенной тональностью",
    "WITH_UNDEFINED_TONE" : "С неопределенной тональностью",
    "WITH_TONE_MODIFIED_BY_AUTORULE" : "С тональностью измененной автоправилом",
    "MODIFIED_BY_AUTORULE" : "Измененные автоправилом", 
    "AUDIENCE_MAS_MEDIA_COVERAGE_INVOLVEMENT" : "Регион источника",
    "INTERNATIONAL_MAS_MEDIA" : "Международные",
    "REPUBLICAN_MAS_MEDIA" : "Республиканские",
    "REGIONAL_MAS_MEDIA" : "Региональные",
    "PRINT_MAS_MEDIA" : "Печатные СМИ",
    "VERIFICATION_STATUS": "Статус проверки",
    'VERIFICATIONSTATUS': 'Статус проверки',
    "VERIFIED": "Проверенные",
    "DELETED": "Удален",
    "DUPLICATE": "Дубликаты",
    "NONRELEVANT": "Не релевантные",
    "OLD": "Старые",
    "RESTORED": "Восстановленные",
    "UNVERIFIED": "Не проверенные",
    "ANY": "Размеченные",
    "MORE_THAN_MILLION_SUBSCRIBERS" : "Больше 1 000 000 подписчиков",
    "LESS_THAN_MILLION_MORE_THAN_ONE_HUNDRED_THOUSANDS_SUBSCRIBERS" : "От 1 000 000 до 100 000 подписчиков",
    "LESS_THAN_ONE_HUNDRED_THOUSANDS_MORE_THAN_TEN_THOUSANDS_SUBSCRIBERS" : "От 100 000 до 10 000 подписчиков",
    "LESS_THAN_TEN_THOUSANDS_MORE_THAN_THOUSAND_SUBSCRIBERS" : "От 10 000 до 1 000 подписчиков",
    "LESS_THAN_THOUSAND_SUBSRIBERS" : "Менее 1 000 подписчиков",
    "AUDIENCE_NOT_DETERMINDED" : "Аудитория не определена",
    "POST_LANGUAGE" : "Язык публикации",
    "KAZAKH_2" : "Казахский",
    "RUSSIAN_2" : "Русский",
    "ENGLISH_2" : "Английский",
    "NOT_DETERMINDED" : "Не определен",
    "GEOGRAPHY" : "География",
    "NUR_SULTAN" : "Нур-султан",
    "ALMATY" : "Алматы",
    "SHYMKENT" : "Шымкент",
    "AKMOLA_REGION" : "Акмолинская область",
    "AKTOBE_REGION" : "Актюбинская область",
    "ALMATY_REGION" : "Алматинская область",
    "ATYRAU_REGION" : "Атырауская область",
    "EAST_KAZAKHSTAN_REGION" : "Восточно-Казахстанская область",
    "ZHAMBYL_REGION" : "Жамбылская область",
    "WEST_KAZAKHSTAN_REGION" : "Западно-Казахстанская область",
    "KARAGANDY_REGION" : "Карагандинская область",
    "KOSTANAY_REGION" : "Костанайская область",
    "KYZYLORDA_REGION" : "Кызылординская область",
    "MANGISTAU_REGION" : "Мангистауская область",
    "PAVLODAR_REGION" : "Павлодарская область",
    "NORT_KAZAKHSTAN_REGION" : "Северо-Казахстанская область",
    "TURKESTAN_REGION" : "Туркестанская область",
    "ABAY_REGION" : "Абай область",
    "GETISU_REGION" : "Жетысу область",
    "ULYTAU_REGION" : "Улытау область",
    "RUSSIA" : "Россия",
    "KIRGIZSTAN" : "Киргизия",
    "CIS" : "СНГ",
    "OTHERS": "Другие",
    "OTHER" : "Прочее",
    "NOT_DETERMINDED_2" : "Не определена",
    "LOCATION" : "Локация",
    "PERSON" : "Личность",
    "ORGANIZATION" : "Организация",
    "_COMMENT_9":"избранные публикации",
    "TOPIC" : "ТЕМА",
    "TOPICS" : "Темы",
    "BREAKDOWN_BY" : "РАЗБИВКА ПО",
    "HOURS": "Часам",
    "DAYS" : "Дням",
    "WEEKS" : "Неделям",
    "MONTHS" : "Месяцам",
    "CHOOSE_ALL_TOPICS" : "Выбрать все темы",
    "CHOOSE_ALL_SUBTOPICS" : "Выбрать все подтемы",
    "_COMMENT_10":"избранные авторы и источники",
    "TITLE" : "НАЗВАНИЕ",
    "TYPE" : "ТИП",
    "SOURCE" : "РЕСУРС",
    "SUBSCRIBERS" : "ПОДПИСЧИКОВ",
    "LAST_POST_DATE" : "ДАТА ПОСЛ. ПУБЛИКАЦИИ",
    "PUBL_TOTAL" : "ПУБЛ. ВСЕГО",
    "PUBL_IN_SELECTION" : "ПУБЛ. В ВЫБОРКЕ",
    "MARKS" : "ОТМЕТКИ",
    "TONE" : "ТОНАЛЬНОСТЬ",
    "TONES" : "ТОНАЛЬНОСТИ",
    "ACTIONS" : "ДЕЙСТВИЯ",
    "AUTHORS_AND_SOURCES" : "Авторы и источники",
    "POSTS_2" : "Публикации",
    "_COMMENT_10_1":"фильтры таблицы авторов и источников",
    "ALPHABETICALLY_A-Z_LATIN_↓" : "ПО АЛФАВИТУ A-Z ↓",
    "ALPHABETICALLY_Z-A_LATIN_↑" : "ПО АЛФАВИТУ A-Z ↑",
    "ASC" : "↓",
    "DESC" : "↑",
    "DATE_↓" : "ПО ДАТЕ ↓",
    "DATE_↑" : "ПО ДАТЕ ↑",

    "LIKES_↓": "ПО ЛАЙКАМ ↓",
    "LIKES_↑": "ПО ЛАЙКАМ ↑",

    "COMMENTS_↓": "ПО КОММЕНТАРИЯМ ↓",
    "COMMENTS_↑": "ПО КОММЕНТАРИЯМ ↑",

    "SHARES_↓": "ПО РЕПОСТАМ ↓",
    "SHARES_↑": "ПО РЕПОСТАМ ↑",

    "COVERAGE_↓": "ОХВАТ САЙТА ↓",
    "COVERAGE_↑": "ОХВАТ САЙТА ↑",

    "AGGRESIVE_FIRST" : "СНАЧАЛА НАЛИЧИЕ АГРЕССИИ",
    "OBSCENE_FIRST" : "СНАЧАЛА НАЛИЧИЕ НЕЦЕНЗУРНОЙ РЕЧИ",
    "ADVERTISEMENT_FIRST" : "СНАЧАЛА НАЛИЧИЕ РЕКЛАМЫ",
    "POSITIVE_TONE_FIRST_↓" : "СНАЧАЛА ПОЗИТИВНЫЕ ТОНАЛЬНОСТИ ↓",
    "POSITIVE_TONE_FIRST_↑" : "СНАЧАЛА ПОЗИТИВНОЙ ТОНАЛЬНОСТИ ↑",
    "NEUTRAL_TONE_FIRST_↓" : "СНАЧАЛА НЕЙТРАЛЬНОЙ ТОНАЛЬНОСТИ ↓",
    "NEUTRAL_TONE_FIRST_↑" : "СНАЧАЛА НЕЙТРАЛЬНОЙ ТОНАЛЬНОСТИ ↑",
    "NEGATIVE_TONE_FIRST_↓" : "СНАЧАЛА НЕГАТИВНОЙ ТОНАЛЬНОСТИ ↓",
    "NEGATIVE_TONE_FIRST_↑" : "СНАЧАЛА НЕГАТИВНОЙ ТОНАЛЬНОСТИ ↑",
    "_COMMENT_11":"развернутая аналитика",
    "DOWNLOAD_REPORT" : "Выгрузить отчет",
    "FORMAT_REPORT_AND_WILL_BE_SENDED_TO_EMAIL": "Сформируйте отчет и он будет отправлен на почту",
    "REPORT_IN_PROGRESS_AND_WILL_AVAILABLE_IN_NOTIFICATIONS": "Отчет формируется и будет доступен в уведомлениях",
    "REPORT_IN_PROGRESS_AND_WILL_BE_SENDED_TO_EMAIL": "Отчет формируется и будет отправлен на почту",
    "DASHBOARDS_LAYOUT" : "Расположение инфопанелей",
    "MENTIONING_BY_DATE" : "Упоминания по датам",
    "MENTIONING_BY_LANG" : "Упоминания по языкам",
    "NEWSBREAK" : "Инфоповоды",
    "TOTAL" : "ВСЕГО",
    "CREATE" : "Создать",
    "MERGE_NEWSBREAKS" : "Объединить инфоповоды",
    "NEUTRAL_3" : "НЕЙТРАЛЬНАЯ",
    "NOT_DETERMINDED_3" : "НЕ ОПРЕДЕЛЕНА",
    "POSITIVE_3" : "ПОЗИТИВНАЯ",
    "NEGATIVE_3" : "НЕГАТИВНАЯ",
    "TONE_BY_DATE" : "Тональность по датам",
    "TONE_BY_LANG" : "Тональность по языкам",
    "RUSSIAN_3" : "РУССКИЙ",
    "KAZAKH_3" : "КАЗАХСКИЙ",
    "ENGLISH_3" : "АНГЛИЙСКИЙ",
    "POST_TYPES_BY_DATES" : "Типы публикаций по датам",
    "POST_TYPES_BY_LANG" : "Типы публикаций по языкам",
    "SPECIAL_MARKS_BY_DATES" : "Особые отметки по датам",
    "SPECIAL_MARKS_BY_LANG" : "Особые отметки по языкам",
    "AGGRESION" : "АГРЕССИЯ",
    "OBSCENE_SPEECH" : "НЕЦЕНЗУРНАЯ РЕЧЬ",
    "ADVERTISEMENT" : "РЕКЛАМА",
    "AUTHORS" : "Авторы",
    "AUTHORS_NAME": "Имя автора",
    "INFLUENCE_OF_AUTHORS" : "Влиятельность авторов",
    "NUMBER_OF_SUBSCRIBERS" : "КОЛИЧЕСТВО ПОДПИСЧИКОВ",
    "COMMUNITIES" : "Сообщества",
    "INFLUENCE_OF_COMMUNITIES" : "Влиятельность сообществ",
    "MENTIONS" : "Упоминания",
    "TONE" : "Тональность",
    "SENTIMENT" : "Тональность",
    "TYPES_OF_POSTS" : "Типы публикаций",
    "SPECIAL_MARKS" : "Особые отметки",
    "TOP_20_MASS_MEDIA" : "Топ-20 СМИ",
    "MASS_MEDIA_TYPE" : "Тип СМИ",
    "REGIONS" : "Регионы",
    "COVERAGE": "Вовлеченность",
    "SITE_COVERAGE": "Охват сайта",
    "REACH_OF_SOURCE" : "Охват",
    "REACH_OF_SOURCE_BY_SENTIMENT" : "Охват по тональности",
    "REACH_OF_SOURCE_BY_COUNT" : "Охват по количеству",
    "POPULAR_WORDS" : "Популярные слова",
    "ENTITIES" : "Сущности",
    "GEOGRAPHY_OF_AUTHORS" : "География авторов",
    "AUTHOR_STATISTICS" : "Статистика авторов",
    "AUTHOR_STATISTICS_GENDER" : "Статистика авторов по полу",
    "AUTHOR_STATISTICS_AGE" : "Статистика авторов по возрасту",
    "TOP_20_MASS_MEDIA_BY_LANG" : "Топ-20 СМИ по языкам",
    "TONE_BY_MASS_MEDIA_TYPES" : "Тональность по типам источника",
    "NEWS_AGENCIES_2" : "ИНФОРМАЦИОННЫЕ АГЕНТСТВА",
    "WEBSITES" : "ИНТЕРНЕТ САЙТЫ",
    "PRINT_MASS_MEDIA" : "ПЕЧАТНЫЕ СМИ",
    "TELEVISION_2" : "Телевидение",
    "TONE_BY_REGIONS" : "Тональность по регионам",
    "SOURCE_TYPES_BY_SCALE" : "Типы источников по масштабу",
    "NUR_SULTAN_2" : "НУР-СУЛТАН",
    "ALMATY_2" : "АЛМАТЫ",
    "SHYMKENT_2" : "ШЫМКЕНТ",
    "AKMOLA_REGION_2" : "АКМОЛИНСКАЯ",
    "AKTOBE_REGION_2" : "АКТЮБИНСКАЯ",
    "ALMATY_REGION_2" : "АЛМАТИНСКАЯ",
    "ATYRAU_REGION_2" : "АТЫРАУСКАЯ",
    "EAST_KAZAKHSTAN_REGION_2" : "ВКО",
    "ZHAMBYL_REGION_2" : "ЖАМБЫЛСКАЯ",
    "WEST_KAZAKHSTAN_REGION_2" : "ЗКО",
    "KARAGANDY_REGION_2" : "КАРАГАНДИНСКАЯ",
    "KOSTANAY_REGION_2" : "КОСТАНАЙСКАЯ",
    "KYZYLORDA_REGION_2" : "КЫЗЫЛОРДИНСКАЯ",
    "MANGISTAU_REGION_2" : "МАНГИСТАУСКАЯ",
    "PAVLODAR_REGION_2" : "ПАВЛОДАРСКАЯ",
    "NORT_KAZAKHSTAN_REGION_2" : "СКО",
    "TURKESTAN_REGION_2" : "ТУРКЕСТАНСКАЯ",
    "ABAY_REGION_2" : "АБАЙ",
    "GETISU_REGION_2" : "ЖЕТЫСУ",
    "ULYTAU_REGION_2" : "УЛЫТАУ",
    "REGIONAL" : "РЕГИОНАЛЬНЫЕ",
    "CENTRAL" : "Центральные  СМИ",
    "INTERNATIONAL" : "МЕЖДУНАРОДНЫЕ",
    "TAGS_LIST" : "Список тегов",
    "TAGS_CLOUD" : "Облако тегов",
    "TAGS_BY_TIME" : "Теги по времени",
    "POSTS_BY_TAGS" : "Публикации по тегам",
    "TAG" : "ТЕГ",
    "EDIT_TAG": " Редактировать тег",
    "NUMBER_OF_ASSIGNMENTS" : "КОЛИЧЕСТВО ПРИСВОЕНИЙ",
    "SPECIAL_MARKS_2" : "ОСОБЫЕ ОТМЕТКИ",
    "TONES_OF_POSTS" : "ОТТЕНКИ ПУБЛИКАЦИЙ",
    "VIEW_POSTS" : "Просмотреть публикации",
    "shares_count": "Репосты",
    "likes_count": "Лайки",
    "comments_count" : "Комментарий",
    "GRADE": "Оценка",
    "POSTS" : "Посты",
    "REPOSTS" : "Репосты",
    "LIKES" : "Лайки",
    "COMMENTS" : "Комментарий",
    "ADDED_REPOSTS" : "Дополненные репосты",
    "LIST_OF_KEYWORDS" : "Список ключевых слов",
    "KEYWORDS_CLOUD" : "Облако ключевых слов",
    "KEYWORDS_BY_TIME" : "Ключевые слова по времени",
    "POSTS_BY_KEYWORDS" : "Публикации по ключевым словам",
    "LIST_OF_POPULAR_WORDS" : "Список популярных слов",
    "POPULAR_WORDS_CLOUD" : "Облако популярных слов",
    "POPULAR_WORDS_BY_TIME" : "Популярные слова по времени",
    "POSTS_BY_POPULAR_WORDS" : "Публикации по популярным словам",
    "POPULAR_WORDS_BY_SENTIMENT" : "Популярные слова по тональности",
    "LIST_OF_ENTITIES" : "Список сущностей",
    "ENTITIES_CLOUD" : "Облако сущностей",
    "ENTITIES_BY_TIME" : "Сущности по времени",
    "POSTS_BY_ENTITIES" : "Публикации по сущностям",
    "Male": "Мужчины",
    "Female": "Женщины",
    "WEMAN" : "Женщины",
    "MEN" : "Мужчины",
    "_COMMENT_12":"сравнение тем",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_TYPE" : "Сравнение тем по типу СМИ",
    "MASS_MEDIA_TYPE_&_SOCIAL_NETWORKS" : "Тип СМИ и социальных сетей",
    "LANGUAGE" : "Язык",
    "MASS_MEDIA_STATUS" : "Статус СМИ",
    "TOPICS_COMPARISON_BY_MENTIONS": "Сравнение тем по упоминаниям",
    "TOPICS_COMPARISON_BY_MEDIA_TYPE" : "Сравнение тем по типу СМИ",
    "TOPICS_COMPARISON_BY_TONE" : "Сравнение тем по тональности",
    "TOPICS_COMPARISON_BY_SPECIAL_MARKS" : "Сравнение тем по особым отметкам",
    "TOPICS_COMPARISON_BY_LANG" : "Сравнение тем по языкам",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_STATUS" : "Сравнение тем по статусу СМИ",
    "TOPICS_COMPARISON_BY_REGIONS" : "Сравнение тем по регионам",
    "_COMMENT_13":"страница конструктора отчетов",
    "LIST_OF_REPORTS" : "Список отчетов",
    "CREATE_REPORT" : "Создать отчет",
    "REPORT_BUILDER" : "Конструктор отчета",
    "PAGES_ORIENTATION" : "ОРИЕНТАЦИЯ ЛИСТОВ",
    "APPLY" : "Применить",
    "DOWNLOAD_FINISHED_REPORT" : "Скачать готовый отчет",
    "EMPTY_DOCUMENT" : "В отчете нет данных",
    "SORTING" : "Сортировка",
    "DATE_OF_CREATION" : "ДАТА СОЗДАНИЯ",
    "DATE_OF_GENERATION" : "ДАТА ГЕНЕРАЦИИ",
    "REPORT_DELETING" : "Удаление отчета",
    "CONFIRM_REPORT_DELETING" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ ОТЧЕТА",
    "AVAILABLE_ELEMENTS" : "Доступные элементы",
    "TEXT" : "Текст",
    "TABLE" : "Таблица",
    "CHART" : "График",
    "LIST" : "Список",
    "VALUE_WITH_DESCRIPTION" : "Значение с описанием",
    "EXPORTED_FILTERS" : "Экспортированные фильтры",
    "DATA_OF_EXTENDED_ANALYTICS_PAGE" : "Данные страницы развернутой аналитики",
    "DATA_OF_TOPICS_COMPARISON_PAGE" : "Данные страницы сравнения тем",
    "DATA_OF_FAVORITE_POSTS_PAGE" : "Данные страницы избранных публикаций",
    "DATA_FO_FAVORITE_AUTHORS_AND_SOURCES_PAGE" : "Данные страницы избранных авторов и источников",
    "PERIOD" : "Период",
    "BREAKDOWN_ON" : "РАЗБИВКА ПО",
    "TOPIC_&_SUBTOPIC" : "Тема и подтема",
    "SUBTOPIC" : "ПОДТЕМА",
    "SUBTOPICS" : "ПОДТЕМЫ",
    "SET_TOPIC_FOR_THE_WHOLE_REPORT" : "Задать тему для всего отчета",
    "SAVED_FILTER_SETTINGS" : "Сохраненные настройки фильтров",
    "SET_FILTER_FOR_THE_WHOLE_REPORT" : "Задать фильтр для всего отчета",
    "DELETE" : "Удалить",
    "PAGE" : "СТРАНИЦА",
    "SECTION" : "РАЗДЕЛ",
    "NAME" : "НАЗВАНИЕ",
    "DESIGN" : "ДИЗАЙН",
    "FIELDS" : "ПОЛЯ",
    "_COMMENT_14":"Страница автоправил",
    "LIST_OF_AUTORULES" : "Список автоправил",
    "CREATE_AUTORULE" : "Создать автоправило",
    "ONLY_ADDINGS_TO_THE_BLACK_LIST" : "ТОЛЬКО ДОБАВЛЕНИЯ В ЧЕРНЫЙ СПИСОК",
    "ONLY_ADDINGS_TO_THE_FAVORITES" : "ТОЛЬКО ДОБАВЛЕНИЯ В СПИСОК ИЗБРАННЫХ",
    "ONLY_CHANGES_OF_VALUES" : "ТОЛЬКО ИЗМЕНЕНИЯ ЗНАЧЕНИЙ",
    "ONLY_NOTIFICATIONS_SENDING" : "ТОЛЬКО ОТПРАВКА УВЕДОМЛЕНИЙ",
    "ONLY_DELETING_OF_POSTS" : "ТОЛЬКО УДАЛЕНИЕ ПУБЛИКАЦИЙ",
    "AUTORULE_TITLE" : "НАЗВАНИЕ АВТОПРАВИЛА",
    "TYPE_OF_ACTION" : "ТИП ДЕЙСТВИЯ",
    "NUMBER_OF_ACTIVATIONS" : "КОЛИЧЕСТВО СРАБАТЫВАНИЙ",
    "LAST_POST" : "Последняя публикация",
    "OPEN_ON_PAGE" : "Открыть на странице",
    "AUTORULE_EDITING" : "Редактирование автоправила",
    "AUTORULE" : "АВТОПРАВИЛО",
    "CHOOSE_THE_GROUP_OF_VALUES" : "ВЫБЕРИТЕ ГРУППУ ЗНАЧЕНИЙ",
    "CHOOSE_THE_VALUE" : "ВЫБЕРИТЕ ЗНАЧЕНИЕ",
    "CHOOSE_THE_TYPE_OF_COMPARISON" : "ВЫБЕРИТЕ ВИД СРАВНЕНИЯ",
    "ENTER_THE_VALUE" : "ВВЕДИТЕ ЗНАЧЕНИЕ",
    "ADD_STRING" : "Добавить строку",
    "CHOOSE_THE_TYPE_OF_ACTION" : "ВЫБЕРИТЕ ТИП ДЕЙСТВИЯ",
    "ADD_ACTION" : "ДОБАВИТЬ ДЕЙСТВИЕ",
    "SAVE" : "Сохранить",
    "NUMBER" : "Число",
    "VARIANT" : "Вариант",
    "AUTHOR" : "Автор",
    "TITLE_2" : "Заголовок",
    "SOURCE_2" : "Источник",
    "KEY_PARAGRAPH_TEXT" : "Текст ключевого абзаца",
    "POST_TEXT" : "Текст публикации",
    "SOURCE_TAGS" : "Тег исходного интернет-ресурса",
    "DATE_OF_POST" : "Дата публикации",
    "INVOLVEMENT_INDEX" : "Индекс вовлеченности",
    "NUMBER_OF_DUBLICATES" : "Количество дубликатов",
    "NUMBER_OF_COMMENTS" : "Количество комментариев",
    "NUMBER_OF_LIKES" : "Количество лайков",
    "NUMBER_OF_SHARES" : "Количество пересылок",
    "NUMBER_OF_VIEWS" : "Количество просмотров",
    "SIZE_OF_AUDIENCE" : "Размер аудитории",
    "GEOGRAPHY_OF_POST" : "География публикации",
    "PRESENCE_OF_AGGRESSION" : "Наличие агрессии",
    "PRESENCE_OF_ADVERTISEMENT" : "Наличие рекламы",
    "PRESENCE_OF_OBSCENE_SPEECH" : "Наличие нецензурной речи",
    "TAG_2" : "Тег",
    "TYPE_OF_POST" : "Тип публикации",
    "TONE_OF_POST" : "Тональность публикации",
    "TOPIC_2" : "Тема",
    "NOT_EQUAL" : "Не равно",
    "EQUAL" : "Равно",
    "DOES_NOT_CONTAIN" : "Не содержит",
    "CONTAIN" : "Содержит",
    ">" : ">",
    "<" : "<",
    "=" : "=",
    ">=" : ">=",
    "<=" : "<=",
    "!=" : "!=",
    "ADD_TO_THE_FAVORITES" : "Добавить в избранные",
    "ADD_TO_THE_BLACK_LIST" : "Добавить в черный список",
    "CHANGE_VALUES" : "Изменить значения",
    "SEND_NOTIFICATION" : "Отправить уведомление",
    "POST" : "Публикация",
    "OBSCENE_SPEECH_2" : "Нецензурная речь",
    "ADVERTISEMENT_2" : "Реклама",
    "TO_EMAIL" : "На адрес электронной почты",
    "TO_TELEGRAM" : "В Telegram",
    "YES" : "Есть",
    "NO" : "Нет",
    "NEUTRAL_4" : "Нейтральная",
    "NOT_DETERMINDED_4" : "Не определена",
    "POSITIVE_4" : "Позитивная",
    "NEGATIVE_4" : "Негативная",
    "_COMMENT_15":"модальные окна",
    "STATUSES_OF_SERVER_REQUESTS" : "Статусы запросов к серверу",
    "PERIOD_2" : "ПЕРИОД",
    "DATA" : "ДАТА",
    "STATUS" : "СТАТУС",
    "READY_SENT_TO_THE_EMAIL" : "Готов - отправлен на почту",
    "READY" : "Готов",
    "REQUEST_IS_PROCESSING_PLEASE_WAIT" : "Запрос в обработке ожидайте",
    "ERROR" : "Ошибка",
    "ERROR_RE_REQUEST_REQUIRED" : "Ошибка - требуется повторный запрос",
    "ERROR_CONTACT_SUPPORT" : "Ошибка - обратитесь в службу поддержки",
    "GETTING_SELECTION_REPORT" : "Получение отчета по выборке",
    "UPLOAD" : "Выгрузка",
    "GETTING_CUSTOM_REPORT" : "Получение пользовательского отчета",
    "RESEND_POST" : "Переслать публикацию",
    "SEND_TO_THE_EMAIL" : "ОТПРАВИТЬ НА ПОЧТУ",
    "SEND_TO_THE_TELEGRAM" : "ОТПРАВИТЬ В TELEGRAM",
    "EMAIL_ADDRESS" : "АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ",
    "THOOSE_NAME_OF_THE_GROUP" : "ВЫБЕРИТЕ НАЗВАНИЕ ГРУППЫ",
    "PHONE_OF_THE_TELEGRAM_USER" : "НОМЕР ТЕЛЕФОНА ПОЛЬЗОВАТЕЛЯ TELEGRAM",
    "SAVE_LINK_TO_THE_CLIPBOARD" : "Сохранить ссылку в буфер обмена",
    "SAVE_FILTER_SETTINGS" : "Сохранение настроек фильтров",
    "ENTER_SETTINGS_NAME" : "ВВЕДИТЕ НАЗВАНИЕ НАСТРОЕК",
    "ADDING_POST" : "Добавление публикации",
    "POST_LINK" : "ССЫЛКА НА ПУБЛИКАЦИЮ",
    "SCAN_LINK" : "Сканировать ссылку",
    "YOUR_LINK_SCAN_RESULT_WILL_BE_DISPLAYED_HERE" : "ЗДЕСЬ ОТОБРАЗИТЬСЯ РЕЗУЛЬТАТ СКАНИРОВАНИЯ ВАШЕЙ ССЫЛКИ",
    "CONTACT_SUPPORT" : "Обратиться в службу поддержки",
    "ADD_EXISTING_TAGS" : "Добавление существующего тега",
    "CREATE_NEW_TAG" : "Создать новый тег",
    "TEXT_OF_TAG" : "ТЕКСТ ТЕГА",
    "ENTER_TEXT_OF_TAG" : "ВВЕДИТЕ ТЕКСТ ТЕГА",
    "FILL_COLOR" : "ЦВЕТ ЗАЛИВКИ ТЕГА",
    "TAG_PREVIEW" : "ПРЕДПРОСМОТР ТЕГА",
    "_COMMENT_16":"база знаний и FAQ",
    "CONTENT" : "Содержание",
    "CONTENT_SEATCH" : "ПОИСК ПО СОДЕРЖАНИЮ",
    "TEXT_DEEP_SEARCH" : "ГЛУБОКИЙ ПОИСК ПО ТЕКСТУ",
    "REPORT_MISTAKE" : "Сообщить об ошибке",
    "IMAGE" : "Изображение",
    "MISTAKE_IN_THE_KNOWLEDGE_BASE" : "Ошибка в базе знаний",
    "KNOWLEDGE_BASE_SECTION_NAME" : "НАЗВАНИЕ РАЗДЕЛА БАЗЫ ЗНАНИЙ",
    "KNOWLEDGE_BASE_ARTICLE_NAME" : "НАЗВАНИЕ СТАТЬИ БАЗЫ ЗНАНИЙ",
    "DESCRIPTION" : "ОПИСАНИЕ",
    "DESCRIBE_MISTAKE_OR_INACCURACY_YOU_ENCOUNTERED" : "ОПИШИТЕ ОШИБКУ ИЛИ НЕТОЧНОСТЬ, С КОТОРОЙ ВЫ СТОЛКНУЛИСЬ",
    "_COMMENT_17":"Настройки",
    "USERS" : "Пользователи",
    "DIGESTS" : "Дайджесты",
    "REPORTS" : "Отчеты",
    "SAVED_FILTERS" : "Сохраненные фильтры",
    "LIST_OF_FILTERS" : "Список фильтров",
    "CONNECTED_ACCOUNTS" : "Подключенные учетные записи",
    "USERS_LOGS" : "Логи пользователей",
    "_COMMENT_18":"настройки прав пользователей",
    "LIST_OF_USERS" : "Список пользователей",
    "#" : "№",
    "EMAIL_2" : "EMAIL",
    "CREATE_USER" : "Создать пользователя",
    "EDITING_USER_RIGHTS" : "Редактирование прав пользователя",
    "RIGHTS_LIST_FILTER" : "Фильтрация списка прав",
    "ACTIVATED_FIRST" : "Сначала активированные",
    "DEACTIVATED_FIRST" : "Сначала деактивированные",
    "CREATE_USERS" : "Создание пользователей",
    "EDIT_DELETE_USERS" : "Редактирование/удаление пользователей",
    "VIEW_USERS_LIST" : "Просмотр списка пользователей",
    "CREATE_TOPICS" : "Создание тем",
    "EDIT_DELETE_TOPICS" : "Редактирование/удаление тем",
    "VIEW_TOPICS_LIST" : "Просмотр списка тем",
    "FAVORITES_LISTS_WORKING" : "Работа со списками избранных",
    "TRASHBIN_LISTS_WORKING" : "Работа со списками корзины",
    "BLACK_LISTS_WORKING" : "Работа с черными списками",
    "AUTORULES_CREATION" : "Создание автоправил",
    "EDIT_DELETE_AUTORULES" : "Редактирование/удаление автоправил",
    "AUTORULES_VIEW" : "Просмотр автоправил",
    "VIEW_TOPICS_SELECTION" : "Просмотр выборки по темам",
    "MANUAL_POST_ADDING" : "Ручное добавление публикаций",
    "POST_TONE_CHANGING" : "Изменение тональности публикаций",
    "CREATE_FILTERS_SETTING" : "Создание настроек фильтров",
    "EDIT_DELETE_FILTER_SETTINGS" : "Редактирование/удаление настроек фильтров",
    "APPLY_FILTER_SETTINGS" : "Применение настроек фильтров",
    "CREATE_REPORTS" : "Создание отчетов",
    "EDIT_DELETE_REPORTS" : "Редактирование/удаление отчетов",
    "VIEW_DOWNLOAD_REPORTS" : "Просмотр и выгрузка отчетов",
    "VIEW_EXTENDED_ANALYTICS" : "Просмотр развернутой аналитики",
    "DOCUMENTS_DOWNLOAD_ANALYTICS_SELECTION" : "Выгрузка документов (аналитика/выборка)",
    "VIEW_TOPICS_COMPARISON" : "Просмотр сравнения тем",
    "LIST_OF_DIGESTS" : "Список дайджестов",
    "DIGESTS_CREATION" : "Создание дайджестов",
    "EDIT_DELETE_DIGESTS" : "Редактирование/удаление дайджестов",
    "VIEW_DIGESTS_LIST" : "Просмотр списка дайджестов",
    "CREATE_EMAIL_NOTIFICATIONS" : "Создание уведомлений на почту",
    "EDIT_DELETE_EMAIL_NOTIFICATIONS" : "Редактирование/удаление уведомлений на почту",
    "VIEW_EMAIL_NOTIFICATIONS_LIST" : "Просмотр списка уведомлений на почту",
    "CREATE_TELEGRAM_NOTIFICATIONS" : "Создание уведомлений Telegram",
    "EDIT_DELETE_TELEGRAM_NOTIFICATIONS" : "Редактирование/удаление уведомлений Telegram",
    "VIEW_TELEGRAM_NOTIFICATIONS_LIST" : "Просмотр списка уведомлений Telegram",
    "ANSWER_POSTS" : "Ответ на публикации",
    "LINKING_THIRD_PARTY_ACCOUNTS" : "Привязка учетных записей сторонних ресурсов",
    "EDIT_DELETE_LINKED_THIRD_PARTY_ACCOUNTS" : "Редактирование/удаление привязанных учетных записей сторонних ресурсов",
    "CREATE_NEWSBREAKS" : "Создание инфоповодов",
    "EDIT_DELETE_NEWSBREAKS" : "Редактирование/удаление инфоповодов",
    "VIEW_NEWSBREAK_SELECTION" : "Просмотр выборки по инфоповоду",
    "_COMMENT_19":"настройки данных и списка тем пользователей",
    "USER_DATA_EDIT" : "Редактирование данных пользователя",
    "PHONE_NUMBER" : "НОМЕР ТЕЛЕФОНА",
    "CHANGE_PASSWORD" : "Сменить пароль",
    "NEW_PASSWORD" : "НОВЫЙ ПАРОЛЬ",
    "REPEAT_NEW_PASSWORD" : "ПОВТОР НОВОГО ПАРОЛЯ",
    "LIST_OF_ASSIGNED_TOPICS" : "СПИСОК ПРИСВОЕННЫХ ТЕМ",
    "LIST_OF_RIGHTS" : "СПИСОК ПРАВ",
    "LIST_OF_USER_ASSIGNED_TOPICS_EDIT" : "Редактирование списка тем доступных пользователю",
    "LIST_OF_TOPICS_FILTER" : "ФИЛЬТР СПИСКА ТЕМ",
    "SORT_BY" : "СОРТИРОВКА ПО",
    "DELETE_USER" : "Удаление пользователя",
    "CONFIRM_THIS_USER_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕГО ПОЛЬЗОВАТЕЛЯ",
    "YOU_HAVE_UNSAVED_CHANGES_IN_DATA" : "У вас есть несохраненные изменения данных",
    "DONT_SAVE" : "Не сохранять",
    "_COMMENT_20":"настройки дайджестов",
    "TOPIC_NAME" : "НАЗВАНИЕ ТЕМЫ",
    "PERIODICITY" : "ПЕРИОДИЧНОСТЬ",
    "SENDING_TIME" : "ВРЕМЯ ОТПРАВКИ",
    "FORMAT" : "ФОРМАТ",
    "CREATE_DIGEST" : "Создать дайджест",
    "DIGEST_DISTRIBUTION_SETUP" : "Настройка рассылки дайджеста",
    "NUMBER_OF_DIGESTS_PER_DAY" : "КОЛИЧЕСТВО ДАЙДЖЕСТОВ В ДЕНЬ",
    "DIGESTS_SENDING_TIME" : "ВРЕМЯ ОТПРАВКИ ДАЙДЖЕСТОВ",
    "DIGESTS_SENDING_DAYS" : "ДНИ ОТПРАВКИ ДАЙДЖЕСТОВ",
    "CHOOSE_ALL" : "Выбрать все",
    "MONDAY" : "Понедельник",
    "TUESDAY" : "Вторник",
    "WEDNESDAY" : "Среда",
    "THURSDAY" : "Четверг",
    "FRIDAY" : "Пятница",
    "SATURDAY" : "Суббота",
    "SUNDAY" : "Воскресенье",
    "DIGESTS_SENDING_DATES" : "ДАТЫ ОТПРАВКИ ДАЙДЖЕСТОВ",
    "ENTER_DATE" : "ВВЕДИТЕ ДАТУ",
    "ADD" : "Добавить",
    "RECIPIENTS_EMAILS" : "EMAIL ПОЛУЧАТЕЛЕЙ",
    "ENTER_EMAIL" : "ВВЕДИТЕ EMAIL",
    "CHARTS" : "ГРАФИКИ",
    "DIGEST_WITHOUT_CHARTS" : "Дайджест без графиков",
    "DIGEST_FORMAT" : "ФОРМАТ ДАЙДЖЕСТА",
    "DIGEST_SETTINGS_VIEW" : "Просмотр настроек дайджеста",
    "DIGEST_DELETE" : "Удаление дайджеста",
    "CONFIRM_THIS_DIGEST_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕГО ДАЙДЖЕСТА",
    "_COMMENT_21":"настройки тем",
    "TOPICS_LIST" : "Список тем",
    "EDIT_DATE" : "ДАТА РЕДАКТИРОВАНИЯ",
    "KEY_WORDS_2" : "КЛЮЧЕВЫЕ СЛОВА",
    "STOP_WORDS" : "СТОП СЛОВА",
    "CREATE_TOPIC" : "Создать тему",
    "SUBTOPICS_LIST" : "Список подтем",
    "CREATE_SUBTOPIC" : "Создать подтему",
    "TOPIC_SETTINGS_EDIT" : "Редактирование настроек темы",
    "SUBTOPIC_SETTINGS_EDIT" : "Редактирование настроек подтемы",
    "TOPIC_SETTINGS_VIEW" : "Просмотр настроек темы",
    "GENERAL" : "Стандартные",
    "ADVANCED" : "Расширенные",
    "ENTER_TITLE" : "ВВЕДИТЕ НАЗВАНИЕ",
    "SELECTION_LIMIT" : "ОГРАНИЧЕНИЕ ВЫБОРКИ",
    "CONNECTION_WITH_CABINET" : "СВЯЗЬ С КАБИНЕТОМ",
    "OTHER_2" : "Другое",
    "MAIN" : "Главные",
    "COMPETITORS" : "Конкуренты",
    "PRODUCTS" : "Продукты",
    "PERSONS" : "Персоны",
    "AFFILIATED_COMPANIES" : "Дочерние компании",
    "COMPANIONS" : "Компаньоны",
    "PROMO" : "Акции",
    "DEPARTMENTS" : "Отделы",
    "AKIMS" : "Акимы",
    "MANAGEMENT" : "Управления",
    "ALL" : "Все",
    "WORLD_NEWS" : "Всемирные новости",

    "AZERBAIJAN" : "Azerbaijan",
    "ARMENIA" : "Armenia",
    "BELARUS" : "Belarus",
    "KAZAKHSTAN" : "Казахстан",
    "KYRGYZSTAN" : "Kyrgyzstan",
    "MOLDOVA" : "Moldova",
    "RUSSIA" : "Russia",
    "TAJIKISTAN" : "Tajikistan",
    "TURKMENISTAN" : "Turkmenistan",
    "UZBEKISTAN" : "Uzbekistan",
    "UKRAINE" : "Украина",

    "KEY_WORDS_CREATION" : "СОЗДАНИЕ КЛЮЧЕВЫХ СЛОВ",
    "ENTER_NEW_WORD" : "ВВЕДИТЕ НОВОЕ СЛОВО",
    "WORDS" : "СЛОВА",
    "TOPIC_GEOGRAPHY" : "ГЕОГРАФИЯ ТЕМЫ",
    "LINKS_TO_PROFILES_&_SOURCES" : "ССЫЛКИ НА ПРОФИЛИ И РЕСУРСЫ",
    "LINKS_TO_PROFILES" : "ССЫЛКИ НА ПРОФИЛИ",
    "LINKS_TO_SOURCES" : "ССЫЛКИ НА РЕСУРСЫ",
    "PROFILE_OR_SOURCE_URL" : "URL ПРОФИЛЯ ИЛИ РЕСУРСА",
    "SETTINGS_IMPORT" : "ИМПОРТ НАСТРОЕК",
    "TOPIC_DELETE" : "Удаление темы",
    "CONFIRM_THIS_TOPIC_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕЙ ТЕМЫ",
    "_COMMENT_22":"настройки отчета",
    "REPORT_PREVIEW_WINDOW" : "Окно предпросмотра отчета",
    "REPORT_SETTINGS" : "Настройки отчета",
    "REPORT_DESIGN" : "Оформление отчета",
    "GRAPHICS_AND_TABLES" : "Графики и таблицы",
    "REPORT_ELEMENT" : "Элемент отчета",
    "TOPIC_NAME_2" : "Наименование темы",
    "HEADERS_AND_FOOTERS" : "Колонтитулы",
    "COLLECTION_MAIN_INFORMATION" : "Основная информация по сбору",
    "MENTIONING_DYNAMICS" : "Динамика упоминаний",
    "POPULAR_RESOURCES" : "Популярные ресурсы",
    "GEOGRAPHY_OF_RESOURCES" : "География ресурсов",
    "MEDIA_TYPE" : "Вид СМИ (международные, региональные и т.д.)",
    "AUTHORS_OF_POSTS" : "Авторы постов (для соц. сетей)",
    "COMMUNITIES_FOR_SN" : "Сообщества (для соц. сетей)",
    "INVOLVMENT" : "Вовлеченность (для соц. сетей)",
    "STATISTIC_OF_AUTHORS" : "Статистика авторов (для соц. сетей)",
    "GEOGRAPHY_OF_AUTHORS_FOR_SN" : "География авторов (для соц. сетей)",
    "PUBLICATION_DETAILS" : "Детали документа",
    "TEXT_REPRESENTATION": "Текстовое представление",
    "NAME_OF_PUBLICATION" : "Наименование статьи",
    "ADDRESS" : "Адрес (URL)",
    "DATE" : "Дата",
    "DUBLICATES" : "Дубликаты",
    "PUBLICATION_SHORT_TEXT" : "Краткий текст статьи",
    "PUBLICATION_TEXT" : "Текст статьи",
    "NUMBERING" : "Нумерация",
    "SEPARATE_DUBLICATES" : "Разделить дубликаты",
    "GROUP_BY" : "Группировать по",
    "GROUP" : "Группировать",
    "GROUPING_TYPE": "Тип группировки",
    "TONE_2" : "тональности",
    "MASS_MEDIA_TYPES" : "типам СМИ",
    "RESOURCE" : "Ресурс",
    "RESOURCES" : "Ресурсы",
    "RESOURCES_2": "ресурсам",
    "WITHOUT_GROUPING" : "без группировки",
    "TAGS_2" : "тегам",
    "TONES_AND_MESS_MEDIA_TYPES" : "тональностям и типам СМИ",
    "MEDIA_TYPES_AND_TONES" : "типам СМИ и тональностям",
    "SUBTHEMES" : "подтемам",
    "_COMMENT_23":"настройки уведомлений",
    "LIST_OF_NOTIFICATIONS" : "Список уведомлений",
    "CREATED" : "СОЗДАНА",
    "ACTIVATED" : "СРАБАТЫВАНИЕ",
    "CONDITION_OF_ACTIVATION" : "УСЛОВИЕ СРАБАТЫВАНИЯ",
    "CREATE_NOTIFICATION" : "Создать уведомление",
    "CREATE_SUBSCRIPTION_NOTIFICATION" : "Создать подписку на уведомления",
    "NOTIFICATION_EDIT" : "Редактирование уведомления",
    "NOTIFICATION_VIEW" : "Просмотр уведомления",
    "EDIT_COMPANY" : "Редактирование компании",
    "SUBTHEME_NAME" : "НАЗВАНИЕ ПОДТЕМЫ",
    "MEDIA_TYPE_2" : "ТИП МЕДИА",
    "MEDIATYPE" : "ТИП МЕДИА",
    "REACTIONS" : "РЕАКЦИИ",
    "NUMBER_OF_POSTS" : "Количество публикаций",
    "NUMBER_OF_NEW_POSTS" : "Количество новых публикаций",
    "NUMBER_OF_SELECTED_POSTS" : "Количество выбранных публикаций",
    "CHANGE_OF_NUMBER_OF_PUBLICATIONS" : "Изменение количества публикаций",
    "WITH_NEGATIVE_TONE" : "С негативной тональностью",
    "WITH_NEUTRAL_TONE" : "С нейтральной тональностью",
    "WITH_POSITIVE_TONE" : "С позитивной тональностью",
    "WITH_UNIDENTIFIED_TONE" : "С неопределенной тональностью",
    "WITH_OBSCENE_SPEECH" : "С нецензурной речью",
    "WITHOUT_REGARD_TO_TONE_AND_MARKS" : "Без учета тональности и отметок",
    "PER_DAY" : "За сутки",
    "PER_WEEK" : "За неделю",
    "PER_MONTH" : "За месяц",
    "YOU_CAN_ENTER_NEGATIVE_VALUES_WITH_-_SIGN" : "Можно вводить отрицательное значение со знаком -.",
    "NOTIFICATON_DELETE" : "Удаление уведомления",
    "CONFIRM_THIS_NOTIFICATION_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕГО УВЕДОМЛЕНИЯ",
    "_COMMENT_24":"настройки telegram",
    "NUMBER_GROUP" : "НОМЕР/ГРУППА",
    "PHONE_NUMBERS_OR_GROUP_NAMES" : "НОМЕРА ТЕЛЕФОНОВ ПОЛУЧАТЕЛЕЙ ИЛИ НАЗВАНИЯ ГРУПП",
    "HOW_TO_SETUP_SENDING" : "КАК НАСТРОИТЬ ОТПРАВКУ?",
    "CHOOSE_GROUP_NAME" : "ВЫБЕРИТЕ НАЗВАНИЕ ГРУППЫ",
    "CHOOSE_PHONE_NUMBER" : "ВВЕДИТЕ НОМЕР ТЕЛЕФОНА",
    "RESET_ALL" : "Сбросить все",
    "_COMMENT_25":"настройки фильтров",
    "FILTER_DELETE" : "Удаление фильтра",
    "CONFIRM_THIS_FILTER_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕГО ФИЛЬТРА",
    "_COMMENT_26":"настройки подключенных учетных записей",
    "LIST_OF_ACCOUNTS" : "Список учетных записей",
    "CREATE_ACCOUNT" : "Создать аккаунт",
    "UPDATE_ACCOUNT": "Редактировать аккаунт",
    "SHOW_ACCOUNT": "Просмотр аккаунта",
    "ACCOUNT_DELETE" : "Удаление учетной записи",
    "CONFIRM_ACCOUNT_DELETE" : "ПОДТВЕРДИТЕ УДАЛЕНИЕ СЛЕДУЮЩЕЙ УЧЕТНОЙ ЗАПИСИ",
    "CONNECTION_OF_ACCOUNT" : "Подключение учетной записи",
    "RESOURCE" : "РЕСУРС",
    "CHOOSE_RESOURCE_NAME" : "ВЫБЕРИТЕ НАЗВАНИЕ РЕСУРСА",
    "ENTER_LOGIN" : "ВВЕДИТЕ ЛОГИН",
    "ENTER_PASSWORD" : "ВВЕДИТЕ ПАРОЛЬ",
    "_COMMENT_27":"логи пользователей",
    "CHOOSE_ALL_2" : "Выбрать всех",
    "LOG_FOR_SELECTED" : "Лог по выбранным",
    "VIEW_LOG" : "Показать лог",
    "DATE_2" : "ДАТА",
    "TIME" : "ВРЕМЯ",
    "NEXT_ACTION_TIME" : "ВРЕМЯ СЛЕД. ДЕЙСТВИЯ",
    "MOVING" : "ПЕРЕМЕЩЕНИЕ",
    "CREATING" : "СОЗДАНИЕ",
    "CHANGING" : "ИЗМЕНЕНИЕ",
    "DELETING" : "УДАЛЕНИЕ",
    "_COMMENT_28":"Сообщения для пользователя",
    "ONE_SHOULD_BE":"ОДНО ИЗ ПОЛЕЙ ДОЛЖНО БЫТЬ ЗАПОЛНЕНО",
    "EXIT":"Выход",
    "CLEAR_DATA_CONTENTS_DELETED_KEYWORDS": "Стереть данные содержащие удаленные ключевые слова?",
    "EVERY_PUBLICATION_TELEGRAM": "КАЖДАЯ ПУБЛИКАЦИЯ", 
    "NEGATIVE_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕГАТИВНЫХ",
    "NEUTRAL_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕЙТРАЛЬНЫХ", 
    "POSITIVE_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО ПОЗИТИВНЫХ", 
    "UNDEFINED_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕ ОПРЕДЕЛЕННЫХ", 
    "NEGATIVE_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕГАТИВНЫХ", 
    "NEUTRAL_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕЙТРАЛЬНЫХ", 
    "POSITIVE_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ ПОЗИТИВНЫХ", 
    "UNDEFINED_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕ ОПРЕДЕЛЕННЫХ", 
    "EVERY_PUBLICATION_PERCENT_TELEGRAM": "КАЖДЫЙ ПРОЦЕНТ ПУБЛИКАЦИИ",
    "EVERY_PUBLICATION": "КАЖДАЯ ПУБЛИКАЦИЯ",
    "EVERY_PUBLICATION_PERCENT": "КАЖДЫЙ ПРОЦЕНТ ПУБЛИКАЦИИ",
    "PERCENT": "ПРОЦЕНТ",
    "ALERT_POLICY_AGREGATE_COUNT": "ОПОВЕЩЕНИЕ - КОЛИЧЕСТВО",
    "ABSOLUTE_COUNT": "АБСОЛЮТНОЕ КОЛИЧЕСТВО",
    "AGGREGATE": "СОВОКУПНОСТЬ",
    "AGGREGATE_PERCENT": "ПРОЦЕНТ",
    "AGGREGATE_COUNT": "КОЛИЧЕСТВО",
    "AGGREGATE_ABSOLUTE_COUNT": "АБСОЛЮТНОЕ КОЛИЧЕСТВО",
    "METRCIC": "Метрика",
    "METRIC_TYPES": "Тип метрики",
    "COUNT" : "КОЛИЧЕСТВО",
    "POLICY": "ПОЛИТИКА",
    "POLICIES": "ПОЛИТИКИ",
    "HOURLY": "Час",
    "DAILY" : "День",
    "WEEKLY" : "Неделя",
    "MONTHLY" : "Месяц",
    "PUBLICTION": "Публикацию",
    "RESOURCE": "Источник",
    "FROM_FAVOURITE": "Из избранных",
    "PROFILE_URL": "URL профиля",
    "RESOURCE_URL": "URL источника",
    "PROFILE": "Профиль",
    "PROFILES": "Профили",
    "CREATED_AT": "ДАТА СОЗДАНИЯ",
    "UPDATED_AT": "ДАТА ИЗМЕНЕНИЯ",
    "DELIVERY_TYPE": "ТИП УВЕДОМЛЕНИЯ",
    "DAYS_COUNT" : "КОЛИЧЕСТВО ДНЕЙ",
    "ADMIN": "Администратор",
    "COMPANY_MANAGEMENT" : "Управление компаниями",
    "CREATE_COMPANY" : "Создать компанию",
    "USER_MANAGEMENT" : "Управление пользователями",
    "TOPIC_MANAGEMENT" : "Управление темамим",
    "WITHOUT_FILTER_TYPE": "Без типа фильтра",
    "WITH_TAGS": "С тегом",
    "WITHOUT_TAGS": "Без тегов",
    "VIEW_FILTER": "Просмотреть фильтр",

    'TOPIC_NAME': 'Наименование темы', 
    'PERIOD': 'Период', 
    'REQUEST_DATE': 'Дата отчета', 
    'COLONTITLES': 'Колонтитулы', 
    'TABLE_OF_CONTENTS': 'Содержание',
    'MAIN_INFO': 'Основная информация по сбору', 
    'SENTIMENT_DYNAMICS': 'Динамика тональности', 
    'LANGUAGE_DYNAMICS': 'Динамика языков', 
    'LANGUAGE_TOTAL': 'Общая по языкам',
    'MEDIA_TYPE_DYNAMICS': 'Динамика типа источников', 
    'MEDIA_TYPE_TOTAL': 'Общая типа источника', 
    'MEDIA_TYPE_BY_SENTIMENT': 'Тип источника по тональности', 
    'KEYWORDS': 'Ключевые слова',
    'STOPWORDS': 'Стоп слова', 
    'POPULAR_WORDS': 'Популярные слова', 
    'TAGS': 'Теги', 
    'POPULAR_RESOURCES': 'Популярные ресурсы', 
    'MEDIA_GEOGRAPHY': 'География источников', 
    'MEDIA_SCALE': 'Регион источника',
    'MEDIASCALE': 'Регион источника', 
    'SOCIAL_DOMAIN_DYNAMICS': 'Динамика социальной области', 
    'SOCIAL_DOMAIN': 'Социальная область', 
    'AUTHORS': 'Авторы', 
    'GROUPS': 'Группы', 
    'AUTHORS_GEOGRAPHY': 'География авторов', 
    'PROFILE_AGE': 'Возраст профиля', 
    'PROFILE_GENDER': 'Пол профиля', 
    'INVOLVEMENT': 'Вовлеченность', 
    'PUBLICATION_TYPE_BY_SENTIMENT': 'Тип публикации по тональности', 
    'PUBLICATION_TYPE_TOTAL': 'Тип публикации общая', 
    'MENTIONS_FEED': 'Упоминания',
    'CREATE_REPORT_SETTINGS': 'Создание настроек отчета',
    'DOCUMENT_TITLE': 'Название документа', 
    'DOCUMENT_DOMAIN': 'Ресурс документа', 
    'DOCUMENT_AUTHOR': 'Автор документа', 
    'DOCUMENT_URL': 'URL документа', 
    'DOCUMENT_TYPE': 'Тип документа', 
    'DOCUMENT_SENTIMENT': 'Тональность документа', 
    'DOCUMENT_LOCATION': 'Местность документа', 
    'DOCUMENT_DATE': 'Дата документа', 
    'DOCUMENT_COVERAGE': 'Документация', 
    'DOCUMENT_TAGS': 'Теги документа', 
    'DOCUMENT_TEXT': 'Текст документа', 
    'DOCUMENT_NUMERATION': 'Нумерация документа',

    'HIGHLIGHTED_FULL_TEXT': 'Подсветка документа', 
    'PLAIN_FULL_TEXT': 'Полный текст', 
    'HIGHLIGHTED_FRAGMENTS': 'Подсветка фрагментов',

    "ADD_POLICY": "Добавить политику",
    "DELETE_POLICY": "Удалить политику",

    'BY_SENTIMENT': 'По тональности', 
    'BY_MEDIA_TYPE': 'По типу', 
    'BY_DOMAIN': 'По источнику', 
    'NO_GROUPING': 'Без группировки', 
    'BY_TAGS': 'По тегам', 
    'BY_SENTIMENT_AND_MEDIA_TYPE': 'По тональности и типам источников', 
    'BY_MEDIA_TYPE_AND_SENTIMENT': 'По типам источников и тональности', 
    'BY_SUBTOPICS': 'По подтемам',
    'COMPANY': 'КОМПАНИЯ',
    'COMPANY_1': 'КОМПАНИЯ',
    'COMPANIES': 'Компании',
    'ACCOUNTS': 'Аккаунты',
    'ACCOUNT': 'Аккаунт',
    'COMPANY_NAME': 'НАИМЕНОВАНИЕ КОМПАНИИ',
    'EXIT_COMPANY': 'Выйти из компании',
    'UPDATE': 'Редактировать',
    'COMPANY_ACCOUNTS': 'Аккаунты компании',
    'COMPANY_TOPICS': 'Темы компании',
    'ACCOUNT_TOPICS': 'Темы аккаунта',
    'MAREKER_TOPICS': 'Темы разметчика',
    'TOPIC_ACCOUNTS': 'Аккаунты темы',
    'ACCOUNT_LOCALE': 'Локализация',
    'ATTACH': 'Прикрепить',
    'DETACH': 'ОТКРЕПИТЬ',
    'ATTACH_ACCOUNT_TO_TOPIC': "Прикрепить тему пользователю",
    'ATTACH_TOPIC_TO_ACCOUNT': "Прикрепить пользователю тему",
    'ATTACH_TOPIC_TO_MARKER': "Прикрепить разметчику тему",
    'ATTACH_ACCOUNT_TO_COMPANY': 'Прикрепить пользователя к компании',
    'ATTACH_ACCOUNT_TO_COMPANY_AT_FIRST': 'Для редактирования следует прикрепить пользователя к компании',
    'DETACH_ACCOUNT_FROM_COMPANY': 'Открепить пользователя от компании',
    'ACTIVE': 'Активный',
    'WITH_TAG': 'С тегом',
    'WITHOUT_TAG': 'Без тега',
    "EXPIRE_DATE" : "Дата истечения срока",
    "BIN": "БИН",
    "ROLE": 'Роль',
    "SURE_WANT_TO_DELETE": "Вы действительно хотите удалить?",
    "SURE_WANT_TO_STOP": "Вы действительно хотите приостановить?",
    "SURE_WANT_TO_DETACH": "Вы действительно хотите открепить?",
    "SURE_WANT_TO_EXIT": "Вы действительно хотите выйти из системы?",
    "SURE_WANT_TO_EXIT_COMPANY": "Вы действительно хотите выйти из компании?",
    "AUTHORITIES": "Права доступа",
    "ROLE_SUPER_ADMIN": "Супер администратор",
    "ROLE_ADMIN": "Администратор",
    "ROLE_MARKER": "Разметчик",
    "ROLE_USER": "Пользователь",
    "STATES": "Страны",
    "EVERYTHING_EXCEPT_KAZAKHSTAN" : "Все кроме Казахстана",
    "MASS_INCLUDE": "Сбор по СМИ",
    "SOCIAL_INCLUDE": "Сбор по соц. сетям",
    "SOCIAL_ALLOW_UNDEFINED_LOCATION": "Соц. сети без локации",
    "PAUSE": "пауза",
    "TOPIC_CREATED_SUCCEFULLY": "Тема успешно создана",
    "SUBTOPIC_CREATED_SUCCEFULLY": "Подтема успешно создана",
    "TOPIC_CREATED_WITH_ERRORS": "Тема создана с ошибками",
    "SUBTOPIC_CREATED_WITH_ERRORS": "Подтема создана с ошибками",
    "TOPIC_NOT_CREATED": "Тема не была создана",
    "TOPIC_STARTED": "Тема успешно запущена",
    "TOPIC_STOPED": "Тема успешно приостановлена",
    "TOPIC_DELETED": "Тема успешно удалена",
    "SUBTOPIC_NOT_CREATED": "Подтема не была создана",
    "USER_TAGS": "Пользовательские теги",
    "ITEM_NAME": "Наименование",
    "WORD_TITLES": "WORD ЗАГОЛОВКИ",
    "WORD_ANALYTICS": "WORD АНАЛИТИКА",
    "PDF_TITLES": "PDF ЗАГОЛОВКИ",
    "PDF_ANALYTICS": "PDF АНАЛИТИКА",
    "EXCEL_TITLES": "EXCEL ЗАГОЛОВКИ",
    "EXCEL_ANALYTICS": 'EXCEL АНАЛИТИКА',
    "JSON_FEED": "JSON ВЫБОРКА",
    "JSON_ANALYTICS": "JSON АНАЛИТИКА",
    "JSON_FEED_ANALYTICS": "JSON ВЫБОРКА-АНАЛИТИКА",
    "STANDART_REPORT": "СТАНДАРТНЫЙ ОТЧЕТ",
    "INACTIVE": "Не активный",
    "BLOCKED": "Заблокирован",
    "CONTRACT_EXPIRED": "Срок действия договора истек",
    "DATE_CREATED_AT_↓" : "ПО ДАТЕ СОЗДАНИЯ ↓",
    "DATE_CREATED_AT_↑" : "ПО ДАТЕ СОЗДАНИЯ ↑",
    "DATE_UPDATED_AT_↓" : "ПО ДАТЕ ИЗМЕНЕНИЯ ↓",
    "DATE_UPDATED_AT_↑" : "ПО ДАТЕ ИЗМЕНЕНИЯ ↑",
    "SET_ACTIVE" : "Активировать",
    "SET_PAUSE" : "Приостановить",
    "SET_DELETE" : "Удалить",
    "SET_VIEW": "Просмотреть",
    "SET_EDIT": "Редактировать",
    "SET_ADD": "Прикрепить",
    "SET_REMOVE": "Открепить",
    "SET_COPY": "Копировать",
    "SET_RETURN": "Вернуть",
    "SET_GO_TO_COMPANY": "Перейти в компанию",
    "TOPICS_VISIBILITY": "Видимость тем",
    "SEARCH_BY_TITLE": "Искать по названию",
    "MARKERS": "Разметчики",
    "ARCHIVE_COLLECTION": "СБОР АРХИВА",
    "ARCHIVE_COLLECTION_BY_PRFILES": "СБОР АРХИВА ПО ПРОФИЛЯМ",
    "ARCHIVE_COLLECTION_BY_RESOURCES": "СБОР АРХИВА ПО РЕСУРСАМ",
    "URL_IS_ALREADY_BEING_CHECKED": "URL профиля найден",
    "URL_IS_NOT_BEING_CHECKED": "URL профиля не найден",
    "DOMAIN_IS_ALREADY_BEING_CHECKED": "Домен найден",
    "DOMAIN_IS_NOT_BEING_CHECKED": "Домен не найден",
    "MESSAGE_SENDED": "Сообщение отправлено",
    "THANK_FOR_MESSAGE": "Спасибо за сообщение, оно принято в обработку.",
    "FIELD_NOT_BE_EMPTY": "Поле контакт или email не должно быть пустым",
    "HOW_CAN_WE_CONTACT_TO_YOU": "Как мы можем к вам обращаться",
    "OBLIGATORY_FIELD": "Обязательно поле",
    "ONLY_NUMBERS_AND_LETTERS_AT_LEAST_3": "Только буквы и числа, не менее 3-х символов",
    "ONLY_NUMBERS_AND_LETTERS": "Только буквы и числа",
    "ONLY_LETTERS": "Только буквы",
    "FEED_TYPES": "Тип выборки",
    "MEDIA_TYPES" : "Тип СМИ",
    "SENTIMENTS": "Тональности",
    "LANGUAGES": "Языки",
    "MEDIA_SCALES": "Статус СМИ",
    "PROFILE_LOCATIONS": "Местоположение профилей",
    "tv": "Телевидение",
    "print": "Печатные СМИ",
    "news_agency": "Информационные агентства",
    "internet": "Интернет",
    "social": "Социальные сети",
    "positive": "Позитивные",
    "undefined": "Неопределенные",
    "negative": "Негативные",    
    "neutral": "Нейтральные",
    "regional": "Региональные",
    "central": "Центральные",
    "international": "Международные",
    "is_swearing": "Ненормативная лексика",
    "ISSWEARING": "Ненормативная лексика",
    "DOWNLOADS": "Выгрузка",
    "post": "Пост",
    "comment": "Комментарий",
    "share": "Репост",
    "article": "Статья",
    "extended_share": "Дополненный репост",
    "video": "Видео",
    "VIDEO": "Видео",
    "COLLAPSE": "Свернуть",
    "READ_MORE": "Читать далее",
    "RETURN_BACK_TO": "Вернуться на",
    "DELAYED_START_TIME": "ВРЕМЯ ОТЛОЖЕННОГО СТАРТА",
    "STARS_ON_CENTER_OR_END": "Символ звездочки (*) может использоваться только в конце или середине ключевого слова (стоп-слова).",
    "NO_AMPERSANDS": "Логический оператор AND (амперсанд, &&) может использоваться только для соединения двух и более ключевых (стоп-) слов, обязательно должен быть отделен от них символом пробела, и не может встречаться в начале или в конце строки.",
    "THREE_AMPERSANDS": "Недопустимо использование сочетания трех и более символов амперсанд (&) подряд.",
    "ONE_SYMBOL_WITH_STAR": "Символ звездочки (*) может использоваться с ключевыми (стоп-) словами длиной от двух символов и более; недопустыми сочетания вида а*, б* и пр.",
    "NO_CHARACHTERS": "Следующие символы недопустимы: :;!,%()\/\\@",
    "SINGLE_STAR": "Отдельностоящие символы звездочки (*) и вопросительного знака запрещены.",
    "EXCLUSIVE_QUERIES_WITHOUT_SUBQERIES" : "Исключающие запросы не должны иметь подзапросы.",
    "QUERY_WITHOUT_EXCLUSIVE_SUBQERIES" : "Запрос не должен иметь исключающих подзапросов.",
    "SELECT_FILTER": "Выбрать фильтр",
    "SELECT_LANGUAGE": "Выбрать язык",
    "SELECT_ROLE": "Выбрать роль",
    "is_advertising": "Реклама",
    "ISADVERTISING": "Реклама",
    "reviewInclude": "Сбор отзывов",
    "profile_followers_count": "Количество подписчиков",
    "WITH_ADVERTISING": "Рекламный контент",
    "WITHOUT_ADVERTISING": "Без рекламы",
    "WITHOUT_ADVERTISING_FILTERS": "Без фильтров рекламы",
    "WITHOUT_SWEARING": "Без ненормативной лексики",
    "WITH_SWEARING": "Ненормативная лексика",
    "WITHOUT_SWEARING_FILTERS": "Без фильтров ненормативной лексики",
    "UNKNOWN": "Неизвестные",

    "FOR_RELEVANT_COLLECTION": "Для релевантного сбора в теме, необходимо, подготовить КЛЮЧЕВЫЕ СЛОВА и СТОП СЛОВА используя логические операторы системы:",
    "LOGICAL_OPERATORS_WITH_EXAMPLES": "Логические операторы + примеры:",
    "OPERATOR": "Оператор",
    "WHAT_FOR_USE_IT": "Для чего использовать",
    "EXAMPLES_OF_KEYWORDS_WITH_OPERATORS": "Примеры ключевых слов с операторами",
    "RESULT_IN_PUBLICATION_TEXT": "Результаты в тексте публикации",
    "STAR": "* (звездочка)",
    "MATCHES_ENDING_TO_KEYWORD": "Подбирает окончание к ключевому слову, в том числе окончание может быть нулевым, также его склоняет.",
    "TALDYKORGAN_STAR": "Талдыкорган*",
    "TALDYKORGAN_DECLINATION": "Талдыкорган, Талдыкоргане, Талдыкорганом, Талдыкорганский и т.д.",
    "CAN_USE_COMPLEX_PHRASES": "Можно использовать более сложные словосочетания",
    "TALDYKORGAN_AVIA_BASE": "Талдыкорган* авиацион* баз*",
    "TALDYKORGAN_AVIA_BASE_DECLINATION": "Талдыкорганская авиационная база, Талдыкорганскую авиационную базу и т.д.",
    "LINKING_MULTIPLE_PARTS": "Связь нескольких частей ключевых слов, которые расположены в пределах текста одной публикации. При поиске соблюдается требование обязательного присутствия всех частей связанного ключевого слова. При этом порядок частей ключевого слова не имеет значения.",
    "TALDYKORGAN_SUBSIDIES": "Талдыкорган* && субсиди*",
    "CONSTRUCTIVE_DIALOG_IN_TALDYKORGAN": "В Талдыкоргане власти и бизнес вступили в конструктивный диалог. В области 4,5 тыс. субъектов АПК получили 10,5 млрд. тенге в виде субсидий.",
    "TALDYKORGAN_AUTHORITIES_AND_SUBSIDIES": "Талдыкорган* власт* && АПК && субсиди*",

    "TO_SEARCH_ENTER_WORDS": "Для поиска введите необходимые вам слова, нажмите добавить. Далее введённые слова будут отображаться ниже в списке.",
    "FOR_MORE_PRECISE": "Для более точного поиска воспользуйтесь следующими операторами поиска:",
    "EXCLUSIVE": "исключающий",
    "NEGATIVE_2": "негативный",
    "SEARCH_HIGHLIGHT": "подсветка при поиске:",
    "EXAMPLES_OF_USING_SEARCH_OPERATORS": "Примеры использования операторов поиска",
    "FOLLOWING_SEARCH_OPERATORS": "Поддерживаются следующие операторы поиска",
    "FUZZY_SEARCH": "нечеткий поиск",
    "AND": "и",
    "PRESUMPTIVE_SEARCH": "предположительный поиск",
    "CAN_BE_USED_A_WORD": "можно использовать в конце, середине или начале слова:",
    "WILL_RETURN": "вернет",
    "ETC": "т.д.",
    "USED_TO_MATCH_FIXED": "используется для соответствия фиксированному количеству символов (произвольных)",
    "RETURNED_CORONAVIRUS": "корон?вирус вернет как коронавирус, так и короновирус",
    "RETURNED_AMERICA": "казах?? вернет казахов и казахам, но не вернет казахи или казахами",
    "USED_AT_THE_END": "используется в конце фразы из двух или более слов для поиска текста, в котором эти слова отстоят друг от друга не больше, чем на N слов между ними:",
    "GAVE_AN_ORDER": '"президент поручение"~1 вернет результат "Президент дал поручение", при этом чтобы получить результат "Президент Республики Казахстан дал поручение", запрос придется сформулировать уже как "президент поручение"~3',
    "QUERIES_FORMULATED_INDIVIDUAL": "запросы можно формулировать как отдельными словами, так и фразами из нескольких слов",
    "SEARCH_OPERATORS_COMBINED": 'операторы поиска можно комбинировать: "корон?вирус*", "отмен? выбор*"~10',
    "HISTORICAL_MIGRATION": "Историческая миграция",
    "VIEW_HISTORICAL_MIGRATION": "Просмотр исторической миграции",
    "CREATE_HISTORICAL_MIGRATION": "Создание исторической миграции",
    "COLLECTION": "Коллекция",
    "ACCOUNT_NAME": "Имя аккаунта",
    "DATE_START": "Дата начала",
    "DATE_END": "Дата окончания",
    "EXPECTED_DOCUMENTS": "Количество ожидаемых документов",
    "CURRENT_DOCUMENTS": "Текущее количество документов",
    "LOADED_DOCUMENTS_PERCENT": "Процент загруженных документов",
    "START_MIGRATION": "Начать миграцию",
    "GET_EXPECTED_DOCUMENTS": "Получить предварительное количество документов",
    "EXPECTED_DOCS / CURRENT_DOCS": "Ожидаемых / загруженных документов",
    "PAUSED": "Пауза",
    "COMPLETE": "Завершен",
    "FAILED": "Неуспешный",
    "RETRY": "Повторить",
    "PENDING": "В ожидании",
    "common_location_country_id": "Локация",
    "COMMONLOCATIONCOUNTRYID": "Локация",
    "WITHOUT_VALUE": "Без значения",
    "HIDE_LEFT_PANEL": "Скрыть левую панель",
    "HIDE_RIGHT_PANEL": "Скрыть правую панель",
    "SHOW_LEFT_PANEL": "Открыть левую панель",
    "SHOW_RIGHT_PANEL": "Открыть правую панель",
    "SEARCH_TAG": "Поиск тега",
    "NO_DATA_TODAY": "За сегодня упоминания отсутствуют",
    "NO_DATA": "Упоминаний нет",
    "DOMAIN": "Источник",
    "OPEN_IN_MODAL": "Открыть в модальном окне",
    "FULL_LIST": "Полный список",
    "SELECTED_SPACE": "Выбрано ",
    "TOPICS_SELECTED": "тема",
    "TOPICS_SELECTED_1": "темы",
    "TOPICS_SELECTED_2": "тем",
    "SUBTOPICS_SELECTED": "подтема",
    "SUBTOPICS_SELECTED_1": "подтемы",
    "SUBTOPICS_SELECTED_2": "подтем",
    "CODE": "Код",
    "BOT": "Бот",
    "ADD_TELEGRAM_BOT": "Добавить Telegram бота",
    "SUBSCRIBE_TO_TELEGRAM": "Подписаться на telegram",
    "DEAR_USER": "Уважаемый пользователь!",
    "FIND_TELEGERAM_BOT": "Найдите бота в telegram и отправьте ему код",
    "PRESS_TO_COPY": "Нажмите чтобы скопировать",
    "SUBSCRIPTION_TO_NOTIFICATIONS": "Подписки на уведомления",
    /*"DOCUMENT_TITLE": "",
    "DOCUMENT_DOMAIN": "",
    "DOCUMENT_AUTHOR": "",
    "DOCUMENT_URL": "",
    "DOCUMENT_TYPE": "",
    "DOCUMENT_SENTIMENT": "",
    "DOCUMENT_LOCATION": "",
    "DOCUMENT_DATE": "",
    "DOCUMENT_COVERAGE": "",
    "DOCUMENT_TAGS": "",
    "DOCUMENT_TEXT": "",
    "DOCUMENT_NUMERATION": "",

    "TOPIC_NAME": "",
    "PERIOD": "",
    "REQUEST_DATE": "",
    "COLONTITLES": "",
    "TABLE_OF_CONTENTS": "",
    "MAIN_INFO": "",
    "SENTIMENT_DYNAMICS": "",
    "LANGUAGE_DYNAMICS": "",
    "LANGUAGE_TOTAL": "",
    "MEDIA_TYPE_DYNAMICS": "",
    "MEDIA_TYPE_TOTAL": "",
    "MEDIA_TYPE_BY_SENTIMENT": "",
    "KEYWORDS": "",
    "POPULAR_WORDS": "",
    "TAGS": "",
    "POPULAR_RESOURCES": "",
    "MEDIA_GEOGRAPHY": "",
    "MEDIA_SCALE": "",
    "SOCIAL_DOMAIN_DYNAMICS": "",
    "SOCIAL_DOMAIN": "",
    "AUTHORS": "",
    "GROUPS": "",
    "AUTHORS_GEOGRAPHY": "",
    "PROFILE_AGE": "",
    "PROFILE_GENDER": "",
    "INVOLVEMENT": "",
    "PUBLICATION_TYPE_BY_SENTIMENT": "",
    "PUBLICATION_TYPE_TOTAL": "",
    "MENTIONS_FEED": "",
    "HIGHLIGHTED_FULL_TEXT": "",
    "PLAIN_FULL_TEXT": "",
    "HIGHLIGHTED_FRAGMENTS": "",
    "BY_SENTIMENT": "",
    "BY_MEDIA_TYPE": "",
    "BY_DOMAIN": "",
    "NO_GROUPING": "",
    "BY_TAGS": "",
    "BY_SENTIMENT_AND_MEDIA_TYPE": "",
    "BY_MEDIA_TYPE_AND_SENTIMENT": "",
    "BY_SUBTOPICS": "",*/

    "en": "Английский",
    "ru": "Русский",
    "kz": "Казахский",
    "kk": "Казахский",
    "be": "Белорусский",
    "uk": "Украинский",
    "fr": "Французский",
    "kt": "Казахский (транслит)",
    "da": "Датский",
    "ca": "Каталанский",
    "nl": "Нидерландский (Голландский)",
    "eo": "Эсперанто",
    "de": "Немецкий",
    "no": "Норвежский",
    "gl": "Гренландский",
    "it": "Итальянский",
    "sk": "Словацкий",
    "sv": "Шведский",
    "fi": "Финский",
    "es": "Испанский",
    "is": "Исландский",
    "pt": "Португальский",
    "rt": "Русский (транслит)",
    "sl": "Словенский",
    "ro": "Румынский",
    "pl": "Польский",
    "el": "Греческий (Новогреческий)",
    "et": "Эстонский",
    "hu": "Венгерский",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
    "": "",
};

export default Titles;