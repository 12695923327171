import { ReportService } from "../connection/report-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
const SET_REPORT_SENDED = "SET-REPORT-SENDED";

const reportData = {
    reportSended: null,
    reportFormatTypes: [
        {
            title: 'PDF',
            short_name: 'pdf',
            name: "PDF"
        },
        {
            title: 'WORD',
            short_name: 'docx',
            name: "Word"
        },
        {
            title: 'PDF_TITLES',
            short_name: 'titles_pdf',
            name: "PDF_TITLES"
        },
        {
            title: 'WORD_TITLES',
            short_name: 'titles_docx',
            name: "WORD_TITLES"
        },
        {
            title: 'EXCEL_TITLES',
            short_name: 'titles_xlsx',
            name: "EXCEL_TITLES"
        },
        {
            title: 'PDF_ANALYTICS',
            short_name: 'pdf_analytics',
            name: "PDF_ANALYTICS"
        },
        {
            title: 'WORD_ANALYTICS',
            short_name: 'docx_analytics',
            name: "WORD_ANALYTICS"
        },
        {
            title: 'EXCEL',
            short_name: 'graphs_xlsx',
            name: "EXCEL"
        },
        {
            title: 'EXCEL_ANALYTICS',
            short_name: 'graphs_xlsx_analytics',
            name: "EXCEL_ANALYTICS"
        },
        {
            title: 'JSON_FEED',
            short_name: 'json_feed',
            name: "JSON_FEED"
        },
        {
            title: 'JSON_ANALYTICS',
            short_name: 'json_analytics',
            name: "JSON_ANALYTICS"
        },
        {
            title: 'JSON_FEED_ANALYTICS',
            short_name: 'json_feed_analytics',
            name: "JSON_FEED_ANALYTICS"
        },
        {
            title: 'STANDART_REPORT',
            short_name: 'STANDART_REPORT',
            name: 'STANDART_REPORT',
        }
    ],
    reportTypes: [
        {
            title: 'with_titles',
            short_name: 'С заголовками',
            name: "С заголовками"
        },
        {
            title: 'basic',
            short_name: 'Основной отчет',
            name: "Основной отчет"
        }
    ]
}

const report = (state = reportData, action) => {
    switch(action.type){
        case SET_REPORT_SENDED:
            return {...state, reportSended: action.reportSended}
        default:
            return {...state}
    }

}

export default report;

export const setReportSended = (reportSended) => {
    return {
        type: SET_REPORT_SENDED,
        reportSended: reportSended
    }
}

export const sendReport = (type, topicId, email, since, till, grouping, filters, feedType, selectedPostItems) => {
    return (dispatch) => {
        ReportService.getReport(type, topicId, email, since, till, grouping, filters, feedType, selectedPostItems).then(data=>{
            dispatch(setReportSended(data.id))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}
//getMultipleTopicReport
export const sendMultipleReport = (type, topicId, email, since, till, grouping, filters, feedType, selectedPostItems) => {
    return (dispatch) => {
        ReportService.getMultipleTopicReport(type, topicId, email, since, till, grouping, filters, feedType, selectedPostItems).then(data=>{
            dispatch(setReportSended(data.id))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const sendTemplateReport = (templateId, topicId, subtopicId, email, since, till, grouping, filters, feedType, selectedPostItems) => {
    return (dispatch) => {
        ReportService.getTemplateReport(templateId, topicId, subtopicId, email, since, till, grouping, filters, feedType, selectedPostItems).then(data=>{
            dispatch(setReportSended(data.id))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const sendSubtopicReport = (type, topicId, subtopicId, email, since, till, grouping, filters) => {
    return (dispatch) => {
        ReportService.getSubtopicReport(type, topicId, subtopicId, email, since, till, grouping, filters).then(data=>{
            dispatch(setReportSended(data.id))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}



