const Titles = {
    "_COMMENT_0_":" рұқсат",
    "LOGIN" : "ЛОГИН",
    "PASSWORD" : "ҚҰПИЯ СӨЗ",
    "WELLCOME_TO_THE" : "Қош келдіңіз",
    "FCBK_ECHO": "FCBK Echo",
    "MEDIA_MONITORING" : "Media Monitoring",

    "WELLCOME" : "Қош келдіңіз",
    "TO_THE_MEDIA_MONITORING" : "Media мониторингіне",
    "DON'T_REMEMBER_PASSWORD" : "ҚҰПИЯ СӨЗ ЕСІМДЕ ЖОҚ",
    "PREVIOUS VERISON" : "Жүйенің алдыңғы нұсқасы",
    "SIGN_IN" : "Жүйеге кіру",
    "DON'T_HAVE_ACCOUNT" : "Сізде аккаунт жоқ па?",
    "REGISTRATION" : "Тіркелу",
    "WRONG_EMAIL" : "ЭЛЕКТРОНДЫҚ ПОШТА АДРЕСІ ҚАТЕ",
    "EMAIL_AND_PASSWORD_COMBINATION_WASN'T_FOUND" : "ЛОГИН МЕН ҚҰПИЯ СӨЗДІҢ БАЙЛАНЫСЫ ТАБЫЛМАДЫ",
    "_COMMENT__1":"ПІКІР",
    "PASSWORD_RESTORE" : "Құпия сөзді қалпына келтіру",
    "CONTINUE" : "Жалғастыру",
    "SORRY_SUCH_MAIL_OR_LOGIN_IS_NOT_REGISTERED" : "ӨКІНІШКЕ ОРАЙ БҰНДАЙ ПОШТА НЕМЕСЕ ЛОГИН ТІРКЕЛМЕГЕН",
    "TRY_AGAIN" : "Тағы бір рет көру",
    "CANCEL" : "Бас тарту",
    "A_LETTER_WITH_INSTRUCTIONS_WAS_SENT_TO_YOUR_EMAIL_ADDRESS" : "НҰСҚАУЛЫҚТАРЫ БАР ХАТ СІЗДІҢ ЭЛЕКТРОНДЫҚ ПОШТА МЕКЕН-ЖАЙЫҢЫЗҒА ЖІБЕРІЛДІ",
    "CLOSE" : "Жабу",
    "_COMMENT_2":"пайдаланушының тіркелу терезесі",
    "USER_REGISTRATION" : "Тіркелу терезесі",
    "UNIQUE_LOGIN" : "БІРЕГЕЙ ЛОГИН",
    "SORRY_SUCH_LOGIN_IS_BUSY" : "КЕШІРІНІЗ, БҰЛ ЛОГИН БОС ЕМЕС",
    "8-12 CHARACTER, LATIN, CAPITAL LETTER, NUMBERS" : "8-12 ТАҢБА, ЛАТЫНША, БАС ӘРІП, САНДАР",
    "PASSWORD_REPEAT" : "ҚҰПЫЯ СӨЗДІ ҚАЙТА ЕНГІЗУ",
    "SORRY_WRONG_PASSWORD_REPEAT" : "КЕШІРІҢІЗ, СІЗ ҚҰПИЯ СӨЗДІ ҚАТЕ ЕҢГІЗДІҢІЗ. ҚАЙТАЛАУЫҢЫЗДЫ СҰРАЙМЫН",
    "OPTIONAL_FIELDS" : "ҚОСЫМША ӨРІСТЕР",
    "FULL_NAME" : " ТОЛЫҚ АТЫ-ЖӨНІ ",
    "YOUR_FULL_NAME" : "ТОЛЫҚ АТЫ-ЖӨНІҢІЗ",
    "EMAIL_EXAMPLE" : "EXAMPLE@EMAIL.COM",
    "PHONE" : "ТЕЛЕФОН",
    "YOUR_PHONE_NUMBER" : "ТЕЛЕФОН НӨМЕРІҢІЗ",
    "NOT_VALID_EMAIL" : "ЖАРАМСЫЗ ЭЛЕКТРОНДЫ АДРЕС",
    "NOT_VALID_PHONE" : "РҰҚСАТ БЕРІЛМЕЙТІН ТЕЛЕФОН НӨМЕРІ",
    "SEND" : "ЖІБЕРУ",
    "ALLREADY_HAS_ACCOUNT" : "Аккаунтыңыз бар ма?",
    "_COMMENT_3":"Кері байланыс үлгісі",
    "FEEDBACK_FORM" : "Кері байланыс үлгісі",
    "CONTACT_DETAILS" : "БАЙЛАНЫС МӘЛІМЕТТЕРІ",
    "PHONE_NUMBER_OR_E-MAIL_ADDRESS_SEPARATED_WITH_A_COMMA" : "ТЕЛЕФОН НӨМЕРІ НЕМЕСЕ ЭЛЕКТРОНДЫҚ ПОШТАНЫҢ АДРЕСІ, ҮТІР АРҚЫЛЫ ЖАЗУҒА БОЛАДЫ",
    "EMAIL_OR_LOGIN" : "ЭЛЕКТРОНДЫҚ ПОШТАНЫҢ АДРЕСІ НЕМЕСЕ ЛОГИН",
    "EMAIL" : "ЭЛЕКТРОНДЫҚ ПОШТАНЫҢ АДРЕСІ",
    "MESSAGE_TEXT" : "ХАБАРЛАМА МӘТІНІ",
    "WRITE_HERE_WHAT_YOU_ARE_INTERESTED_IN" : "Сізді алаңдататын барлық мәселелерді осында жазыңыз",
    "WE_ARE_ALWAYS_AWAILABLE_AT" : "Біз әрқашан мына мекен жайдмыз:",
    "OUR_EMAIL" : "echo@1cb.kz",
    "OR_BY_PHONE" : "немесе мына нөмер арқылы:",
    "OUR_PHONE" : "+7 (727) 325 35 00",
    "_COMMENT_4":"негізгі мәзір",
    "FAVORITE_POSTS" : "Таңдамалы пост",
    "FAVORITE_AUTHORS_AND_SOURCES" : "Таңдамалы авторлар мен мәлімет көздері",
    "BLACK_LIST" : "Қара тізім",
    "TRASHBIN" : "Себет",
    "LIGHT" : "ашық",
    "MIX" : "аралас",
    "DARK" : "күңгірт",
    "RUSSIAN" : "Орыс",
    "KAZAKH" : "Қазақ",
    "ENGLISH" : "English",
    "BELORUSSIAN": "Белорусский",
    "UKRAINIAN": "Украинский",
    "GERMAN": "Немецкий",
    "DUTCH": "Нидерландский",
    "FRENCH": "Французский",
    "ITALIAN": "Итальянский",
    "SPANISH": "Испанский",
    "DANISH": "Датский",
    "CATALAN": "Каталонский",
    "SLOVENIAN": "Словенский",
    "PORTUGUESE": "Португальский",
    "ROMANIAN": "Румынский",
    "SLOVAK": "Словацкий",
    "NORWEGIAN": "Норвежский",
    "FINNISH": "Финский",
    "POLISH": "Польский",
    "GREEK": "Греческий",
    "ESTONIAN": "Эстонский",
    "SWEDISH": "Шведский",
    "HUNGURIAN": "Венгерский",
    "LITHUANIAN": "Литовский",
    "THAI": "Тайский",
    "SHORT_ANALYTICS" : "Қысқаша аналитика",
    "SELECTION" : "Іріктеу",
    "EXTENDED_ANALYTICS" : "Кеңейтілген аналитика",
    "TOPICS_COMPARISON" : "Тақырыптарды салыстыру",
    "REPORTS_BUILDER" : "Есептерді құрастырушы",
    "AUTO_RULES" : "Авто ережелер",
    "STATISTIC_DESIGN" : "Статистикалық дизайн",
    "NOTIFICATIONS" : "Хабарландырулар",
    "FAQ" : "FAQ",
    "CONTACT_US" : "Бізбен байланыс",
    "SETTINGS": "Баптаулар",
    "ACCOUNTS_CABINET": "Аккаунтың кабенеті",
    "_COMMENT_5":"қысқаша талдаудың жоғарғы мәзірі",
    "TOPICS" : "Тақырыптар",
    "MASS_MEDIA" : "БАҚ",
    "SOC_MEDIA" : "Әлеуметтік медиа",
    "TOTAL_1" : "Жалпы",
    "DIAGRAMS" : "Диаграммалар",
    "COMPARISON" : "Салыстыру",
    "_COMMENT_5_1":"тақырып бойынша қысқаша талдаудың сүзгілеу беттері",
    "ALPHABETICALLY_A-Z_↓" : "ӘЛІПБИ БОЙЫНША А-Я ↓",
    "ALPHABETICALLY_Z-A_↑" : "ӘЛІПБИ БОЙЫНША А-Я ↑",
    "LAST_VIEWED_DATE_↓" : "СОНҒЫ ҚАРАЛЫМ КҮНІ БОЙЫНША ↓",
    "LAST_VIEWED_DATE_↑" : "СОНҒЫ ҚАРАЛЫМ КҮНІ БОЙЫНША ↑",
    "MONITORING_START_DATE_↓" : "МОНИТОРИНГТІҢ БАСТАУ КҮНІ БОЙЫНША ↓",
    "MONITORING_START_DATE_↑" : "МОНИТОРИНГТІҢ БАСТАУ КҮНІ БОЙЫНША ↑",
    "BY_LAST_PUBLICATION_DATE_↓" : "СОНҒЫ БАСЫЛЫМДАР КҮНІ БОЙЫНША ↓",
    "BY_LAST_PUBLICATION_DATE_↓" : "СОНҒЫ БАСЫЛЫМДАР КҮНІ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_↓" : "ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_↑" : "ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_PUBLICATIONS_FOUND_↓" : "ТАБЫЛҒАН БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_PUBLICATIONS_FOUND_↑" : "ТАБЫЛҒАН БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "_COMMENT_5_2":"Масс-медиа, әлеуметтік медиа және жалпы құны мәні бойынша қысқаша талдаудың сүзгі беті",
    "NUMBER_OF_NEW_PUBLICATIONS_FOR_THIS_DAY_↓" : "АҒЫМДАҒЫ ТӘУЛІКТЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_FOR_THIS_DAY_↑" : "АҒЫМДАҒЫ ТӘУЛІКТЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_FOR_THIS_WEEK_↓" : "АПТА БАСЫНДАҒЫ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_FOR_THIS_WEEK_↑" : "АПТА БАСЫНДАҒЫ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_PUBLICATIONS_FOR_THE_ENTIRE_MONITORING_PERIOD_↓" : "БАРЛЫҚ КЕЗЕНДЕГІ МОНИТОРИНГДЕГІ БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_PUBLICATIONS_FOR_THE_ENTIRE_MONITORING_PERIOD_↑" : "БАРЛЫҚ КЕЗЕНДЕГІ МОНИТОРИНГДЕГІ БАСЫЛЫМДАР САНЫ БОЙыНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_POSITIVE_TONE_↓" : "ОҢ ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_POSITIVE_TONE_↑" : "ОҢ ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_NEUTRAL_TONE_↓" : "БЕЙТАРАП ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_NEUTRAL_TONE_↑" : "БЕЙТАРАП ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_NEGATIVE_TONE_↓" : "ТЕРІС ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_A_NEGATIVE_TONE_↑" : "ТЕРІС ПІКІРДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_AGGRESIVE_CONTENT_↓" : "АГРЕССИВТІ МАЗМҰНДАҒЫ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_WITH_AGGRESIVE_CONTENT_↑" : "АГРЕССИВТІ МАЗМҰНДАҒЫ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "NUMBER_OF_NEW_PUBLICATIONS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↓" : "ҮЛКЕН АУДИТОРИЯ КӨЗІНДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↓",
    "NUMBER_OF_NEW_PUBLICATIONS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↑" : "ҮЛКЕН АУДИТОРИЯ КӨЗІНДЕГІ ЖАҢА БАСЫЛЫМДАР САНЫ БОЙЫНША ↑",
    "_COMMENT_6":"Қысқа аналитика бетіндегі басқа элементтер",
    "NEW_MESSAGES" : "ЖАҢА ХАБАРЛАР",
    "WEEK" : "АПТА",
    "TOTAL_2" : "Барлығы",
    "TODAY" : "БҮГІН",
    "TODAY_STATISTIC" : "БҮГІНГІ СТАТИСТИКА (00:00 БАСТАП)",
    "_COMMENT_7":"таңдалым беті",
    "THEME_NOT_SELECTED" : "Тақырып таңдалмаған",
    "ALL_ON_PAGE" : "Осы беттегі барлығы",
    "ALL_LIST" : "Барлық тізім",
    "SHOW" : "КӨРСЕТУ",
    "ADD_PUBLICATION" : "Басылымды қосу",
    "DOWNLOAD" : "Жүктеу",
    "SELECTED" : "ТАҢДАЛЫНҒАН",
    "COMMON_TAGS" : "ОРТАҚ ТЕГ",
    "SHARE" : "Бөлісу",
    "TO_FAVORITE" : "Таңдалымға",
    "TO_BLACK_LIST" : "Қара тізімге",
    "FROM_BLACK_LIST" : "Қара тізімнен",
    "TO_TRASHBIN" : "Себетке",
    "FROM_TRASHBIN" : "Себеттен",
    "ANSWER" : "Жауап беру",
    "ADD_TAG" : "Тег қосу",
    "ARTICLE" : "Мақала",
    "TRANSCRIBATION" : "ТРАНСКРИБАЦИЯ",
    "POST_3" : "ПОСТ",
    "POST" : "ПОСТ",
    "REPOST" : "РЕПОСТ",
    "EXTENDED_REPOST": "Қосымша бөлісу",
    "ADDED_REPOST" : "ҚОСЫМША РЕПОСТ",
    "COMMENT" : "Пікір",
    "review" : "Шолулар",
    "REVIEWS" : "Шолулар",
    "REVIEW_2": "Шолу",
    "TODAY_2" : "Бүгін",
    "WEEK_2" : "Апта",
    "MONTH" : "Ай",
    "YEAR": "Жыл",
    "TEXT_FILTER" : "ТЕКСТ БОЙЫНША ІЗДЕУ",
    "TEXT_FILTER_INSTRUCTION": "ТЕКСТ БОЙЫНША ІЗДЕУ ИНСТРУКЦИЯСЫ",
    "_COMMENT_8":"фильтрация панелі",
    "SELECTION_DATES_FILTER" : "Кезеңді таңдау",
    "BEGIN" : "Басы",
    "END" : "Соңы",
    "MON" : "ДС",
    "TUE" : "СС",
    "WED" : "СР",
    "THU" : "БС",
    "FRI" : "ЖМ",
    "SAT" : "СБ",
    "SUN" : "ЖБ",
    "JAN" : "Қаңтар",
    "FEB" : "Ақпан",
    "MAR" : "Наурыз",
    "APR" : "Сәуір",
    "MAY" : "Мамыр",
    "JUN" : "Маусым",
    "JUL" : "Шілде",
    "AUG" : "Тамыз",
    "SEP" : "Қыркүйек",
    "OKT" : "Қазан",
    "NOV" : "Қараша",
    "DEC" : "Желтоқсан",
    "SOURCE_TYPE" : "Дереккөз түрлері",
    "SOCIAL" : "Әлеуметтік",
    "SOCIAL_NETWORKS" : "Әлеуметтік желілер",
    "FACEBOOK" : "Facebook",
    "INSTAGRAM" : "Instagram",
    "VKONTAKTE" : "Vkontakte",
    "YOUTUBE" : "YouTube",
    "TWITTER" : "Twitter",
    "TELEGRAM" : "Telegram",
    "ODNOKLASSNIKI" : "Odnoklassniki",
    "RUTUBE" : "RuTube",
    "YANDEXZEN" : "ЯндексДзен",
    "TIKTOK" : "TikTok",
    "MOIMIR" : "Мой Мир",
    "RADIO" : "Радио (транскрибациялар)",
    "TELEVISION" : "Теледидар (транскрибациялар)",
    "TV" : "Теледидар (транскрибациялар)",
    "PRINT_MASS_MEDIA_PAPER" : "Басылым БАҚ (қағаз нұсқасы)",
    "PRINT" : "Басылым БАҚ (қағаз нұсқасы)",
    "PRINT_MASS_MEDIA_WEB" : "Басылым БАҚ (ғаламтор нұсқасы)",
    "INTERNET_MASS_MEDIA" : "Ғаламтор сайттар",
    "INTERNET" : "Ғаламтор",
    "NEWS_AGENCIES" : "Ақпараттық агенттіктер",
    "NEWS_AGENCY" : "Ақпараттық агенттіктер",
    "POST_TYPE" : "Жарияланым түрі",
    "ARTICLE_2" : "Мақала",
    "TRANSCRIBATION_2" : "Транскрибация",
    "POST_2" : "Пост",
    "REPOST_2" : "Бөлісу",
    "ADDED_REPOST_2" : "Қосымша бөлісу",
    "COMMENT_2" : "Пікір",
    "SOURCES" : "Дереккөздері",
    "TAGS" : "Тегтер",
    "KEY_WORDS" : "Кілттік сөздер",
    "TONE_AGGRESSION_ADVERTISEMENT" : "Үндестілік, агрессия, жарнама",
    "NEUTRAL" : "Бейтарап",
    "POSITIVE" : "Жағымды",
    "NEGATIVE" : "Жағымсыз",
    "WITH_OBSCENE_VOCABULARY" : "Әдепсіз сөздермен",
    "WITH_AGGRESSION" : "Агрессиямен",
    "WITH_ADVERTISEMENT" : "Жарнамамен",
    "WITH_CHECKED_TONE" : "Тексерілген тонымен",
    "WITH_UNDEFINED_TONE" : "Анықталмаған тонымен",
    "WITH_TONE_MODIFIED_BY_AUTORULE" : " Өзгерілген автоереже тонымен",
    "MODIFIED_BY_AUTORULE" : "Автоережемен өзгертілген",
    "AUDIENCE_MAS_MEDIA_COVERAGE_INVOLVEMENT" : "Дереккөз аймағы",
    "INTERNATIONAL_MAS_MEDIA" : "Халықаралық",
    "REPUBLICAN_MAS_MEDIA" : "Республикалық",
    "REGIONAL_MAS_MEDIA" : "Аймақтық",
    "PRINT_MAS_MEDIA" : "Жарияланым БАҚ",
    "VERIFICATION_STATUS": "Тексерудің статусы",
    'VERIFICATIONSTATUS': 'Тексерудің статусы',
    "VERIFIED": "Тексерілген",
    "DELETED": "Жойылған",
    "DUPLICATE": "Дубликаттар",
    "NONRELEVANT": "Релевантсыз",
    "OLD": "Ескі",
    "RESTORED": "Қалпына келтірілген",
    "UNVERIFIED": "Тексерілмеген",
    "ANY": "Тексерілген",
    "MORE_THAN_MILLION_SUBSCRIBERS" : "1 000 000 асқан жазылушылар",
    "LESS_THAN_MILLION_MORE_THAN_ONE_HUNDRED_THOUSANDS_SUBSCRIBERS" : "1 000 000-нан 100 000 дейінгі жазылушылар",
    "LESS_THAN_ONE_HUNDRED_THOUSANDS_MORE_THAN_TEN_THOUSANDS_SUBSCRIBERS" : "100 000-нан 10 000 жазушылар",
    "LESS_THAN_TEN_THOUSANDS_MORE_THAN_THOUSAND_SUBSCRIBERS" : "10 000-нан 1 000 жазушылар",
    "LESS_THAN_THOUSAND_SUBSRIBERS" : "1 000 кем жазушылар",
    "AUDIENCE_NOT_DETERMINDED" : "Аудитория анықталмаған",
    "POST_LANGUAGE" : "Жарияланым тілі",
    "KAZAKH_2" : "Қазақ",
    "RUSSIAN_2" : "Орыс",
    "ENGLISH_2" : "Ағылшын",
    "NOT_DETERMINDED" : "Анықталмаған",
    "GEOGRAPHY" : "Жағрафия",
    "NUR_SULTAN" : "Астана",
    "ALMATY" : "Алматы",
    "SHYMKENT" : "Шымкент",
    "AKMOLA_REGION" : "Ақмола облысы",
    "AKTOBE_REGION" : "Ақтөбе облысы",
    "ALMATY_REGION" : "Алматы облысы",
    "ATYRAU_REGION" : "Атырау облысы",
    "EAST_KAZAKHSTAN_REGION" : "Шығыс-Қазақстан облысы",
    "ZHAMBYL_REGION" : "Жамбыл облысы",
    "WEST_KAZAKHSTAN_REGION" : "Батыс-Қазақстан облысы",
    "KARAGANDY_REGION" : "Қарағанды облысы",
    "KOSTANAY_REGION" : "Қостанай облысы",
    "KYZYLORDA_REGION" : "Қызылорда облысы",
    "MANGISTAU_REGION" : "Манғыстау облысы",
    "PAVLODAR_REGION" : "Павлодар облысы",
    "NORT_KAZAKHSTAN_REGION" : "Солтүстік-Қазақстан облысы",
    "TURKESTAN_REGION" : "Түркістан облысы",
    "ABAY_REGION" : "Абай облысы",
    "GETISU_REGION" : "Жетісу облысы",
    "ULYTAU_REGION" : "Ұлытау облысы",
    "RUSSIA" : "Рессей",
    "KIRGIZSTAN" : "Қырғыстан",
    "CIS" : "БҰҰ",
    "OTHERS": "Басқалары",
    "OTHER" : "Басқасы",
    "NOT_DETERMINDED_2" : "Анықталмаған",
    "LOCATION" : "Орналасқан жері",
    "PERSON" : "Тұлға",
    "ORGANIZATION" : "Ұйым",
    "_COMMENT_9":"таңдамалы басылымдар",
    "TOPIC" : "ТАҚЫРЫП",
    "TOPICS" : "Тақырыптар",
    "BREAKDOWN_BY" : "БОЙЫНША БӨЛУ",
    "HOURS": "Сағаттар",
    "DAYS" : "Күндер",
    "WEEKS" : "Апталар",
    "MONTHS" : "Айлар",
    "CHOOSE_ALL_TOPICS" : "Барлық тақырыптарды таңдау",
    "CHOOSE_ALL_SUBTOPICS" : "Барлық тақырыпшаларды таңдау",
    "_COMMENT_10":"таңдаулы авторлар мен көздері",
    "TITLE" : "АТАЛУЫ",
    "TYPE" : "ТҮРІ",
    "SOURCE" : "РЕСУРС",
    "SUBSCRIBERS" : "ЖАЗЫЛУШЫЛАР",
    "LAST_POST_DATE" : "СОҢҒЫ ЖААРИЯЛАНЫМ КҮНІ",
    "PUBL_TOTAL" : "БАРЛ. ЖАРИЯЛАНЫМ",
    "PUBL_IN_SELECTION" : "ТАҢДАЛЫМДАҒЫ ЖАРИЯЛАНЫМДАР",
    "MARKS" : "БЕЛГІЛЕР",
    "TONE" : "Үндестілік",
    "TONES" : "Үндестіліктер",
    "ACTIONS" : "ӘРЕКЕТ",
    "AUTHORS_AND_SOURCES" : "Авторлар мен көздері",
    "POSTS_2" : "Жарияланым",
    "_COMMENT_10_1":"Авторлар мен көздерінің таблицалар фильтрі",
    "ALPHABETICALLY_A-Z_LATIN_↓" : "ӘЛІПБИ БОЙЫНША A-Z ↓",
    "ALPHABETICALLY_Z-A_LATIN_↑" : "ӘЛІПБИ БОЙЫНША A-Z ↑",
    "ASC" : "↓",
    "DESC" : "↑",
    "DATE_↓" : "КҮНДЕР БОЙЫНША ↓",
    "DATE_↑" : "КҮНДЕР БОЙЫНША ↑",

    "LIKES_↓": "ЛҮПІЛДЕР БОЙЫНША ↓",
    "LIKES_↑": "ЛҮПІЛДЕР БОЙЫНША ↑",

    "COMMENTS_↓": "ПІКІРЛЕР БОЙЫНША ↓",
    "COMMENTS_↑": "ПІКІРЛЕР БОЙЫНША ↑",

    "SHARES_↓": "РЕПОСТТАР БОЙЫНША ↓",
    "SHARES_↑": "РЕПОСТТАР БОЙЫНША ↑",

    "COVERAGE_↓": "САЙТ ҚАМТУЫ ↓",
    "COVERAGE_↑": "САЙТ ҚАМТУЫ  ↑",

    "AGGRESIVE_FIRST" : "БІРІНШІ АГРЕССИЯСЫ БАР",
    "OBSCENE_FIRST" : "БІРІНШІ ӘДЕПСІЗ СӨЗДЕРІ БАР",
    "ADVERTISEMENT_FIRST" : "БІРІНШІ ЖАРНАМАСЫ БАР",
    "POSITIVE_TONE_FIRST_↓" : "БІРІНШІ ОҢ ПІКІРІ ↓",
    "POSITIVE_TONE_FIRST_↑" : "БІРІНШІ ОҢ ПІКІРІ ↑",
    "NEUTRAL_TONE_FIRST_↓" : "БІРІНШІ БЕЙТАРАП ПІКІРІ ↓",
    "NEUTRAL_TONE_FIRST_↑" : "БІРІНШІ БЕЙТАРАП ПІКІРІ ↑",
    "NEGATIVE_TONE_FIRST_↓" : "БІРІНШІ ТЕРІС ПІКІРІ ↓",
    "NEGATIVE_TONE_FIRST_↑" : "БІРІНШІ ТЕРІС ПІКІРІ ↑",
    "_COMMENT_11":"ұзартылған аналитика",
    "DOWNLOAD_REPORT" : "Есепті шығару",
    "FORMAT_REPORT_AND_WILL_BE_SENDED_TO_EMAIL": "Есепті таңдаңыз, ол сіздің электрондық поштаңызға жіберіледі",
    "REPORT_IN_PROGRESS_AND_WILL_AVAILABLE_IN_NOTIFICATIONS": "Есеп құрылуда, Хабарламалар тізімінде қолжетімді болады",
    "REPORT_IN_PROGRESS_AND_WILL_BE_SENDED_TO_EMAIL": "Есеп құрылуда, ол сіздің электрондық поштаңызға жіберіледі",
    "DASHBOARDS_LAYOUT" : "ақпарат панелінің орны",
    "MENTIONING_BY_DATE" : "Күндер бойынша ескертпелер",
    "MENTIONING_BY_LANG" : "Тілдер бойынша ескертпелер",
    "NEWSBREAK" : "Ақпараттық жағдай",
    "TOTAL" : "БАРЛЫҒЫ",
    "CREATE" : "Құру",
    "MERGE_NEWSBREAKS" : "Ақпараттық жағдайларды біріктіру",
    "NEUTRAL_3" : "Бейтарап",
    "NOT_DETERMINDED_3" : "Анықталмаған",
    "POSITIVE_3" : "Жағымды",
    "NEGATIVE_3" : "Жағымсыз",
    "TONE_BY_DATE" : "Күн бойынша үндестілік",
    "TONE_BY_LANG" : "Тіл бойынша үндестілік",
    "RUSSIAN_3" : "ОРЫС",
    "KAZAKH_3" : "ҚАЗАҚ",
    "ENGLISH_3" : "АҒЫЛШЫН",
    "POST_TYPES_BY_DATES" : "Күн бойынша басылым түрі",
    "POST_TYPES_BY_LANG" : "Тіл бойынша басылым түрі",
    "SPECIAL_MARKS_BY_DATES" : "Күн бойынша ерекше белгілер",
    "SPECIAL_MARKS_BY_LANG" : "Тіл бойынша ерекше белгілер",
    "AGGRESION" : "АГРЕССИЯ",
    "OBSCENE_SPEECH" : "ӘДЕПСІЗ СӨЗДЕР",
    "ADVERTISEMENT" : "ЖАРНАМА",
    "AUTHORS" : "Авторлар",
    "AUTHORS_NAME": "Автордың аты",
    "INFLUENCE_OF_AUTHORS" : "Авторлар ықпалы",
    "NUMBER_OF_SUBSCRIBERS" : "ЖАЗЫЛУШЫЛАР САНЫ",
    "COMMUNITIES" : "Қауымдастық",
    "INFLUENCE_OF_COMMUNITIES" : "Қауымдыстықтардың ықпалы",
    "MENTIONS" : "Ескертпелер",
    "TONE" : "Үндестілік",
    "SENTIMENT" : "Үндестілік",
    "TYPES_OF_POSTS" : "Жарияланым түрлері",
    "SPECIAL_MARKS" : "Ерекше белгілер",
    "TOP_20_MASS_MEDIA" : "Топ-20 БАҚ",
    "MASS_MEDIA_TYPE" : "БАҚ түрі",
    "REGIONS" : "Аймақтар",
    "COVERAGE": "Қатысушылық",
    "SITE_COVERAGE": "Сайт Қатысушылығы",
    "REACH_OF_SOURCE" : "Қамту",
    "REACH_OF_SOURCE_BY_SENTIMENT" : "Тон бойынша қамту",
    "REACH_OF_SOURCE_BY_COUNT" : "Сан бойынша қамту",
    "POPULAR_WORDS" : "Кеңінен таралған сөздер",
    "ENTITIES" : "Мәні",
    "GEOGRAPHY_OF_AUTHORS" : "Авторлар географиясы",
    "AUTHOR_STATISTICS" : "Авторлар статистикасы",
    "AUTHOR_STATISTICS_GENDER" : "Жынысы бойынша авторлар статистикасы",
    "AUTHOR_STATISTICS_AGE" : "Жасы бойынша авторлар статистикасы",
    "TOP_20_MASS_MEDIA_BY_LANG" : "Топ-20 БАҚ тілдер бойынша",
    "TONE_BY_MASS_MEDIA_TYPES" : "Дереккөз түрі бойынша үндестілік",
    "NEWS_AGENCIES_2" : "АҚПАРАТТЫҚ АГЕНТТІКТЕР",
    "WEBSITES" : "ҒАЛАМТОР САЙТТАРЫ",
    "PRINT_MASS_MEDIA" : "БАСПА БАҚ",
    "TELEVISION_2" : "Телеарналар",
    "TONE_BY_REGIONS" : "Аймақ бойынша тоны",
    "SOURCE_TYPES_BY_SCALE" : "Масштаб бойынша көз түрі",
    "NUR_SULTAN_2" : "АСТАНА",
    "ALMATY_2" : "АЛМАТЫ",
    "SHYMKENT_2" : "ШЫМКЕНТ",
    "AKMOLA_REGION_2" : "АҚМОЛА",
    "AKTOBE_REGION_2" : "АҚТӨБЕ",
    "ALMATY_REGION_2" : "АЛМАТЫ",
    "ATYRAU_REGION_2" : "АТЫРАУ",
    "EAST_KAZAKHSTAN_REGION_2" : "ШҚО",
    "ZHAMBYL_REGION_2" : "ЖАМБЫЛ",
    "WEST_KAZAKHSTAN_REGION_2" : "БҚО",
    "KARAGANDY_REGION_2" : "ҚАРАҒАНДЫ",
    "KOSTANAY_REGION_2" : "ҚОСТАНАЙ",
    "KYZYLORDA_REGION_2" : "ҚЫЗЫЛОРДА",
    "MANGISTAU_REGION_2" : "МАНҒЫСТАУ",
    "PAVLODAR_REGION_2" : "ПАВЛОДАР",
    "NORT_KAZAKHSTAN_REGION_2" : "СҚО",
    "TURKESTAN_REGION_2" : "ТҮРКІСТАН",
    "ABAY_REGION_2" : "АБАЙ",
    "GETISU_REGION_2" : "ЖЕТІСУ",
    "ULYTAU_REGION_2" : "ҰЛЫТАУ",
    "REGIONAL" : "АЙМАҚТЫҚ",
    "CENTRAL" : "Орталық",
    "INTERNATIONAL" : "ХАЛЫҚАРАЛЫҚ",
    "TAGS_LIST" : "Тегтер тізімі",
    "TAGS_CLOUD" : "Тегтер бұлты",
    "TAGS_BY_TIME" : "Уақыт бойынша тегтер",
    "POSTS_BY_TAGS" : "Тег бойынша басылымдар",
    "TAG" : "ТЕГ",
    "EDIT_TAG": "Тегті өзгерту",
    "NUMBER_OF_ASSIGNMENTS" : "ЕЛЕНУ САНЫ",
    "SPECIAL_MARKS_2" : "ЕРЕКШЕ БЕЛГІЛЕРІ",
    "TONES_OF_POSTS" : "БАСЫЛЫМ ТҮСТЕРІ",
    "VIEW_POSTS" : "Басылымды қарау",
    "shares_count": "РЕПОСТТАР",
    "likes_count": "ЛҮПІЛДЕР",
    "comments_count" : "ПІКІРЛЕР",
    "GRADE": "Бағалау",
    "POSTS" : "ПОСТТАР",
    "REPOSTS" : "РЕПОСТТАР",
    "LIKES" : "ЛҮПІЛДЕР",
    "COMMENTS" : "ПІКІРЛЕР",
    "ADDED_REPOSTS" : "ҚОСЫМША РЕПОСТТАР",
    "LIST_OF_KEYWORDS" : "Кілт сөздер тізімі",
    "KEYWORDS_CLOUD" : "Кілт сөздер бұлты",
    "KEYWORDS_BY_TIME" : "Уақыт бойынша негізгі кілт сөздер",
    "POSTS_BY_KEYWORDS" : "Кілт сөздер бойынша басылымдар",
    "LIST_OF_POPULAR_WORDS" : "Кеңінен таралған сөздер тізімі",
    "POPULAR_WORDS_CLOUD" : "Кеңінен таралған сөздер бұлты",
    "POPULAR_WORDS_BY_TIME" : " Уақыт бойынша кеңінен таралған сөздер",
    "POSTS_BY_POPULAR_WORDS" : "Кеңінен таралған сөздер бойынша басылымдар",
    "POPULAR_WORDS_BY_SENTIMENT" : "Тон бойынша кеңінен таралған сөздер",
    "LIST_OF_ENTITIES" : "Мәндер тізімі",
    "ENTITIES_CLOUD" : "Мәндер бұлты",
    "ENTITIES_BY_TIME" : "Уақыт бойынша мәндері",
    "POSTS_BY_ENTITIES" : "Мәндер бойынша баслымдар",
    "Male": "Ерлер",
    "Female": "Әйелдер",
    "WEMAN" : "Әйелдер",
    "MEN" : "Ерлер",
    "_COMMENT_12":"тақырып салыстырулар",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_TYPE" : "БАҚ түрі бойынша тақырыптарды салыстыру",
    "MASS_MEDIA_TYPE_&_SOCIAL_NETWORKS" : "БАҚ түрі және әлеуметтік желі",
    "LANGUAGE" : "Тіл",
    "MASS_MEDIA_STATUS" : "БАҚ статусы",
    "TOPICS_COMPARISON_BY_MENTIONS": "Ескертпелер бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_MEDIA_TYPE" : "БАҚ түрі бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_TONE" : "Үндестілік бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_SPECIAL_MARKS" : "Ерекше белгі бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_LANG" : "Тілдер бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_STATUS" : "БАҚ статусы бойынша тақырыптарды салыстыру",
    "TOPICS_COMPARISON_BY_REGIONS" : "Аймақ бойынша тақырыптарды салыстыру",
    "_COMMENT_13":"есеп конструкторы беті",
    "LIST_OF_REPORTS" : "Есеп тізімі",
    "CREATE_REPORT" : "Есеп құру",
    "REPORT_BUILDER" : "Есеп констукторы",
    "PAGES_ORIENTATION" : "БЕТТЕР БАҒЫТЫ",
    "APPLY" : "Қолдану",
    "DOWNLOAD_FINISHED_REPORT" : "Дайын есепті жүктеу",
    "EMPTY_DOCUMENT" : "Есепте деректер жоқ",
    "SORTING" : "Сұрыптау",
    "DATE_OF_CREATION" : "ҚҰРУ КҮНІ",
    "DATE_OF_GENERATION" : "ГЕНЕРАЦИЯ КҮН",
    "REPORT_DELETING" : "Есепті өшіру",
    "CONFIRM_REPORT_DELETING" : "ЕСЕПТІ ӨШІРУДІ РАСТАУ",
    "AVAILABLE_ELEMENTS" : "Қолжетімді элементтер",
    "TEXT" : "Текст",
    "TABLE" : "Таблица",
    "CHART" : "График",
    "LIST" : "Тізім",
    "VALUE_WITH_DESCRIPTION" : "Сипаттамасымен белгілер",
    "EXPORTED_FILTERS" : "Экспортталған сүзгілер",
    "DATA_OF_EXTENDED_ANALYTICS_PAGE" : "Ұзартылған аналитика бетінің мәліметтері",
    "DATA_OF_TOPICS_COMPARISON_PAGE" : "Тақырыптарды салыстыру бетінің мәліметтері",
    "DATA_OF_FAVORITE_POSTS_PAGE" : "Таңдамалы басылым бетінің мәліметтері",
    "DATA_FO_FAVORITE_AUTHORS_AND_SOURCES_PAGE" : "Таңдамалы авторлар мен көздер бетінің мәліметтері",
    "PERIOD" : "Кезең",
    "BREAKDOWN_ON" : "БОЙЫНША БӨЛУ",
    "TOPIC_&_SUBTOPIC" : "Тақырып және тақырыпшалар",
    "SUBTOPIC" : "ТАҚЫРЫПША",
    "SUBTOPICS" : "ТАҚЫРЫПШАЛАР",
    "SET_TOPIC_FOR_THE_WHOLE_REPORT" : "Барлық есеп үшін тақырып беру",
    "SAVED_FILTER_SETTINGS" : "Сақталған сүзгілер баптаулары",
    "SET_FILTER_FOR_THE_WHOLE_REPORT" : "Барлық есептер үшін сүзгілер беру",
    "DELETE" : "Жою",
    "PAGE" : "БЕТ",
    "SECTION" : "БӨЛІМ",
    "NAME" : "ТАҚЫРЫБЫ",
    "DESIGN" : "ДИЗАЙН",
    "FIELDS" : "ЖОЛДАР",
    "_COMMENT_14":"Автоережелер беті",
    "LIST_OF_AUTORULES" : "Автоережелер тізімі",
    "CREATE_AUTORULE" : "Автоережені құру",
    "ONLY_ADDINGS_TO_THE_BLACK_LIST" : "ТЕК ҚАРА ТІЗІМГЕ ҚОСУ",
    "ONLY_ADDINGS_TO_THE_FAVORITES" : "ТЕК ТАҢДАМАЛЫ ТІЗІМГЕ ҚОСУ",
    "ONLY_CHANGES_OF_VALUES" : "ТЕК МӘНІН ӨЗГЕРТУ",
    "ONLY_NOTIFICATIONS_SENDING" : "ТЕК ХАБАРЛАНДЫРУ ЖІБЕРУ",
    "ONLY_DELETING_OF_POSTS" : "ТЕК БАСЫЛЫМДАРДЫ ӨШІРУ",
    "AUTORULE_TITLE" : "АВТОЕРЕЖЕЛЕР АТТАРЫ",
    "TYPE_OF_ACTION" : "ӘРЕКЕТ ТҮРЛЕРІ",
    "NUMBER_OF_ACTIVATIONS" : "ІСКЕ ҚОСУ САНЫ",
    "LAST_POST" : "Соңғы басылым",
    "OPEN_ON_PAGE" : "Осы бетте ашу",
    "AUTORULE_EDITING" : "Автоережені түзету",
    "AUTORULE" : "АВТОЕРЕЖЕ",
    "CHOOSE_THE_GROUP_OF_VALUES" : "ТОП МАҒЫНАСЫН ТАҢДАҢЫЗ",
    "CHOOSE_THE_VALUE" : "МАҒЫНАСЫН ТАҢДАҢЫЗ",
    "CHOOSE_THE_TYPE_OF_COMPARISON" : "САЛЫСТЫРУ ТҮРІН ТАҢДАҢЫЗ",
    "ENTER_THE_VALUE" : "МАҒЫНАСЫН ЕНГІЗІҢІЗ",
    "ADD_STRING" : "Жол енгізу",
    "CHOOSE_THE_TYPE_OF_ACTION" : "ӘРЕКЕТ ТҮРІН ТАҢДАҢЫЗ",
    "ADD_ACTION" : "ӘРЕКЕТ ҚОСУ",
    "SAVE" : "Сақтау",
    "NUMBER" : "Сан",
    "VARIANT" : "Нұсқа",
    "AUTHOR" : "Автор",
    "TITLE_2" : "Тақырып",
    "SOURCE_2" : "Дереккөзі",
    "KEY_PARAGRAPH_TEXT" : "Негізгі абзац мәтіні",
    "POST_TEXT" : "Жарияланым мәтіні",
    "SOURCE_TAGS" : "Бастапқы ғаламтор-ресурсының тегі",
    "DATE_OF_POST" : "Жарияланым күні",
    "INVOLVEMENT_INDEX" : "Қатысу индексі",
    "NUMBER_OF_DUBLICATES" : "Көшірме саны",
    "NUMBER_OF_COMMENTS" : "Пікірлер саны",
    "NUMBER_OF_LIKES" : "Лүпілдер саны",
    "NUMBER_OF_SHARES" : "Жіберулер саны",
    "NUMBER_OF_VIEWS" : "Қаралым саны",
    "SIZE_OF_AUDIENCE" : " Көрермен көлем",
    "GEOGRAPHY_OF_POST" : "Жарияланым географиясы",
    "PRESENCE_OF_AGGRESSION" : "Агрессияның болуы",
    "PRESENCE_OF_ADVERTISEMENT" : "Жарнаманың болуы",
    "PRESENCE_OF_OBSCENE_SPEECH" : "Әдепсіз сөздердің болуы",
    "TAG_2" : "Тег",
    "TYPE_OF_POST" : "Жарияланым түрі",
    "TONE_OF_POST" : "Жарияланым тоны",
    "TOPIC_2" : "Тақырып",
    "NOT_EQUAL" : "Тең емес",
    "EQUAL" : "Тең",
    "DOES_NOT_CONTAIN" : "Құрамында жоқ",
    "CONTAIN" : "Құрамында бар",
    ">" : ">",
    "<" : "<",
    "=" : "=",
    ">=" : ">=",
    "<=" : "<=",
    "!=" : "!=",
    "ADD_TO_THE_FAVORITES" : "Таңдамалыға қосу",
    "ADD_TO_THE_BLACK_LIST" : "Қара тізімге қосу",
    "CHANGE_VALUES" : "Мағынасын өзгерту",
    "SEND_NOTIFICATION" : "Хабарландыру жіберу",
    "POST" : "Басылым",
    "OBSCENE_SPEECH_2" : "Әдепсіз сөз",
    "ADVERTISEMENT_2" : "Жарнама",
    "TO_EMAIL" : "Электрондық пошта мекен-жайына",
    "TO_TELEGRAM" : "Telegram-ға",
    "YES" : "Бар",
    "NO" : "Жоқ",
    "NEUTRAL_4" : "Бейтарап",
    "NOT_DETERMINDED_4" : "Анықталмаған",
    "POSITIVE_4" : "Оң",
    "NEGATIVE_4" : "Теріс",
    "_COMMENT_15":"модальдік терезе",
    "STATUSES_OF_SERVER_REQUESTS" : "Серверге сұраныс статусы",
    "PERIOD_2" : "КЕЗЕҢ",
    "DATA" : "КҮН",
    "STATUS" : "СТАТУСЫ",
    "READY_SENT_TO_THE_EMAIL" : "Поштаға жіберілді-дайын",
    "READY" : "Дайын",
    "REQUEST_IS_PROCESSING_PLEASE_WAIT" : "Сұраныс өңделуде күтіңіз",
    "ERROR" : "Қате",
    "ERROR_RE_REQUEST_REQUIRED" : "Қате- қайта сұраныс қажет",
    "ERROR_CONTACT_SUPPORT" : "Қате- қолдау қызметіне хабарласыңыз",
    "GETTING_SELECTION_REPORT" : "Таңдамалы бойынша есеп алу",
    "UPLOAD" : "Жүктеу",
    "GETTING_CUSTOM_REPORT" : "Пайдаланушы есебін алу",
    "RESEND_POST" : "Басылымды жіберу",
    "SEND_TO_THE_EMAIL" : "ПОШТАҒА ЖІБЕРУ",
    "SEND_TO_THE_TELEGRAM" : "TELEGRAM-ҒА ЖІБЕРУ",
    "EMAIL_ADDRESS" : "ЭЛЕКТРОНДЫ ПОШТА МЕКЕН-ЖАЙЫ",
    "THOOSE_NAME_OF_THE_GROUP" : "ТОП АТЫН ТАҢДАУ",
    "PHONE_OF_THE_TELEGRAM_USER" : "TELEGRAM ПАЙДАЛАНУШЫСЫНЫҢ ТЕЛЕФОН НӨМІРІНЕ",
    "SAVE_LINK_TO_THE_CLIPBOARD" : "Алмасу буферіне сілтемені сақтау",
    "SAVE_FILTER_SETTINGS" : "Сүзгі баптауларын сақтау",
    "ENTER_SETTINGS_NAME" : "БАПТАУЛАР АТАУЫН ЕНГІЗІҢІЗ",
    "ADDING_POST" : "Басылымдарды қосу",
    "POST_LINK" : "БАСЫЛЫМДАРҒА СІЛТЕМЕ",
    "SCAN_LINK" : "Сілтемені сканерлеу",
    "YOUR_LINK_SCAN_RESULT_WILL_BE_DISPLAYED_HERE" : "БҰЛ ЖЕРДЕ СІЗДІҢ СІЛТЕМЕНІ СКАНЕРЛЕУ НӘТИЖНСІ КӨРСЕТІЛЕДІ",
    "CONTACT_SUPPORT" : "Қолдау қызметіне хабарласу",
    "ADD_EXISTING_TAGS" : "Сақталған тег қосу",
    "CREATE_NEW_TAG" : "Жаңа тег құру",
    "TEXT_OF_TAG" : "ТЕГ МӘТІНІ",
    "ENTER_TEXT_OF_TAG" : "ТЕГ МӘТІНІН ЕНГІЗІҢІЗ",
    "FILL_COLOR" : "ТЕГ ҚҰЮ ТҮСІ",
    "TAG_PREVIEW" : "ТЕГТІ АЛДЫН АЛА КӨРУ",
    "_COMMENT_16":"Білім базасы және FAQ",
    "CONTENT" : "Мазмұны",
    "CONTENT_SEATCH" : "Мазмұн бойынша іздеу",
    "TEXT_DEEP_SEARCH" : "МӘТІН БОЙЫНША ТЕРЕҢ ІЗДЕУ",
    "REPORT_MISTAKE" : "Қате туралы хабарлау",
    "IMAGE" : "Сурет",
    "MISTAKE_IN_THE_KNOWLEDGE_BASE" : "Білім базасындағы қате",
    "KNOWLEDGE_BASE_SECTION_NAME" : "БІЛІМ БАЗАСЫНДАҒЫ БӨЛІМ АТАУЫ",
    "KNOWLEDGE_BASE_ARTICLE_NAME" : "БІЛІМ БАЗАСЫНДАҒЫ МАҚАЛА АТАУЫ",
    "DESCRIPTION" : "СИПАТТАМАСЫ",
    "DESCRIBE_MISTAKE_OR_INACCURACY_YOU_ENCOUNTERED" : "СІЗГЕ КЕЗДЕСКЕН ҚАТЕ НЕМЕСЕ ДӘЛСІЗДІКТІ СИПАТТАҢЫЗ",
    "_COMMENT_17":"Баптаулар",
    "USERS" : "Қолданушылар",
    "DIGESTS" : "Дайджесттер",
    "REPORTS" : "Есептер",
    "SAVED_FILTERS" : "Сақталынған сүзгілер",
    "LIST_OF_FILTERS" : "Сүзгілер тізімі",
    "CONNECTED_ACCOUNTS" : "Қосылған есептік жазбалар",
    "USERS_LOGS" : "Пайдаланушылар логтары",
    "_COMMENT_18":"пайдаланушылар құқығының баптаулары",
    "LIST_OF_USERS" : "Пайдаланушылар тізімі",
    "#" : "№",
    "EMAIL_2" : "EMAIL",
    "CREATE_USER" : "Пайдаланушыны құру",
    "EDITING_USER_RIGHTS" : "Пайдаланушы құқығын түзету",
    "RIGHTS_LIST_FILTER" : "Құқық тізімін сүзгілеу",
    "ACTIVATED_FIRST" : "Алдымен қосылғандар",
    "DEACTIVATED_FIRST" : "Алдымен өшірілгендер",
    "CREATE_USERS" : "Пайдаланушыларды құру",
    "EDIT_DELETE_USERS" : "Пайдаланушыларды түзету/өшіру",
    "VIEW_USERS_LIST" : "Пайдаланушылар тізімін қарау",
    "CREATE_TOPICS" : "Тақырып құру",
    "EDIT_DELETE_TOPICS" : "Тақырыпты түзету/өшіру",
    "VIEW_TOPICS_LIST" : "Тақырып тізімін қарау",
    "FAVORITES_LISTS_WORKING" : "Таңдамалы тізімімен жұмыс",
    "TRASHBIN_LISTS_WORKING" : "Себет тізімімен жұмыс",
    "BLACK_LISTS_WORKING" : "Қара тізімімен жұмыс",
    "AUTORULES_CREATION" : "Автоереже құру",
    "EDIT_DELETE_AUTORULES" : "Автоережені түзету/өшіру",
    "AUTORULES_VIEW" : "Автоережені қарау",
    "VIEW_TOPICS_SELECTION" : "Тақырып бойынша үлгілерді қарау",
    "MANUAL_POST_ADDING" : "Басылымдарды қолмен қосу",
    "POST_TONE_CHANGING" : "Басылымдар тонын өзгеру",
    "CREATE_FILTERS_SETTING" : "Сүзгілер баптауларын құру",
    "EDIT_DELETE_FILTER_SETTINGS" : "Сүзгілер баптауларын түзету/өшіру",
    "APPLY_FILTER_SETTINGS" : "Сүзгілер баптауларын қолдан",
    "CREATE_REPORTS" : "Есептер құру",
    "EDIT_DELETE_REPORTS" : "Есептерді түзету/өшіру",
    "VIEW_DOWNLOAD_REPORTS" : "Есептерді қарау және жүктеу",
    "VIEW_EXTENDED_ANALYTICS" : "Ұзартылған аналитиканы қарау",
    "DOCUMENTS_DOWNLOAD_ANALYTICS_SELECTION" : "Құжаттарды жүктеу (аналитика/үлгі)",
    "VIEW_TOPICS_COMPARISON" : "Тақырыптарды салыстыруды қарау",
    "LIST_OF_DIGESTS" : "Дайджесттер тізімі",
    "DIGESTS_CREATION" : "Дайджестті құру",
    "EDIT_DELETE_DIGESTS" : "Дайджестті түзету/өшіру",
    "VIEW_DIGESTS_LIST" : "Дайджесттер тізімін қарау",
    "CREATE_EMAIL_NOTIFICATIONS" : "Поштаға хабарлама құру",
    "EDIT_DELETE_EMAIL_NOTIFICATIONS" : "Поштаға хабарламаны түзету/өшіру",
    "VIEW_EMAIL_NOTIFICATIONS_LIST" : "Поштаға хабарламалар тізімін қарау",
    "CREATE_TELEGRAM_NOTIFICATIONS" : "Telegram-ға хабарлама құру",
    "EDIT_DELETE_TELEGRAM_NOTIFICATIONS" : "Telegram-ға хабарламаны түзету/өшіру",
    "VIEW_TELEGRAM_NOTIFICATIONS_LIST" : "Telegram-ға хабарламалар тізімін қарау",
    "ANSWER_POSTS" : "Басылымдарға жауап",
    "LINKING_THIRD_PARTY_ACCOUNTS" : "Басқа ресурстардың есептік жазбаларына байланысу",
    "EDIT_DELETE_LINKED_THIRD_PARTY_ACCOUNTS" : "Басқа ресурстардың есептік жазбаларына байланысқандарды түзету/өшіру",
    "CREATE_NEWSBREAKS" : "Ақпараттық жағдай құру",
    "EDIT_DELETE_NEWSBREAKS" : "Ақпараттық жағдайды түзету/өшіру",
    "VIEW_NEWSBREAK_SELECTION" : "Ақпараттық жағдай бойынша үлгілерді қарау",
    "_COMMENT_19":"пайдаланушылар тақырып тізімін және мәліметін баптау",
    "USER_DATA_EDIT" : "Пайдаланушылар мәліметін түзету",
    "PHONE_NUMBER" : "ТЕЛЕФОН НӨМІРІ",
    "CHANGE_PASSWORD" : "Құпия сөзді өзгерту",
    "NEW_PASSWORD" : "ЖАҢА ҚҰПИЯ СӨЗ",
    "REPEAT_NEW_PASSWORD" : "ЖАҢА ҚҰПИЯ СӨЗДІ ҚАЙТАЛАУ",
    "LIST_OF_ASSIGNED_TOPICS" : "ТАҒАЙЫНДАЛҒАН ТАҚЫРЫПТАР ТІЗІМІ",
    "LIST_OF_RIGHTS" : "ҚҰҚЫҚТАР ТІЗІМІ",
    "LIST_OF_USER_ASSIGNED_TOPICS_EDIT" : "Пайдаланушыға қолжетімді тақырыптар тізімін өзгерту",
    "LIST_OF_TOPICS_FILTER" : "ТАҚЫРЫПТАР ТІЗІМІН СҮЗГІЛЕУ",
    "SORT_BY" : "БОЙЫНША СҰРЫПТАУ",
    "DELETE_USER" : "Пайдаланушыны өшіру",
    "CONFIRM_THIS_USER_DELETE" : "КЕЛЕСІ ПАЙДАЛАНУШЫНЫ ӨШІРУДІ РАСТАҢЫЗ",
    "YOU_HAVE_UNSAVED_CHANGES_IN_DATA" : "Сізде сақталынбаған өзгертілген мәліметтер бар",
    "DONT_SAVE" : "Сақтамау",
    "_COMMENT_20":"Дайджесттер баптаулары",
    "TOPIC_NAME" : "ТАҚЫРЫПТЫҢ АТАУЫ",
    "PERIODICITY" : "МЕРІЗІМДІЛІК",
    "SENDING_TIME" : "ЖІБЕРУ УАҚЫТЫ",
    "FORMAT" : "Формат",
    "CREATE_DIGEST" : "Дайджест құру",
    "DIGEST_DISTRIBUTION_SETUP" : "Дайджестті жіберу баптаулары",
    "NUMBER_OF_DIGESTS_PER_DAY" : "БІР КҮНГІ ДАЙДЖЕСТТЕР САНЫ",
    "DIGESTS_SENDING_TIME" : "ДАЙДЖЕСТТІ ЖІБЕРУ КҮНДЕРІ",
    "DIGESTS_SENDING_DAYS" : "ДАЙДЖЕСТТІ ЖІБЕРУ КҮНДЕРІ",
    "CHOOSE_ALL" : "Бәрін таңдау",
    "MONDAY" : "Дүйсенбі",
    "TUESDAY" : "Сейсенбі",
    "WEDNESDAY" : "Сәрсенбі",
    "THURSDAY" : "Бейсенбі",
    "FRIDAY" : "Жұма",
    "SATURDAY" : "Сенбі",
    "SUNDAY" : "Жексенбі",
    "DIGESTS_SENDING_DATES" : "ДАЙДЖЕСТТІ ЖІБЕРУ КҮНДЕРІ",
    "ENTER_DATE" : "КҮНДІ ЕНГІЗІҢІЗ",
    "ADD" : "Қосу",
    "RECIPIENTS_EMAILS" : "АЛУШЫЛАР EMAIL-Ы",
    "ENTER_EMAIL" : "EMAIL ЕНГІЗІҢІЗ",
    "CHARTS" : "ГРАФИКТЕР",
    "DIGEST_WITHOUT_CHARTS" : "Графиксіз дайджест",
    "DIGEST_FORMAT" : "ДАЙДЖЕСТ ФОРМАТЫ",
    "DIGEST_SETTINGS_VIEW" : "Дайджест баптауларын қарау",
    "DIGEST_DELETE" : "Дайджестті өшіру",
    "CONFIRM_THIS_DIGEST_DELETE" : "КЕЛЕСІ ДАЙДЖЕСТТІ ӨШІРУДІ РАСТАҢЫЗ",
    "_COMMENT_21":"тақырыптар баптаулары",
    "TOPICS_LIST" : "Тақырыптар тізімі",
    "EDIT_DATE" : "ӨЗГЕРТУ КҮНІ",
    "KEY_WORDS_2" : "КІЛТТІК СӨЗДЕР",
    "STOP_WORDS" : "СТОП СӨЗДЕР",
    "CREATE_TOPIC" : "Тақырып құру",
    "SUBTOPICS_LIST" : "Тақырыпшалар тізімі",
    "CREATE_SUBTOPIC" : "Тақырыпшалар құру",
    "TOPIC_SETTINGS_EDIT" : "Тақырыптар баптауларын өзгерту",
    "SUBTOPIC_SETTINGS_EDIT" : "Тақырыпшалар баптауларын өзгерту",
    "TOPIC_SETTINGS_VIEW" : "Тақырыптар баптауларын қарау",
    "GENERAL" : "Стандартты",
    "ADVANCED" : "Кеңейтілген",
    "ENTER_TITLE" : "АТАУЫН ЕНГІЗІҢІЗ",
    "SELECTION_LIMIT" : "ҮЛГІЛЕРДІ ШЕКТЕУ",
    "CONNECTION_WITH_CABINET" : "КАБИНЕТПЕН БАЙЛАНЫС",
    "OTHER_2" : "Басқа",
    "MAIN" : "Басты",
    "COMPETITORS" : "Бәсекелестер",
    "PRODUCTS" : "Өнімдер",
    "PERSONS" : "Тұлғалар",
    "AFFILIATED_COMPANIES" : "Еншілес компаниялар",
    "COMPANIONS" : "Серіктестер",
    "PROMO" : "Акциялар",
    "DEPARTMENTS" : "Бөлімдер",
    "AKIMS" : "Әкімдер",
    "MANAGEMENT" : "Басқару",
    "ALL" : "Барлығы",
    "WORLD_NEWS" : " Дүниежүзілік жаңалықтар",

    "AZERBAIJAN" : "Әзірбайжан",
    "ARMENIA" : "Армения",
    "BELARUS" : "Беларусь",
    "KAZAKHSTAN" : "Қазақстан",
    "KYRGYZSTAN" : "Kyrgyzstan",
    "MOLDOVA" : "Молдова",
    "RUSSIA" : "Ресей",
    "TAJIKISTAN" : "Тәжікстан",
    "TURKMENISTAN" : "Түрікменстан",
    "UZBEKISTAN" : "Өзбекстан",
    "UKRAINE" : "Украина",
    
    "KEY_WORDS_CREATION" : "НЕГІЗГІ СӨЗДЕРДІ ҚҰРУ",
    "ENTER_NEW_WORD" : "ЖАҢА СӨЗДІ ЕНГІЗІҢІЗ",
    "WORDS" : "СӨЗДЕР",
    "TOPIC_GEOGRAPHY" : "ТАҚЫРЫПТАР ЖАҒРАФИЯСЫ",
    "LINKS_TO_PROFILES_&_SOURCES" : "РЕСУРСТАР МЕН ПРОФИЛЬДЕРГЕ СІЛТЕМЕ",
    "LINKS_TO_PROFILES" : "ПРОФИЛЬДЕРГЕ СІЛТЕМЕ",
    "LINKS_TO_SOURCES" : "РЕСУРСТАРҒА СІЛТЕМЕ",
    "PROFILE_OR_SOURCE_URL" : " РЕСУРСТАР МЕН ПРОФИЛЬДЕРДІҢ URL",
    "SETTINGS_IMPORT" : "БАПТАУЛАР ИМПОРТЫ",
    "TOPIC_DELETE" : "Тақырыпты өшіру",
    "CONFIRM_THIS_TOPIC_DELETE" : "КЕЛЕСІ ТАҚЫРЫПТЫ ӨШІРУДІ РАСТАҢЫЗ",
    "_COMMENT_22":"есептер баптаулары",
    "REPORT_PREVIEW_WINDOW" : "Есептерді алдын-ала қарау терезесі",
    "REPORT_SETTINGS" : "Есептер баптаулары",
    "REPORT_DESIGN" : "Есептерді безендіру",
    "GRAPHICS_AND_TABLES" : "Графики и таблицы",
    "REPORT_ELEMENT" : "Элемент отчета",
    "TOPIC_NAME_2" : "Тақырыптар атаулары",
    "HEADERS_AND_FOOTERS" : "Колонтитулдар",
    "COLLECTION_MAIN_INFORMATION" : "Жинақ бойынша негізгі ақпарат",
    "MENTIONING_DYNAMICS" : "Еске алу динамикасы",
    "POPULAR_RESOURCES" : "Кеңінен таралған ресурстар",
    "GEOGRAPHY_OF_RESOURCES" : "Ресурстар географиясы",
    "MEDIA_TYPE" : "БАҚ түрі (халықаралық, аймақтық және т.б.)",
    "AUTHORS_OF_POSTS" : "Посттар авторы (әлеуметтік желілер үшін)",
    "COMMUNITIES_FOR_SN" : "Қауымдастықтар (әлеуметтік желілер үшін)",
    "INVOLVMENT" : "Қатысу (әлеуметтік желілер үшін)",
    "STATISTIC_OF_AUTHORS" : "Авторлар статистикасы (әлеуметтік желілер үшін)",
    "GEOGRAPHY_OF_AUTHORS_FOR_SN" : "Авторлар географиясы (әлеуметтік желілер үшін)",
    "PUBLICATION_DETAILS" : "Құжаттардың мәліметтері",
    "TEXT_REPRESENTATION": "Мәтінді көрсету",
    "NAME_OF_PUBLICATION" : "Мақала атауы",
    "ADDRESS" : "Мекен-жай (URL)",
    "DATE" : "Күні",
    "DUBLICATES" : "Көшірмелері",
    "PUBLICATION_SHORT_TEXT" : "Мақаланың қысқаша мәтіні",
    "PUBLICATION_TEXT" : "Мақала мәтіні",
    "NUMBERING" : "Нөмірлеу",
    "SEPARATE_DUBLICATES" : "Көшірмелерді бөлу",
    "GROUP_BY" : "Бойынша топтастыру",
    "GROUPING_TYPE": "Топтастыру түрі",
    "GROUP" : "Топтастыру",
    "TONE_2" : "тоны бойынша",
    "MASS_MEDIA_TYPES" : "БАҚ түрі бойынша",
    "RESOURCE" : "Ресурс",
    "RESOURCES" : "ресурстары бойынша",
    "WITHOUT_GROUPING" : "топтастырмау",
    "TAGS_2" : "тег бойынша",
    "TONES_AND_MESS_MEDIA_TYPES" : "тоны және БАҚ түрі бойынша",
    "MEDIA_TYPES_AND_TONES" : "БАҚ түрі және тоны бойынша",
    "SUBTHEMES" : "тақырыпшалар бойынша",
    "_COMMENT_23":"хабарламалар баптаулары",
    "LIST_OF_NOTIFICATIONS" : "Хабарламалар тізімі",
    "CREATED" : "ҚҰРЫЛДЫ",
    "ACTIVATED" : "ІСКЕ ҚОСЫЛУ",
    "CONDITION_OF_ACTIVATION" : "ІСКЕ ҚОСЫЛУ ШАРТТАРЫ",
    "CREATE_NOTIFICATION" : "Хабарлама құру",
    "CREATE_SUBSCRIPTION_NOTIFICATION" : "Хабрламаларға жазылым жасау",
    "NOTIFICATION_EDIT" : "Хабарламаны түзету",
    "NOTIFICATION_VIEW" : "Хабарламаны көру",
    "EDIT_COMPANY" : "Компанияны өңдеу",
    "SUBTHEME_NAME" : "ТАҚЫРЫПШАЛАР АТАУЫ",
    "MEDIA_TYPE_2" : "МЕДИА ТҮРЛЕРІ",
    "MEDIATYPE" : "МЕДИА ТҮРЛЕРІ",
    "REACTIONS" : "РЕАКЦИЯЛАР",
    "NUMBER_OF_POSTS" : "Басылымдар саны",
    "NUMBER_OF_NEW_POSTS" : "Жаңа басылымдар саны",
    "NUMBER_OF_SELECTED_POSTS" : "Таңдамалы басылымдар саны",
    "CHANGE_OF_NUMBER_OF_PUBLICATIONS" : "Басылымдар санының өзгеруі",
    "WITH_NEGATIVE_TONE" : "Теріс тонды",
    "WITH_NEUTRAL_TONE" : "Бейтарап тонды",
    "WITH_POSITIVE_TONE" : "Оң тонды",
    "WITH_UNIDENTIFIED_TONE" : "Үндестілік анықталмаған",
    "WITH_OBSCENE_SPEECH" : "Әдепсіз сөздермен",
    "WITHOUT_REGARD_TO_TONE_AND_MARKS" : "Үндестілік және белгілері есепке алынбаған",
    "PER_DAY" : "Бір тәулік ішінде",
    "PER_WEEK" : "Бір апта ішінде",
    "PER_MONTH" : "Бір ай ішінде",
    "YOU_CAN_ENTER_NEGATIVE_VALUES_WITH_-_SIGN" : "Теріс мағынаны – белгісімен енгізуге болады.",
    "NOTIFICATON_DELETE" : "Хабарламаны өшіру",
    "CONFIRM_THIS_NOTIFICATION_DELETE" : "КЕЛЕСІ ХАБАРЛАМАНЫ ӨШІРУДІ РАСТАҢЫЗ",
    "_COMMENT_24":"telegram баптаулары",
    "NUMBER_GROUP" : "НӨМІР/ТОП",
    "PHONE_NUMBERS_OR_GROUP_NAMES" : "АЛУШЫЛАРДЫҢ ТЕЛЕФОН НӨМІРЛЕРІ НЕМЕСЕ ТОП АТАУЛАРЫ",
    "HOW_TO_SETUP_SENDING" : "ЖІБЕРІЛІМДІ ҚАЛАЙ БАПТАУ КЕРЕК?",
    "CHOOSE_GROUP_NAME" : "ТОП АТАУЫН ТАҢДАҢЫЗ",
    "CHOOSE_PHONE_NUMBER" : "ТЕЛЕФОН НӨМІРІН ЕНГІЗІҢІЗ",
    "RESET_ALL" : "Бәрін қалпына келтіру",
    "_COMMENT_25":"сүзгілер баптаулары",
    "FILTER_DELETE" : "Сүзгіні өшіру",
    "CONFIRM_THIS_FILTER_DELETE" : "КЕЛЕСІ СҮЗГІНІ ӨШІРУДІ РАСТАҢЫЗ",
    "_COMMENT_26":"қосылған есептік жазбаларды баптау",
    "LIST_OF_ACCOUNTS" : "Есептік жазбалар тізімі",
    "CREATE_ACCOUNT" : "Жазба құру",
    "UPDATE_ACCOUNT": "Аккаунтты өзгерту",
    "SHOW_ACCOUNT": "Аккаунт қаралымы",
    "ACCOUNT_DELETE" : "Есептік жазбаны өшіру",
    "CONFIRM_ACCOUNT_DELETE" : "КЕЛЕСІ ЕСЕПТІК ЖАЗБАНЫ ӨШІРУДІ РАСТАҢЫЗ",
    "CONNECTION_OF_ACCOUNT" : "Есептік жазбаны қосу",
    "RESOURCE" : "РЕСУРС",
    "CHOOSE_RESOURCE_NAME" : "РЕСУРС АТАУЫН ТАҢДАҢЫЗ",
    "ENTER_LOGIN" : "ЛОГИН ЕНГІЗІҢІЗ",
    "ENTER_PASSWORD" : "ҚҰПИЯ СӨЗ ЕНГІЗІҢІЗ",
    "_COMMENT_27":"пайдаланушылар логтары",
    "CHOOSE_ALL_2" : "Бәрін таңдау",
    "LOG_FOR_SELECTED" : "Таңдаулар бойынша лог",
    "VIEW_LOG" : "Лог көрсету",
    "DATE_2" : "КҮНІ",
    "TIME" : "УАҚЫТЫ",
    "NEXT_ACTION_TIME" : "КЕЛЕСІ ӘРЕКЕТ УАҚЫТЫ",
    "MOVING" : "ЖЫЛЖУЫ",
    "CREATING" : "ҚҰРУ",
    "CHANGING" : "ӨЗГЕРТУ",
    "DELETING" : "ӨШІРУ",
    "_COMMENT_28":"Пайдаланушы үшін хабарлама",
    "ONE_SHOULD_BE":" ЖОЛДАРДЫҢ БІРЕУІН ТОЛТЫРУ КЕРЕК",
    "EXIT":"Шығу",
    "CLEAR_DATA_CONTENTS_DELETED_KEYWORDS": "Стереть данные содержащие удаленные ключевые слова?",
    "EVERY_PUBLICATION_TELEGRAM": "ӘР ЖАРИЯЛАНЫМ",
    "NEGATIVE_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕГАТИВНЫХ",
    "NEUTRAL_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕЙТРАЛЬНЫХ",
    "POSITIVE_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО ПОЗИТИВНЫХ",
    "UNDEFINED_SENTIMENT_COUNT_TELEGRAM": "КОЛИЧЕСТВО НЕ ОПРЕДЕЛЕННЫХ",
    "NEGATIVE_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕГАТИВНЫХ",
    "NEUTRAL_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕЙТРАЛЬНЫХ",
    "POSITIVE_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ ПОЗИТИВНЫХ",
    "UNDEFINED_SENTIMENT_PERCENT_TELEGRAM": "ПРОЦЕНТ НЕ ОПРЕДЕЛЕННЫХ",
    "EVERY_PUBLICATION_PERCENT_TELEGRAM": "ӘР ПРОЦЕНТТІҢ ЖАРИЯЛАНЫМЫ",
    "EVERY_PUBLICATION": "ӘР ЖАРИЯЛАНЫМ",
    "EVERY_PUBLICATION_PERCENT": "ӘР ПРОЦЕНТТІҢ ЖАРИЯЛАНЫМЫ",
    "PERCENT": "ПАЙЫЗ",
    "ALERT_POLICY_AGREGATE_COUNT": "ОПОВЕЩЕНИЕ - КОЛИЧЕСТВО",
    "ABSOLUTE_COUNT": "АБСОЛЮТНОЕ КОЛИЧЕСТВО",
    "AGGREGATE": "СОВОКУПНОСТЬ",
    "AGGREGATE_PERCENT": "ПРОЦЕНТ",
    "AGGREGATE_COUNT": "КОЛИЧЕСТВО",
    "AGGREGATE": "СОВОКУПНОСТЬ",
    "METRCIC": "Метрика",
    "METRIC_TYPES": "Тип метрики",
    "COUNT" : "САНЫ",
    "POLICY": "Ереже",
    "POLICIES": "Ережелер",
    "HOURLY": "Сағат",
    "DAILY" : "Күн",
    "WEEKLY" : "Апта",
    "MONTHLY" : "Ай",
    "PUBLICTION": "Жарияланымды",
    "RESOURCE": "Дереккөз",
    "FROM_FAVOURITE": "Таңдалымнан",
    "PROFILE_URL": "Сілтеме",
    "RESOURCE_URL": "Дереккөз",
    "PROFILE": "Профиль",
    "PROFILES": "Профильдер",
    "CREATED_AT": "Құрылған күні",
    "UPDATED_AT": "Өңделген күні",
    "DELIVERY_TYPE": "Хабарландыру түрі",
    "DAYS_COUNT" : "КҮНДЕР САНЫ",
    "ADMIN": "Администратор",
    "COMPANY_MANAGEMENT" : "Компанияны басқару",
    "CREATE_COMPANY" : "Компанияны құру",
    "USER_MANAGEMENT" : "Управление пользователями",
    "TOPIC_MANAGEMENT" : "Управление темамим",
    "WITHOUT_FILTER_TYPE": "Без типа фильтра",
    "WITH_TAGS": "Тегпен",
    "WITHOUT_TAGS": "Тегсіз",
    "VIEW_FILTER": "Фильтрді қарау",

    'TOPIC_NAME': 'Тақырып',
    'PERIOD': 'Период',
    'REQUEST_DATE': 'Есептің күні',
    'COLONTITLES': 'Колонтитулдар',
    'TABLE_OF_CONTENTS': 'Мазмұны',
    'MAIN_INFO': 'Жинақ туралы негізгі ақпарат',
    'SENTIMENT_DYNAMICS': 'Тон динамикасы',
    'LANGUAGE_DYNAMICS': 'Тілдердің динамикасы',
    'LANGUAGE_TOTAL': 'Тіл бойынша жалпы',
    'MEDIA_TYPE_DYNAMICS': 'Дереккөз түрінің динамикасы',
    'MEDIA_TYPE_TOTAL': 'Жалпы дереккөз түрі',
    'MEDIA_TYPE_BY_SENTIMENT': 'Тон бойынша дереккөз түрі',
    'KEYWORDS': 'Кілттік сөздер',
    'STOPWORDS': 'Стоп сөздер',
    'POPULAR_WORDS': 'Кеңінен таралған сөздер',
    'TAGS': 'Тегтер',
    'POPULAR_RESOURCES': 'Кеңінен таралған ресурстар',
    'MEDIA_GEOGRAPHY': 'Дереккөздер географиясы',
    'MEDIA_SCALE': 'Дереккөз аймағы',
    'MEDIASCALE': 'Дереккөз аймағы',
    'SOCIAL_DOMAIN_DYNAMICS': 'Әлеуметтік аймақ динамикасы',
    'SOCIAL_DOMAIN': 'Әлеуметтік аймақ',
    'AUTHORS': 'Авторлар',
    'GROUPS': 'Группы',
    'AUTHORS_GEOGRAPHY': 'Авторлар географиясы',
    'PROFILE_AGE': 'Профиль жасы',
    'PROFILE_GENDER': 'Профиль жынысы',
    'INVOLVEMENT': 'Қатысуы',
    'PUBLICATION_TYPE_BY_SENTIMENT': 'Тон бойынша жарияланым түрі',
    'PUBLICATION_TYPE_TOTAL': 'Жалпы жарияланым түрі',
    'MENTIONS_FEED': 'Ескерту',
    'CREATE_REPORT_SETTINGS': 'Есеп баптауларын құру',
    'DOCUMENT_TITLE': 'Құжат тақырыбы',
    'DOCUMENT_DOMAIN': 'Құжат ресурсі',
    'DOCUMENT_AUTHOR': 'Құжат авторы',
    'DOCUMENT_URL': 'Құжат URL-і',
    'DOCUMENT_TYPE': 'Құжат түрі',
    'DOCUMENT_SENTIMENT': 'Құжат тоны',
    'DOCUMENT_LOCATION': 'Құжат жер бедері',
    'DOCUMENT_DATE': 'Құжат күні',
    'DOCUMENT_COVERAGE': 'Құжаттама',
    'DOCUMENT_TAGS': 'Құжат тегтері',
    'DOCUMENT_TEXT': 'Құжат мәтіні',
    'DOCUMENT_NUMERATION': 'Құжат нөмірлеуі',
    
    'HIGHLIGHTED_FULL_TEXT': 'Құжатты ерекшелеу',
    'PLAIN_FULL_TEXT': 'Толық мәтін',
    'HIGHLIGHTED_FRAGMENTS': 'Фрагменттерді ерекшелеу',

    "ADD_POLICY": "Ережені қосу",
    "DELETE_POLICY": "Ережені жою",

    'BY_SENTIMENT': 'Тон бойынша',
    'BY_MEDIA_TYPE': 'Түрі бойынша',
    'BY_DOMAIN': 'Дереккөз бойынша',
    'NO_GROUPING': 'Топтастыруы жоқ',
    'BY_TAGS': 'Тегтер бойынша',
    'BY_SENTIMENT_AND_MEDIA_TYPE': 'Тон және дереккөздердің түрлері бойынша',
    'BY_MEDIA_TYPE_AND_SENTIMENT': 'Дереккөздердің түрлері және тон бойынша',
    'BY_SUBTOPICS': 'Тақырыпша бойынша',
    'COMPANY': 'КОМПАНИЯ',
    'COMPANY_1': 'КОМПАНИЯСЫ',
    'COMPANIES': 'Компании',
    'ACCOUNTS': 'Аккаунттар',
    'ACCOUNT': 'Аккаунт',
    'COMPANY_NAME': 'КОМПАНИНЫҢ АТЫ',
    'EXIT_COMPANY': 'Компаниядан шығу',
    'UPDATE': 'Өзгерту',
    'COMPANY_ACCOUNTS': 'компаниясының аккаунттары',
    'COMPANY_TOPICS': 'компаниясының тақырыптары',
    'ACCOUNT_TOPICS': 'Аккаунттың тақырыптары',
    'MAREKER_TOPICS': 'Маркердің тақырыптары',
    'TOPIC_ACCOUNTS': 'Тақырыптың аккаунттары',
    'ACCOUNT_LOCALE': 'Локализация',
    'ATTACH': 'Тіркеу',
    'DETACH': 'ОТКРЕПИТЬ',
    'ATTACH_ACCOUNT_TO_TOPIC': "Тақырыпқа пайдаланушыны тіркеу",
    'ATTACH_TOPIC_TO_ACCOUNT': "Пайдаланушыны тақырыпқа тіркеу",
    'ATTACH_TOPIC_TO_MARKER': "Маркерге тақырыпты тіркеу",
    'ATTACH_ACCOUNT_TO_COMPANY': 'Пайдаланушыны компанияға тіркеу',
    'ATTACH_ACCOUNT_TO_COMPANY_AT_FIRST': 'Для редактирования следует прикрепить пользователя к компании',
    'DETACH_ACCOUNT_FROM_COMPANY': 'Открепить пользователя от компании',
    'ACTIVE': 'Белсенді',
    'WITH_TAG': 'Тегпен',
    'WITHOUT_TAG': 'Тегсіз',
    "EXPIRE_DATE" : "Мерзімі аяқталған күні",
    "BIN": "БИН",
    "ROLE": 'Рөл',
    "SURE_WANT_TO_DELETE": "Сіз шынымен жойғыңыз келе ме?",
    "SURE_WANT_TO_STOP": "Сіз шынымен тоқтатқыңыз келе ме?",
    "SURE_WANT_TO_DETACH": "Сіз шынымен босатқыңыз келе ме?",
    "SURE_WANT_TO_EXIT": "Сіз шынымен жүйеден шыққыңыз келе ме?",
    "SURE_WANT_TO_EXIT_COMPANY": "Сіз шынымен компаниядан шыққыңыз келе ме?",
    "AUTHORITIES": "Права доступа",
    "ROLE_SUPER_ADMIN": "Супер әкімші",
    "ROLE_ADMIN": "Әкімші",
    "ROLE_MARKER": "Маркер",
    "ROLE_USER": "Пайдаланушы",
    "STATES": "Елдер",
    "EVERYTHING_EXCEPT_KAZAKHSTAN" : "Все кроме Казахстана",
    "MASS_INCLUDE": "БАҚ бойынша жинау",
    "SOCIAL_INCLUDE": "Әлеуметтік желілер бойынша жинау",
    "SOCIAL_ALLOW_UNDEFINED_LOCATION": "Әлеуметтік желілер орналасқан жерсіз",
    "PAUSE": "Тоқтату",
    "TOPIC_CREATED_SUCCEFULLY": "Тема успешно создана",
    "SUBTOPIC_CREATED_SUCCEFULLY": "Подтема успешно создана",
    "TOPIC_CREATED_WITH_ERRORS": "Тема создана с ошибками",
    "SUBTOPIC_CREATED_WITH_ERRORS": "Подтема создана с ошибками",
    "TOPIC_NOT_CREATED": "Тема не была создана",
    "SUBTOPIC_NOT_CREATED": "Подтема не была создана",
    "TOPIC_STARTED": "Тема успешно запущена",
    "TOPIC_STOPED": "Тема успешно приостановлена",
    "TOPIC_DELETED": "Тема успешно удалена",
    "USER_TAGS": "Пайдалынушының тегтері",
    "ITEM_NAME": "Тақырып",
    "WORD_TITLES": "WORD ТАҚЫРЫПТАР",
    "WORD_ANALYTICS": "WORD АНАЛИТИКАСЫ",
    "PDF_TITLES": "PDF ТАҚЫРЫПТАР",
    "PDF_ANALYTICS": "PDF АНАЛИТИКАСЫ",
    "EXCEL_TITLES": "EXCEL ТАҚЫРЫПТАР",
    "EXCEL_ANALYTICS": 'EXCEL АНАЛИТИКАСЫ',
    "JSON_FEED": "JSON ІРІКТЕУІ",
    "JSON_ANALYTICS": "JSON АНАЛИТИКАСЫ",
    "JSON_FEED_ANALYTICS": "JSON ІРІКТЕУ АНАЛИТИКАСЫ",
    "STANDART_REPORT": "СТАНДАРТТЫ ЕСЕП",
    "INACTIVE": "Белсенді емес",
    "BLOCKED": "Бұғатталған",
    "CONTRACT_EXPIRED": "Келесім шарттың қолданулы мерзімі аяқталды",
    "DATE_CREATED_AT_↓" : "Құрылған күні ↓",
    "DATE_CREATED_AT_↑" : "Құрылған күні ↑",
    "DATE_UPDATED_AT_↓" : "Өзгертілген күні ↓",
    "DATE_UPDATED_AT_↑" : "Өзгертілген күні ↑",
    "SET_ACTIVE" : "Белсендеу",
    "SET_PAUSE" : "Тоқтату",
    "SET_DELETE" : "Жою",
    "SET_VIEW": "Қарау",
    "SET_EDIT": "Өңдеу",
    "SET_ADD": "Тіркеу",
    "SET_REMOVE": "Бекіту",
    "SET_COPY": "Көшіру",
    "SET_RETURN": "Қайтару",
    "SET_GO_TO_COMPANY": "Компанияға өту",
    "TOPICS_VISIBILITY": "Видимость тем",
    "SEARCH_BY_TITLE": "Тақырып бойынша іздеу",
    "MARKERS": "Маркерлер",
    "ARCHIVE_COLLECTION": "Архивті жинау",
    "ARCHIVE_COLLECTION_BY_PRFILES": "ПРОФИЛЬ БОЙЫНША АРХИВ ЖИНАУ",
    "ARCHIVE_COLLECTION_BY_RESOURCES": "РЕСУРС БОЙЫНША АРХИВ ЖИНАУ",
    "URL_IS_ALREADY_BEING_CHECKED": "Профильдің URL-і тексеруде",
    "URL_IS_NOT_BEING_CHECKED": "Профильдің URL-і тексерілмейді",
    "DOMAIN_IS_ALREADY_BEING_CHECKED": "Домен тексеруде",
    "DOMAIN_IS_NOT_BEING_CHECKED": "Домен тексерілмейді",
    "MESSAGE_SENDED": "Сообщение отправлено",
    "THANK_FOR_MESSAGE": "Спасибо за сообщение, оно принято в обработку.",
    "FIELD_NOT_BE_EMPTY": "Поле контакт или email не должно быть пустым",
    "HOW_CAN_WE_CONTACT_TO_YOU": "Как мы можем к вам обращаться",
    "OBLIGATORY_FIELD": "Міндетті ала",
    "ONLY_NUMBERS_AND_LETTERS_AT_LEAST_3": "Только буквы и числа, не менее 3-х символов",
    "ONLY_NUMBERS_AND_LETTERS": "Только буквы и числа",
    "ONLY_LETTERS": "Только буквы",
    "FEED_TYPES": "Іріктеу түлері",
    "MEDIA_TYPES" : "БАҚ түрі",
    "SENTIMENTS": "Үндестілік",
    "LANGUAGES": "Тілдер",
    "MEDIA_SCALES": "БАҚ статусы",
    "PROFILE_LOCATIONS": "Профильдердің орналасқан жері",
    "tv": "Теледидар",
    "print": "Басылым БАҚ (қағаз нұсқасы)",
    "news_agency": "Ақпараттық агентігі",
    "internet": "Ғаламтор",
    "social": "Әлеуметтік желілер",
    "positive": "Жағымды",
    "undefined": "Анықталмаған",
    "negative": "Жағымсыз",    
    "neutral": "Бейтарап",
    "regional": "Аймақтық",
    "central": "Орталық",
    "international": "Халықаралық",
    "is_swearing": "Балағат сөздер",
    "ISSWEARING": "Балағат сөздер",
    "DOWNLOADS": "Жүктеу",
    "post": "Пост",
    "comment": "Пікір",
    "share": "Репост",
    "article": "Мақала",
    "extended_share": "Қосымша репост",
    "video": "Видео",
    "COLLAPSE": "Жыйрату",
    "READ_MORE": "Ары қарай оқу",
    "RETURN_BACK_TO": "Қайтару",
    "DELAYED_START_TIME": "ВРЕМЯ ОТЛОЖЕННОГО СТАРТА",
    "STARS_ON_CENTER_OR_END": "Символ звездочки (*) может использоваться только в конце или середине ключевого слова (стоп-слова).",
    "NO_AMPERSANDS": "Логический оператор AND (амперсанд, &&) может использоваться только для соединения двух и более ключевых (стоп-) слов, обязательно должен быть отделен от них символом пробела, и не может встречаться в начале или в конце строки.",
    "THREE_AMPERSANDS": "Недопустимо использование сочетания трех и более символов амперсанд (&) подряд.",
    "ONE_SYMBOL_WITH_STAR": "Символ звездочки (*) может использоваться с ключевыми (стоп-) словами длиной от двух символов и более; недопустыми сочетания вида а*, б* и пр.",
    "NO_CHARACHTERS": "Следующие символы недопустимы: :;!,%()\/\\@",
    "SINGLE_STAR": "Отдельностоящие символы звездочки (*) и вопросительного знака запрещены.",
    "EXCLUSIVE_QUERIES_WITHOUT_SUBQERIES" : "Исключающие запросы не должны иметь подзапросы.",
    "QUERY_WITHOUT_EXCLUSIVE_SUBQERIES" : "Запрос не должен иметь исключающих подзапросов.",
    "SELECT_FILTER": "Фильтрді таңдау",
    "SELECT_LANGUAGE": "Тілді таңдау",
    "SELECT_ROLE": "Рөлді таңдау",
    "is_advertising": "Жарнама",
    "ISADVERTISING": "Жарнама",
    "reviewInclude": "Кері байланыс жинау",
    "profile_followers_count": "Жазылушылар саны",
    "WITH_ADVERTISING": "Жарнамалық контент",
    "WITHOUT_ADVERTISING": "Жарнамасыз",
    "WITHOUT_ADVERTISING_FILTERS": "Жарнама сүзгілерсіз",
    "WITHOUT_SWEARING": "Балағат сөзсіз",
    "WITH_SWEARING": "Балағат сөздер",
    "WITHOUT_SWEARING_FILTERS": "Балағат сөздер сүзгілерсіз",
    "UNKNOWN": "Белгісіз",


    "FOR_RELEVANT_COLLECTION": "Тақырыпта релеванттық жинақтау үшін логикалық операторлары арқылы КІЛТТІК СӨЗДЕР мен СТОП СӨЗДЕРІН дайындау қажет:",
    
    "LOGICAL_OPERATORS_WITH_EXAMPLES": "Логикалық операторлар + мысалдары:",
    "OPERATOR": "Оператор",
    "WHAT_FOR_USE_IT": "Не үшін пайдалану керек",
    "EXAMPLES_OF_KEYWORDS_WITH_OPERATORS": "Оператормен кілт сөздер мысалдары",
    "RESULT_IN_PUBLICATION_TEXT": "Жарияланым мәтініндегі нәтижелер",
    "STAR": "* (Жұлдызша)",
    "MATCHES_ENDING_TO_KEYWORD": "Подбирает окончание к ключевому слову, в том числе окончание может быть нулевым, также его склоняет.",
    "TALDYKORGAN_STAR": "Талдықорған",
    "TALDYKORGAN_DECLINATION": "Талдықорған, Талдықорғанда, Талдықорғанмен, Талдықорғанның және т.б.",
    "CAN_USE_COMPLEX_PHRASES": "Күрделі сөз тіркестерін қолдануға болады",
    "TALDYKORGAN_AVIA_BASE": "Талдықорған* авиация* база",
    "TALDYKORGAN_AVIA_BASE_DECLINATION": "Талдықорғанның авиациялық базасы, Талдықорғанның авиациялық базасында және т.б.",
    "LINKING_MULTIPLE_PARTS": "Связь нескольких частей ключевых слов, которые расположены в пределах текста одной публикации. При поиске соблюдается требование обязательного присутствия всех частей связанного ключевого слова. При этом порядок частей ключевого слова не имеет значения.",
    "TALDYKORGAN_SUBSIDIES": "Талдықорған* && субсидия*",
    "CONSTRUCTIVE_DIALOG_IN_TALDYKORGAN": "Талдықорғанда билік пен бизнес сындарлы диалог жасады. Облыста агроөнеркәсіптің 4,5 мың субъектісіне 10,5 млрд. теңге субсидия берілген.",
    "TALDYKORGAN_AUTHORITIES_AND_SUBSIDIES": "Талдықорған* билік* && агроөнеркәсіп* && субсидия*",
    "HISTORICAL_MIGRATION": "Тарихи миграция",
    "VIEW_HISTORICAL_MIGRATION": "Тарихи миграцияны қарау",
    "CREATE_HISTORICAL_MIGRATION": "Тарихи миграцияны құру",
    "COLLECTION": "Коллекциясы",
    "ACCOUNT_NAME": "Аккаунтың тегі",
    "DATE_START": "Басталу мерзімі",
    "DATE_END": "Аяқталу мерзімі",
    "EXPECTED_DOCUMENTS": "Күтілетін құжаттар саны",
    "CURRENT_DOCUMENTS": "Құжаттардың ағымдағы саны",
    "LOADED_DOCUMENTS_PERCENT": "Жүктеп алынған құжаттардың пайызы",
    "START_MIGRATION": "Миграцияны бастау",
    "GET_EXPECTED_DOCUMENTS": "Құжаттардың ағымдағы санын көру",
    "EXPECTED_DOCS / CURRENT_DOCS": "Күтілетін / ағымдағы құжаттар",
    "PAUSED": "Үзіліс",
    "СOMPLETE": "Аяқталды",
    "FAILED": "Сәтсіз",
    "RETRY": "Қайталау",
    "PENDING": "Күтуде",
    "common_location_country_id": "Орналасқан жері",
    "COMMONLOCATIONCOUNTRYID": "Орналасқан жері",
    "WITHOUT_VALUE": "Мағынасы жоқ",
    "HIDE_LEFT_PANEL": "Сол жақ панельді жасыру",
    "HIDE_RIGHT_PANEL": "Оң жақ панельді жасыру",
    "SHOW_LEFT_PANEL": "Сол жақ панельді ашу",
    "SHOW_RIGHT_PANEL": "Оң жақ панельді ашу",
    "SEARCH_TAG": "Тегті іздеу",
    "NO_DATA_TODAY": "Бүгінге ескертпелер жоқ",
    "NO_DATA": "Ескертпелер жоқ",
    "DOMAIN": "Дереккөз",
    "OPEN_IN_MODAL": "Модалды терезеде ашу",
    "FULL_LIST": "Толық тізім",
    "SELECTED_SPACE": "таңдалды ",
    "TOPICS_SELECTED": "тақырып",
    "TOPICS_SELECTED_1": "тақырып",
    "TOPICS_SELECTED_2": "тақырып",
    "SUBTOPICS_SELECTED": "тақырыпша",
    "SUBTOPICS_SELECTED_1": "тақырыпша",
    "SUBTOPICS_SELECTED_2": "тақырыпша",
    "CODE": "Код",
    "BOT": "Бот",
    "ADD_TELEGRAM_BOT": "Telegram ботын қосыңыз",
    "SUBSCRIBE_TO_TELEGRAM": "Telegram-ға жазылу",
    "DEAR_USER": "Құрметті қолданушы!",
    "FIND_TELEGERAM_BOT": "Telegram-нан ботты тауып, оған кодты жіберіңіз",
    "PRESS_TO_COPY": "Көшіру үшін басыңыз",
    "SUBSCRIPTION_TO_NOTIFICATIONS": "Хабрламаларға жазылымдар",
    "en": "Ағылшын",
    "ru": "Орыс",
    "kz": "Қазақ",
    "kk": "Қазақ",
    "be": "Белорус",
    "uk": "Украин",
    "fr": "Француз",
    "kt": "Қазақ (транслит)",
    "da": "Дат",
    "ca": "Каталан",
    "nl": "Нидерланд (Голланд)",
    "eo": "Эсперанто",
    "de": "Неміс",
    "no": "Норвегиялық",
    "gl": "Гренландиялық",
    "it": "Итальян",
    "sk": "Словакия",
    "sv": "Швед",
    "fi": "Фин",
    "es": "Испан",
    "is": "Исландия",
    "pt": "Португал",
    "rt": "Орыс (транслит)",
    "sl": "Словен",
    "ro": "Румын",
    "pl": "Поляк",
    "el": "Грек (Жаңа грек)",
    "et": "Эстон",
    "hu": "Венгер",
};

export default Titles;