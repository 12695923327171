import React, {useEffect, useState} from "react";
import s from "./short-analytic-chart-item.module.scss";
import ApexChartContainer from "apex-charts/apex-chart-container";

import { getFormatedDate } from "service/format-date";
import moment from "moment";

const ShortAnalyticChartItem = (props) => {
    const formatDate = (d) => {
        return getFormatedDate(d)
    }

    const [type, setType] = useState(true);
    const [dataCols, setDataCols] = useState(props.data && (props.data.numFoundNeutralToday || props.data.numFoundNegativeToday || props.data.numFoundPositiveToday) ? [props.data.numFoundNeutralToday, props.data.numFoundPositiveToday,props.data.numFoundNegativeToday] : []);
    let labels = [props.getValue('NEUTRAL'), props.getValue('POSITIVE'), props.getValue('NEGATIVE')];
    let list = ['NEUTRAL', 'POSITIVE','NEGATIVE'];

    let pieChartColors = ["var(--echoSecondary)", "var(--green)", "var(--echoRed)"]
    let data = {labels: labels, values: dataCols, list: list}
    
    let arrowSize = window.screen.availWidth < 1800 ? 18 : 28;
    //const [startday, endDay] = getDefaultTime(moment(), moment())//moment(getFormatedDate()).startOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
    //const endDay = moment().endOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
    const startday = formatDate(moment().startOf('day'));
    const endDay = formatDate(moment().endOf('day'));

    const fields = {date: [startday, endDay], grouping: "DAILY", sentiment: list, topicId: props.topicId, mediaType: [], topic: {name: props.topic.name, id: props.topic.uuid}}
    const fieldsType = {seriesIndex: 'sentiment', dataPointIndex: 'date', thirdIndex: 'mediaType'}

    useEffect(() => {
        setDataCols(props.data && (props.data.numFoundNeutralToday || props.data.numFoundNegativeToday || props.data.numFoundPositiveToday) ? [props.data.numFoundNeutralToday,props.data.numFoundPositiveToday,props.data.numFoundNegativeToday] : []);
    }, [props.data])

    /*const getArrowByValues = (prev, now, size=null, color=null) => {
        return prev < now ? <ArrowUp fontSize={size} color={color}/> : <ArrowDown fontSize={size} color={color}/>;
    }*/

    /*const getFormattedCount = (num) =>{
        return Math.abs(num) > 999 
            ? (Math.abs(num) > 999999 
                ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'KK' 
                : Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K')
            : Math.sign(num)*Math.abs(num)
    }*/

    if(props.mediaType){
        fields.mediaType = [];
        if(props.mediaType === 'SOCIAL_MEDIA'){
            fields.mediaType = ['SOCIAL']
        }
        else if(props.mediaType === 'MASS_MEDIA'){
            fields.mediaType = ["NEWS_AGENCY", "INTERNET", "PRINT", "TV"]
        }
    }

    return (
        <div className={s.AnalyticItem + ' ' +s.chartItem}>
            <div className={s.leftChartBlock}>
                <div className={s.leftChartBlockTop}>
                    <div className={s.WeekCount}>
                        {props.getArrowByValues(props.data.numFoundPositivePrevWeek, props.data.numFoundWeek, 22)}
                        <span>{props.getFormattedCount(props.data.numFoundWeek)}</span>
                    </div>
                    <span className={s.chartItemCaption}>{props.getValue('WEEK')}</span>
                </div>
                <div className={s.leftChartBlockBottom}>
                    <div className={s.KeyValues+" "+s.green}>
                        {props.getArrowByValues(props.data.numFoundPositivePrevWeek, props.data.numFoundPositiveWeek, arrowSize, 'var(--echoGreen)')}
                        <span>{props.getFormattedCount(props.data.numFoundPositiveWeek)}</span>
                    </div>
                    <div className={s.KeyValues+" "+s.blue}>
                        {props.getArrowByValues(props.data.numFoundNeutralPrevWeek, props.data.numFoundNeutralWeek, arrowSize, 'var(--echoBlue)')}
                        <span>{props.getFormattedCount(props.data.numFoundNeutralWeek)}</span>
                    </div>
                    <div className={s.KeyValues+" "+s.red}>
                        {props.getArrowByValues(props.data.numFoundNegativePrevWeek, props.data.numFoundNegativeWeek, arrowSize, 'var(--echoRed)')}
                        <span>{props.getFormattedCount(props.data.numFoundNegativeWeek)}</span>
                    </div>
                </div>
            </div>
            <div className={s.ChartBlock}>
            {dataCols 
                    && <ApexChartContainer 
                                        noData={(props.deviceType !== 'mobile' && props.deviceType !== 'tablet') ? props.getValue('NO_DATA_TODAY') : props.getValue('NO_DATA')} 
                                        width={'100%'} 
                                        height={185} 
                                        getValue={props.getValue} 
                                        data={data} 
                                        type={'donut'} 
                                        today={props.data.numFoundToday > 999 ? props.getFormattedCount(props.data.numFoundToday) : props.data.numFoundToday} 
                                        yesterday={props.data.numFoundYesterday > 999 ? props.getFormattedCount(props.data.numFoundYesterday) : props.data.numFoundYesterday} 
                                        colors={pieChartColors} filters={props.filters} 
                                        graphicType={{label: 'sentiment', data: 'dateTime', filters: props.filters}} 
                                        topic={{index: props.index, topicId: props.topic.id, topicUUId: props.topicId, topicsName: props.topic.name}} 
                                        fields={fields} 
                                        fieldsType={fieldsType}
                                    />}
                {/*<PieChart yesterday={props.data.numFoundYesterday} today={props.data.numFoundToday > 999 ? getFormattedCount(props.data.numFoundToday) : props.data.numFoundToday} width={142} height={142} data={data} type={type} redraw={true} doughnut={true} doughnutBackground={'#E8ECF3'} colors={pieChartColors} label={556}/>*/}
            </div>
            <div>
                <div className={s.rightChartBlock}>
                    <div className={s.rightChartBlockTop}>
                        <span>{props.getFormattedCount(props.data.numFoundTotal)}</span>
                        <span className={s.chartItemCaption}>{props.getValue('TOTAL')}</span>
                    </div>
                    {/*<div className={s.rightChartBlockBottom}>
                        <div className={s.totalNegativeAndPublic}><AgressiveSmileIcon /><span className={s.small_values}>{props.data.total_negative}</span></div>
                        <div className={s.totalNegativeAndPublic}><PublicIcon /><span className={s.small_values}>{props.data.public}</span></div>
    </div>*/}
                </div>
            </div>
           
        </div>
    )
}

export default ShortAnalyticChartItem;