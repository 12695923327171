import React from "react";
import { NavLink } from "react-router-dom";
import s from "./header-button.module.scss";

const HeaderButton = (props) => {

    let name = props.name;
    let title = props.title;
    let ButtonImage = props.image;

    let clickButton = () => {
        
        props.type === 'nav' 
            ? props.setClearSelected(props.name, props.pageName)
            : props.onClick()
    }

    return <button className={s.HeaderButton} onClick={clickButton} title={title}>
                {props.type === "nav" 
                ?   <NavLink to={props.link}>
                        {props.image && <ButtonImage /> }
                        <span>{props.deviceType !== 'tablet' ? title : null }</span>
                    </NavLink> 
                : <a><ButtonImage /><span>{ props.deviceType !== 'tablet' ? title : null }</span></a>}
        </button>
}

export default HeaderButton;